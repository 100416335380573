import * as htmlToImage from "html-to-image";

import { Bar, Doughnut } from "react-chartjs-2";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { faCalendar, faDownload } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../helpers/Contexts";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Component } from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { backgroundColor } from "../../helpers/Colors";
import download from "downloadjs";
import { getData } from "../../helpers/Data";
import moment from "moment";

interface IProps {}

interface IState {
  filterItem: string;
  fromDate?: Date;
  statistics?: any;
  toDate?: Date;
}

export default class MaintenanceDashboard extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      filterItem: "2",
      fromDate: moment().startOf("year").toDate(),
      toDate: moment().endOf("day").toDate(),
    };
  }
  static contextType = AuthContext;
  componentDidMount = () => {
    this.refresh();
  };

  refresh() {
    getData("reports/jobCardDashboard", {
      companyId: this.context.selectedCompany?.id,
      from: this.state.fromDate !== undefined ? moment(this.state.fromDate).format("DD MMM YYYY") : "",
      to: this.state.toDate !== undefined ? moment(this.state.toDate).format("DD MMM YYYY hh:mm") : "",
    }).then((data) => {
      // console.log("DASH", data);
      this.setState({ statistics: data });
    });
  }

  setFilterItem = (items: any) => {
    let newFilterItem: string = "";

    items.forEach((element: any) => {
      newFilterItem = element.value;
    });

    let newFromDate = undefined;
    let newToDate = undefined;

    if (newFilterItem === "1") {
      newFromDate = moment().startOf("month").toDate();
      newToDate = moment().endOf("day").toDate();
    } else if (newFilterItem === "2") {
      newFromDate = moment().startOf("year").toDate();
      newToDate = moment().endOf("day").toDate();
    } else if (newFilterItem === "3") {
      newFromDate = moment().startOf("month").subtract(6, "months").toDate();
      newToDate = moment().endOf("day").toDate();
    }

    this.setState({ filterItem: newFilterItem, fromDate: newFromDate, toDate: newToDate }, () => this.refresh());
  };

  saveChartToPng = async (chartId: string, name: string) => {
    const canvas = document.getElementById(chartId) as HTMLCanvasElement;
    htmlToImage
      .toPng(canvas)
      .then((dataUrl) => {
        download(dataUrl, `${name}.png`);
      })
      .catch((err) => {
        console.error("Oops, something went wrong!", err);
      });
  };

  render() {
    if (this.state.statistics) {
      const jobCardPercentageData = {
        labels: this.state.statistics.jobCardPercentagePerMaintenanceTypes?.map((a: any) => a.maintenanceTypeName),
        datasets: [
          {
            label: "%",
            data: this.state.statistics.jobCardPercentagePerMaintenanceTypes?.map((a: any) => a.percentage),
            backgroundColor: backgroundColor,
            borderWidth: 0,
          },
        ],
      };

      let jobCardPrefix =
        this.context.selectedCompany.jobCardPrefix !== null && this.context.selectedCompany.jobCardPrefix !== ""
          ? this.context.selectedCompany.jobCardPrefix
          : "Job Card";

      const jobCardPercentageOptions = {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
          },
          title: {
            display: true,
            text: jobCardPrefix + "% Per Maintenance Type",
            font: {
              size: 24,
            },
          },
          datalabels: {
            display: true,
            align: "bottom",
            borderRadius: 3,
            font: {
              size: 24,
            },
            color: "#fff",
            formatter: (value: any) => {
              return value + "%";
            },
          },
        },
      };

      const jobCardAgePerOperationalUnitData = {
        labels: this.state.statistics.jobCardAgePerOperationalUnits?.map((a: any) => a.operationalUnitName),
        datasets: [
          {
            label: "Average Age",
            data: this.state.statistics.jobCardAgePerOperationalUnits?.map((a: any) => a.age),
            backgroundColor: this.state.statistics.jobCardAgePerOperationalUnits?.map((a: any) => a.colour),
            borderWidth: 0,
          },
        ],
      };

      const jobCardAgePerOperationalUnitOptions = {
        responsive: true,
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
          title: {
            display: true,
            text: "Average " + jobCardPrefix + " Age Per Operational Unit",
            font: {
              size: 24,
            },
          },
          datalabels: {
            display: true,
            align: "right",
            borderRadius: 2,
            font: {
              size: 16,
            },
            color: "#222",
            backgroundColor: "#ffffff66",
          },
        },
      };

      const costPerOperationalUnitsData = {
        labels: this.state.statistics.costPerOperationalUnits?.map((a: any) => a.operationalUnitName),
        datasets: [
          {
            label: "Cost",
            data: this.state.statistics.costPerOperationalUnits?.map((a: any) => a.cost),
            backgroundColor: this.state.statistics.costPerOperationalUnits?.map((a: any) => a.colour),
            borderWidth: 0,
          },
        ],
      };

      const costPerOperationalUnitsOptions = {
        responsive: true,
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
          title: {
            display: true,
            text: "Cost Per Operational Unit",
            font: {
              size: 24,
            },
          },
          //   subtitle: {
          //     display: true,
          //     text: "Year-to-date",
          //     font: {
          //       size: 20,
          //     },
          //   },
          datalabels: {
            display: true,
            align: "right",
            borderRadius: 2,
            font: {
              size: 16,
            },
            color: "#222",
            backgroundColor: "#ffffff66",
            formatter: (value: any) => {
              return value.toLocaleString("en-ZA", {
                style: "currency",
                currency: "ZAR",
              });
            },
          },
        },
      };

      const jobCardsPerArtisanData = {
        labels: this.state.statistics.jobCardsPerArtisan?.map((a: any) => a.artisanName),
        datasets: [
          {
            label: "Job Cards",
            data: this.state.statistics.jobCardsPerArtisan?.map((a: any) => a.numberOfJobCards),
            backgroundColor: this.state.statistics.jobCardsPerArtisan?.map((a: any) => a.colour),
            borderWidth: 0,
          },
        ],
      };

      const jobCardsPerArtisanOptions = {
        responsive: true,
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
          title: {
            display: true,
            text: "Number of " + jobCardPrefix + "'s Per Artisan",
            font: {
              size: 24,
            },
          },
          datalabels: {
            display: true,
            align: "right",
            borderRadius: 2,
            font: {
              size: 16,
            },
            color: "#222",
            backgroundColor: "#ffffff66",
          },
        },
      };

      const downtimePerOperationalUnitsData = {
        labels: this.state.statistics.downtimePerOperationalUnits?.map((a: any) => a.operationalUnitName),
        datasets: [
          {
            label: "Downtime",
            data: this.state.statistics.downtimePerOperationalUnits?.map((a: any) => a.time),
            backgroundColor: this.state.statistics.downtimePerOperationalUnits?.map((a: any) => a.colour),
            borderWith: 0,
          },
        ],
      };

      const downtimePerOperationalUnitsOptions = {
        responsive: true,
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
          title: {
            display: true,
            text: "Operational Units Downtime",
            font: {
              size: 24,
            },
          },
          datalabels: {
            display: true,
            align: "right",
            borderRadius: 2,
            font: {
              size: 16,
            },
            color: "#222",
            backgroundColor: "#ffffff66",
          },
        },
      };

      const decimalToHours = (hours: number) => {
        var h = String(Math.trunc(hours)).padStart(2, "0") as any;
        var m = String(Math.abs(Math.round((hours - h) * 60))).padStart(2, "0");
        return h + ":" + m;
      };

      const hoursWorkedPerArtisansData = {
        labels: this.state.statistics.hoursWorkedPerArtisans?.map((a: any) => a.artisanName),
        datasets: [
          {
            label: "Hours Worked",
            data: this.state.statistics.hoursWorkedPerArtisans?.map((a: any) => a.totalTime),
            backgroundColor: this.state.statistics.hoursWorkedPerArtisans?.map((a: any) => a.colour),
            borderWith: 0,
          },
        ],
      };

      const hoursWorkedPerArtisansOptions = {
        responsive: true,
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
          tooltip: {
            enabled: false,
          },
          title: {
            display: true,
            text: "Artisan Time worked",
            font: {
              size: 24,
            },
          },
          datalabels: {
            display: true,
            align: "right",
            borderRadius: 2,
            font: {
              size: 16,
            },
            color: "#222",
            backgroundColor: "#ffffff66",
            formatter: (value: any) => {
              return decimalToHours(value);
            },
          },
        },
      };

      const selectOptions = [
        { value: "1", label: "Month to Date" },
        { value: "2", label: "Year to Date" },
        { value: "3", label: "Custom" },
      ];

      let row;
      if (Number(this.state.filterItem) < 3) {
        row = (
          <Row className="align-items-center">
            <Col>Maintenance Dashboard</Col>
            <Col sm={3} md={3} lg={4}>
              <Select options={selectOptions} defaultValue={selectOptions[1]} isMulti onChange={(e) => this.setFilterItem(e)} placeholder="All" />
            </Col>
          </Row>
        );
      } else if (this.state.filterItem === "3") {
        row = (
          <Row className="align-items-center">
            <Col>Maintenance Dashboard</Col>
            <Col sm={3} md={3} lg={3}>
              <Select options={selectOptions} isMulti onChange={(e) => this.setFilterItem(e)} />
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="customDatePickerWidth">
                <DatePicker
                  customInput={
                    <InputGroup className="">
                      <Form.Control placeholder="Date" value={moment(this.state.fromDate).format("DD/MM/YYYY")} />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">
                          <FontAwesomeIcon icon={faCalendar} />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  }
                  onChange={(date: Date) => this.setState({ fromDate: date }, () => this.refresh())}
                />
              </div>
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="customDatePickerWidth">
                <DatePicker
                  customInput={
                    <InputGroup className="">
                      <Form.Control placeholder="Date" value={moment(this.state.toDate).format("DD/MM/YYYY")} />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">
                          <FontAwesomeIcon icon={faCalendar} />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  }
                  onChange={(date: Date) => this.setState({ toDate: date }, () => this.refresh())}
                />
              </div>
            </Col>
          </Row>
        );
      }

      return (
        <div>
          <Card style={{ width: "100%" }}>
            <Card.Header>
              <Card.Title>{row}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="text-center">
                  <Button
                    variant={"success"}
                    size="sm"
                    title="Download Chart"
                    onClick={() => this.saveChartToPng("1", `${jobCardPrefix}% Per Maintenance Type`)}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <div id="1" style={{ backgroundColor: "white" }}>
                    <Doughnut data={jobCardPercentageData} height={400} plugins={[ChartDataLabels]} options={jobCardPercentageOptions} />
                  </div>
                </Col>
                <Col className="text-center">
                  <Button
                    variant={"success"}
                    size="sm"
                    title="Download Chart"
                    onClick={() => this.saveChartToPng("2", `Average ${jobCardPrefix} Per Operational Unit`)}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <div id="2" style={{ backgroundColor: "white" }}>
                    <Bar data={jobCardAgePerOperationalUnitData} height={400} plugins={[ChartDataLabels]} options={jobCardAgePerOperationalUnitOptions} />
                  </div>
                </Col>
                <Col className="text-center">
                  <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("3", `Cost Per Operational Unit`)}>
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <div id="3" style={{ backgroundColor: "white" }}>
                    <Bar data={costPerOperationalUnitsData} height={400} plugins={[ChartDataLabels]} options={costPerOperationalUnitsOptions} />
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col className="text-center">
                  <Button
                    variant={"success"}
                    size="sm"
                    title="Download Chart"
                    onClick={() => this.saveChartToPng("4", `Number of ${jobCardPrefix}'s Per Artisan`)}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <div id="4" style={{ backgroundColor: "white" }}>
                    <Bar data={jobCardsPerArtisanData} height={400} plugins={[ChartDataLabels]} options={jobCardsPerArtisanOptions} />
                  </div>
                </Col>
                <Col className="text-center">
                  <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("5", `Operational Units Downtime`)}>
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <div id="5" style={{ backgroundColor: "white" }}>
                    <Bar data={downtimePerOperationalUnitsData} height={400} plugins={[ChartDataLabels]} options={downtimePerOperationalUnitsOptions} />
                  </div>
                </Col>
                <Col className="text-center">
                  <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("6", `Artisan Time Worked`)}>
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <div id="6" style={{ backgroundColor: "white" }}>
                    <Bar data={hoursWorkedPerArtisansData} height={400} plugins={[ChartDataLabels]} options={hoursWorkedPerArtisansOptions} />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
