import "react-big-calendar/lib/css/react-big-calendar.css";

import { Badge, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { EJobCardStatus, EStatusColours, EStatusColoursHex } from "../../helpers/enums/enums";

import { AuthContext } from "../../helpers/Contexts";
import { IJobCard } from "../../entities/JobCard";
import JobCardDetail from "./JobCardDialog";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { getData } from "../../helpers/Data";
import moment from "moment";
import { toast } from "react-toastify";

interface IProps extends RouteComponentProps {
  onClose: any;
  show: boolean;
}

interface IState {
  jobCards: IJobCard[];
  loadingJobCards: boolean;
  selectedJobCard?: IJobCard;
  show: boolean;
  showJobCardDialog: boolean;
}

export default class PlannedJobCardDetail extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      jobCards: [],
      loadingJobCards: false,
      show: this.props.show,
      showJobCardDialog: false,
    };
  }
  static contextType = AuthContext;

  componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.show !== this.props.show) {
      this.refreshJobCardList();
      this.setState({
        show: this.props.show,
      });
    }
  };

  refreshJobCardList = () => {
    this.setState({ loadingJobCards: true });
    getData("jobCards", {
      companyId: this.context.selectedCompany?.id,
      source: "Planned",
    }).then((data) => {
      this.setState({
        jobCards: data,
        loadingJobCards: false,
      });
    });
  };

  closeJobCardDialog = () => {
    this.refreshJobCardList();
    this.setState({
      showJobCardDialog: false,
      selectedJobCard: {
        allToolsAndSparesReturned: false,
        artisanHandovers: [],
        comments: [],
        companyId: "",
        costs: [],
        createdBy: "",
        createdOn: new Date(),
        date: new Date(),
        description: "",
        files: [],
        jobCardTools: [],
        machineCleaned: false,
        machineRunning: false,
        maintenanceForemanId: "",
        maintenanceForemanText: "",
        maintenanceOrRepairsDone: false,
        maintenanceTypeId: "",
        maintenanceTypeText: "",
        name: "",
        noUnaccountedToolsAndSpares: false,
        operationalUnitId: "",
        operationalUnitText: "",
        planned: false,
        plannedJobCardTasks: [],
        status: "",
        statusId: 1,
      },
    });
  };

  openJobCard = (event: any) => {
    let jobCard = this.state.jobCards.find((x) => x.id === event.id);
    // console.log(jobCard);
    if (jobCard === undefined) {
      toast.error("Could not find job card details. Please refresh and try again.");
      return;
    }

    this.setState({ selectedJobCard: jobCard, showJobCardDialog: true });
  };

  newJobCardButtonClick = (date: Date) => {
    // let currentDate = new Date()
    // date.setHours(currentDate.getHours());
    this.setState({
      showJobCardDialog: true,
      selectedJobCard: {
        allToolsAndSparesReturned: false,
        artisanHandovers: [],
        comments: [],
        companyId: "",
        costs: [],
        createdBy: "",
        createdOn: new Date(),
        date: date,
        description: "",
        files: [],
        jobCardTools: [],
        machineCleaned: false,
        machineRunning: false,
        maintenanceForemanId: "",
        maintenanceForemanText: "",
        maintenanceOrRepairsDone: false,
        maintenanceTypeId: "",
        maintenanceTypeText: "",
        name: "",
        noUnaccountedToolsAndSpares: false,
        operationalUnitId: "",
        operationalUnitText: "",
        planned: true,
        plannedJobCardTasks: [],
        status: "",
        statusId: 1,
      },
    });
  };

  eventStyleGetter = (event: any, start: any, end: any, isSelected: any) => {
    // var backgroundColor = "#" + event.hexColor;
    var style = {
      backgroundColor: EStatusColoursHex[event.statusId],
      color: event.statusId === 4 ? "black" : "white",
      // borderRadius: "0px",
      // opacity: 0.8,
      // color: "black",
      // border: "0px",
      // display: "block",
    };
    return {
      style: style,
    };
  };

  render() {
    const localizer = momentLocalizer(moment);

    const events = this.state.jobCards.map((jobCard) => {
      return {
        title: `${
          this.context.selectedCompany.jobCardPrefix !== null && this.context.selectedCompany.jobCardPrefix !== ""
            ? this.context.selectedCompany.jobCardPrefix
            : "Job Card"
        } ${jobCard.jobCardNumber?.toString().padStart(6, "0")} - ${jobCard.name}`,
        start: new Date(moment.utc(jobCard.date).toDate()),
        end: new Date(moment.utc(jobCard.date).toDate()),
        allDay: false,
        id: jobCard.id,
        statusId: jobCard.statusId,
      };
    });

    return (
      <div>
        <JobCardDetail
          history={this.props.history}
          location={this.props.location}
          match={this.props.match}
          onClose={this.closeJobCardDialog}
          user={this.context.User}
          show={this.state.showJobCardDialog}
          jobCard={this.state.selectedJobCard}
        />

        <Modal dialogClassName="modal-10w" centered size={"xl"} show={this.state.show} onHide={() => this.props.onClose()} animation={true}>
          <Modal.Header>
            <Modal.Title className="mr-auto">
              Planned{" "}
              {this.context.selectedCompany.jobCardPrefix && this.context.selectedCompany.jobCardPrefix !== ""
                ? this.context.selectedCompany.jobCardPrefix
                : "Job Cards"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loadingJobCards ? (
              <Row style={{ height: "70vh" }}>
                <Col>
                  <Spinner className="ml-auto mr-auto d-block" animation="grow" />
                </Col>
              </Row>
            ) : (
              <>
                <Calendar
                  onSelectSlot={(slotInfo) => {
                    // console.log(slotInfo.start);
                    this.newJobCardButtonClick(slotInfo.start);
                  }}
                  onSelectEvent={(event) => this.openJobCard(event)}
                  selectable
                  // views={["month", "week", "day"]}
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  eventPropGetter={this.eventStyleGetter}
                  formats={{
                    eventTimeRangeFormat: ({ start }, culture) => {
                      return momentLocalizer(moment).format(start, "hh:mm a", culture);
                    },
                  }}
                  style={{ height: "70vh", cursor: "pointer" }}
                />
                <Row style={{ marginTop: "5px" }}>
                  {(Object.values(EJobCardStatus).filter((value) => value !== 6 && typeof value === "number") as number[]).map((value) => (
                    <Badge style={{ width: "fit-content", margin: "0 1px" }} variant={EStatusColours[value]}>
                      {EJobCardStatus[value]}
                    </Badge>
                  ))}
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.props.onClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
