import { Button, ButtonGroup, Card, Col, Form, FormLabel, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import { ICompany, ICompanyList, IUserDefinedField } from "../../entities/Company";
import { faCheck, faChevronDown, faChevronUp, faEdit, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getData, postData, postDataWithFiles } from "../../helpers/Data";

import { AuthContext } from "../../helpers/Contexts";
import { ConfirmationDialog } from "../../helpers/ConfirmationDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Resizer from "react-image-file-resizer";
import Select from "react-select";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

interface IProps {}

interface IState {
  text: string;
  companies: ICompanyList[];
  showEditDialog: boolean;
  selectedCompany: ICompany;
  inputFile: HTMLInputElement;
  users: any;
  tabKey: any;
}

export default class Companies extends React.Component<IProps, IState> {
  static contextType = AuthContext;
  constructor(props: any) {
    super(props);
    this.state = {
      text: "TEST",
      companies: [],
      showEditDialog: false,
      selectedCompany: {
        active: true,
        allocateDocumentNumber: "",
        artisanHandoverFlag: false,
        companyPrefix: "",
        finalReviewTitle: "",
        jobCardPrefix: "",
        maintenance: false,
        name: "",
        plannedMaintenance: false,
        requiredMaintenanceWhy: false,
        requiredQualityWhy: false,
        toolsAndSparesFlag: false,
        tradingAsName: "",
        userDefinedFields: [],
      },
      inputFile: document.getElementById("uploadBtn") as HTMLInputElement,
      users: [],
      tabKey: "details",
    };
  }

  componentDidMount() {
    this.refresh();
    this.setState({ inputFile: document.getElementById("uploadBtn") as HTMLInputElement });
  }

  refresh() {
    getData("Companies", {}).then((data) => {
      this.setState({ companies: data });
    });
  }

  refreshUsers = (id: string) => {
    getData("users/GetUserDDL", { companyId: id }).then((data) => {
      // console.log("Users", data);
      this.setState({ users: data });
    });
  };

  buttonClick = () => {
    ConfirmationDialog({ confirmFunction: this.complete, title: "YOH?", message: "Are you sure you wanna yoh?", confirmButtonText: "YEAH" });
  };

  complete = () => {
    this.setState({ text: "YOOOOOOOOOOO" });
  };

  openEditDialog = (id: string) => {
    this.refreshUsers(id);
    this.getCompany(id);
    this.setState({ showEditDialog: true });
  };

  openCreateDialog = () => {
    this.setState({
      selectedCompany: {
        active: true,
        allocateDocumentNumber: "",
        artisanHandoverFlag: false,
        companyPrefix: "",
        finalReviewTitle: "",
        jobCardPrefix: "",
        maintenance: false,
        name: "",
        plannedMaintenance: false,
        requiredMaintenanceWhy: false,
        requiredQualityWhy: false,
        toolsAndSparesFlag: false,
        tradingAsName: "",
        userDefinedFields: [],
      },
      showEditDialog: true,
    });
  };

  getCompany = (id: string | undefined) => {
    if (id) {
      getData("companies/" + id).then((data) => {
        // console.log("comp", data);
        if (data.logoId) {
          getData("files/full", { id: data.logoId }).then((logoData) => {
            let comp: ICompany = data;

            comp.logoFileType = logoData.contentType;
            comp.logoBase64 = logoData.data;
            this.setState({ selectedCompany: comp, showEditDialog: true });
          });
        } else {
          let comp: ICompany = data;
          this.setState({ selectedCompany: comp, showEditDialog: true });
        }
      });
    }
  };

  onClose = () => {
    this.setState({
      showEditDialog: false,
      selectedCompany: {
        active: true,
        allocateDocumentNumber: "",
        artisanHandoverFlag: false,
        companyPrefix: "",
        finalReviewTitle: "",
        jobCardPrefix: "",
        maintenance: false,
        name: "",
        plannedMaintenance: false,
        requiredMaintenanceWhy: false,
        requiredQualityWhy: false,
        toolsAndSparesFlag: false,
        tradingAsName: "",
        userDefinedFields: [],
      },
    });
  };

  onSave = () => {
    postData("companies", this.state.selectedCompany).then((res) => {
      this.setState(
        {
          showEditDialog: false,
          selectedCompany: {
            active: true,
            allocateDocumentNumber: "",
            artisanHandoverFlag: false,
            companyPrefix: "",
            finalReviewTitle: "",
            jobCardPrefix: "",
            maintenance: false,
            name: "",
            plannedMaintenance: false,
            requiredMaintenanceWhy: false,
            requiredQualityWhy: false,
            toolsAndSparesFlag: false,
            tradingAsName: "",
            userDefinedFields: [],
          },
        },
        () => this.refresh()
      );
    });
  };

  setSelectedCompanyField = (value: any, field: keyof ICompany) => {
    let company = this.state.selectedCompany;
    company = this.prop(company, field, value);
    this.setState({ selectedCompany: company });
  };

  prop<T, K extends keyof T>(obj: T, key: K, newVal: any) {
    obj[key] = newVal;
    return obj;
  }

  setSelectedCompanyUserDefinedField = (value: any, field: string, id: string) => {
    let udfs = this.state.selectedCompany.userDefinedFields;
    let udf: IUserDefinedField = udfs.find((f) => f.id === id) ?? ({ name: "", options: "", rank: 0, required: false, type: "text" } as IUserDefinedField);
    udf[field] = value;
    this.setState({ selectedCompany: { ...this.state.selectedCompany, userDefinedFields: udfs } });
  };

  resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  handleLogoFileUploaded = async (event: any) => {
    const file = event.target.files[0];
    const resizedFile = await this.resizeFile(file);

    try {
      postDataWithFiles(
        "companies/logo",
        {
          companyId: this.state.selectedCompany.id,
        },
        [resizedFile]
      ).then((res) => {
        // console.log("saved", res.json);
        this.getCompany(this.state.selectedCompany.id);
      });
    } catch {}
  };

  handleUploadButtonClick = () => {
    this.state.inputFile?.click();
  };

  setTab = (key: any) => {
    this.setState({ tabKey: key });
  };

  sortUDFs = (a: IUserDefinedField, b: IUserDefinedField) => {
    if (a.rank < b.rank) {
      return -1;
    }
    if (a.rank > b.rank) {
      return 1;
    }
    return 0;
  };

  changeRank = (id: string, up: boolean) => {
    debugger;
    let udfs = this.state.selectedCompany.userDefinedFields;
    let max = udfs.reduce((max, obj) => {
      return max.rank < obj.rank ? obj : max;
    }).rank;
    let udf: IUserDefinedField = udfs.find((f) => f.id === id) ?? ({ name: "", options: "", rank: 0, required: false, type: "text" } as IUserDefinedField);
    let rank = udf.rank;
    if (up && rank !== 1) {
      let nextUdf: IUserDefinedField =
        udfs.find((f) => f.rank === rank - 1) ?? ({ name: "", options: "", rank: 0, required: false, type: "text" } as IUserDefinedField);
      if (nextUdf.rank !== 0) {
        nextUdf.rank = rank;
        udf.rank = rank - 1;
        this.setState({ selectedCompany: { ...this.state.selectedCompany } });
      }
    }
    if (!up && rank <= max) {
      let nextUdf: IUserDefinedField =
        udfs.find((f) => f.rank === rank + 1) ?? ({ name: "", options: "", rank: 0, required: false, type: "text" } as IUserDefinedField);
      if (nextUdf.rank <= max && nextUdf.rank !== 0) {
        nextUdf.rank = rank;
        udf.rank = rank + 1;
        this.setState({ selectedCompany: { ...this.state.selectedCompany } });
      }
    }
  };

  addNewUserDefinedField = (isJobCard: boolean) => {
    let udfs = this.state.selectedCompany.userDefinedFields || [];
    let max =
      udfs.length > 0
        ? udfs.reduce((max, obj) => {
            return max.rank < obj.rank ? obj : max;
          }).rank
        : 0;
    let newUdf = { id: uuidv4(), name: "", options: "", rank: max + 1, required: false, type: "text", isJobCard: isJobCard } as IUserDefinedField;
    udfs.push(newUdf);
    this.setState({ selectedCompany: { ...this.state.selectedCompany } });
  };

  removeUserDefinedField = (id: string) => {
    let udfs = this.state.selectedCompany.userDefinedFields;
    let udf: IUserDefinedField = udfs.find((f) => f.id === id) ?? ({ name: "", options: "", rank: 0, required: false, type: "text" } as IUserDefinedField);
    udfs.splice(udfs.indexOf(udf), 1);
    udfs.sort(this.sortUDFs).map((m, i) => {
      m.rank = i + 1;
    });
    this.setState({ selectedCompany: { ...this.state.selectedCompany } });
  };

  render() {
    const finalReviewTitleOptions = [
      { value: "HSE Manager", label: "HSE Manager" },
      { value: "QA Manager", label: "QA Manager" },
      { value: "Director", label: "Director" },
    ];
    const udfTypeOptions = [
      { value: "text", label: "Text" },
      { value: "number", label: "Number" },
      { value: "options", label: "Options" },
      { value: "multipleOptions", label: "Multiple Options" },
    ];
    let isSA = this.context.User.roles.includes("SuperAdministrators");

    // console.log(this.state.selectedCompany);

    return (
      <div>
        <Card style={{ width: "100%" }}>
          <Card.Header>
            <Card.Title>
              Companies
              {isSA ? (
                <span className="float-right">
                  <Button variant="primary" className="" onClick={() => this.openCreateDialog()}>
                    Add Company
                  </Button>
                </span>
              ) : null}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Table striped hover size="sm">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Trading As</th>
                      <th>Company Prefix</th>
                      <th>Job Card Prefix</th>
                      <th style={{ width: "95px" }}>Active</th>
                      {(this.context.selectedCompany.maintenance || isSA) && <th style={{ width: "95px" }}>Maintenance</th>}
                      {(this.context.selectedCompany.plannedMaintenance || isSA) && <th>Planned Maintenance</th>}
                      <th style={{ width: "95px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.companies.map((company: ICompanyList) => {
                      return (
                        <tr>
                          <td>{company.name}</td>
                          <td>{company.tradingAsName}</td>
                          <td>{company.companyPrefix}</td>
                          <td>{company.jobCardPrefix}</td>
                          <td>{company.active ? <FontAwesomeIcon icon={faCheck} color="green" /> : <FontAwesomeIcon icon={faTimes} color="red" />}</td>
                          {(this.context.selectedCompany.maintenance || isSA) && (
                            <td>{company.maintenance ? <FontAwesomeIcon icon={faCheck} color="green" /> : <FontAwesomeIcon icon={faTimes} color="red" />}</td>
                          )}
                          {(this.context.selectedCompany.plannedMaintenance || isSA) && (
                            <td>
                              {company.plannedMaintenance ? <FontAwesomeIcon icon={faCheck} color="green" /> : <FontAwesomeIcon icon={faTimes} color="red" />}
                            </td>
                          )}
                          <td>
                            <Button variant="primary" size="sm" onClick={() => this.openEditDialog(company.id)} block>
                              <FontAwesomeIcon icon={faEdit} /> Edit
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Modal show={this.state.showEditDialog} onHide={() => this.onClose()} animation={false} size="xl">
          <Modal.Header>
            <Modal.Title>{this.state.selectedCompany.id ? "Edit " + this.state.selectedCompany.name : "Create Company"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs activeKey={this.state.tabKey} onSelect={(k) => this.setTab(k)} id="uncontrolled-tab-example" className="mb-3">
              <Tab className="" eventKey="details" title="Details">
                <Form>
                  {this.state.selectedCompany.logoBase64 ? (
                    <img
                      alt="Logo"
                      src={"data:" + this.state.selectedCompany.logoFileType + ";base64," + this.state.selectedCompany.logoBase64}
                      style={{ maxWidth: "80%", marginLeft: "10%" }}
                    ></img>
                  ) : null}

                  {this.state.selectedCompany.id ? (
                    <Form.Group className="mb-3">
                      <Form.Label>Upload Logo </Form.Label>
                      <Form.Control type="file" id="uploadBtn" accept="image/*" onChange={(event) => this.handleLogoFileUploaded(event)} />
                    </Form.Group>
                  ) : null}

                  <Row>
                    <Form.Group as={Col} sm={12} md={6}>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        value={this.state.selectedCompany.name}
                        placeholder="Enter Name"
                        onChange={(e) => this.setSelectedCompanyField(e.target.value, "name")}
                      />
                    </Form.Group>

                    <Form.Group as={Col} sm={12} md={6}>
                      <Form.Label>Trading As</Form.Label>
                      <Form.Control
                        value={this.state.selectedCompany.tradingAsName}
                        placeholder="Enter Trading As Name"
                        onChange={(e) => this.setSelectedCompanyField(e.target.value, "tradingAsName")}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} sm={12} md={6}>
                      <Form.Label>Incident Prefix</Form.Label>
                      <Form.Control
                        value={this.state.selectedCompany.companyPrefix}
                        placeholder="Enter Company Prefix"
                        onChange={(e) => this.setSelectedCompanyField(e.target.value, "companyPrefix")}
                      />
                    </Form.Group>

                    <Form.Group as={Col} sm={12} md={6}>
                      <Form.Label>Job Card Prefix</Form.Label>
                      <Form.Control
                        value={this.state.selectedCompany.jobCardPrefix}
                        placeholder="Enter Job Card Prefix"
                        onChange={(e) => this.setSelectedCompanyField(e.target.value, "jobCardPrefix")}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} sm={12} md={6}>
                      <Form.Label>Document Allocation Number</Form.Label>
                      <Form.Control
                        value={this.state.selectedCompany.allocateDocumentNumber}
                        placeholder="Enter Document Allocation Number"
                        onChange={(e) => this.setSelectedCompanyField(e.target.value, "allocateDocumentNumber")}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={6}>
                      <FormLabel>Final Review Title</FormLabel>
                      <Select
                        options={finalReviewTitleOptions}
                        value={
                          this.state.selectedCompany.finalReviewTitle
                            ? finalReviewTitleOptions.filter((option: any) => option.value === this.state.selectedCompany.finalReviewTitle)
                            : null
                        }
                        onChange={(e) => this.setSelectedCompanyField(e?.value, "finalReviewTitle")}
                        placeholder="Final Review Title"
                      />
                    </Form.Group>
                  </Row>
                  {isSA ? (
                    <div>
                      <Row>
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            checked={this.state.selectedCompany.active}
                            label="Active"
                            onChange={(e) => {
                              this.setSelectedCompanyField(e.target.checked, "active");
                            }}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            checked={this.state.selectedCompany.maintenance}
                            label="Maintenance"
                            onChange={(e) => this.setSelectedCompanyField(e.target.checked, "maintenance")}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            checked={this.state.selectedCompany.plannedMaintenance}
                            label="Planned Maintenance"
                            onChange={(e) => this.setSelectedCompanyField(e.target.checked, "plannedMaintenance")}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            checked={this.state.selectedCompany.requiredMaintenanceWhy}
                            label="Require Maintenance Why 1"
                            onChange={(e) => this.setSelectedCompanyField(e.target.checked, "requiredMaintenanceWhy")}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            checked={this.state.selectedCompany.requiredQualityWhy}
                            label="Require Quality Why 1"
                            onChange={(e) => this.setSelectedCompanyField(e.target.checked, "requiredQualityWhy")}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            checked={this.state.selectedCompany.artisanHandoverFlag}
                            label="Show Artisan Handover"
                            onChange={(e) => this.setSelectedCompanyField(e.target.checked, "artisanHandoverFlag")}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            checked={this.state.selectedCompany.toolsAndSparesFlag}
                            label="Show Tools And Spares"
                            onChange={(e) => this.setSelectedCompanyField(e.target.checked, "toolsAndSparesFlag")}
                          />
                        </Form.Group>
                      </Row>

                      {!this.state.selectedCompany.active ? (
                        <div>
                          <Form.Group>
                            <Form.Label>Deactivation Reason</Form.Label>
                            <Form.Control
                              value={this.state.selectedCompany.deactivationReason}
                              as="textarea"
                              rows={3}
                              placeholder="Deactivation Reason"
                              onChange={(e) => this.setSelectedCompanyField(e.target.value, "deactivationReason")}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Control
                              plaintext
                              readOnly
                              value={"Deactivation Date : " + moment(this.state.selectedCompany.dateDeactivated ?? new Date()).format("DD MMM YYYY")}
                            />
                          </Form.Group>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </Form>
              </Tab>
              <Tab className="" eventKey="udfs" title="Incident User Defined Fields">
                <Form>
                  {this.state.selectedCompany.userDefinedFields
                    .filter((f) => !f.isJobCard)
                    .sort(this.sortUDFs)
                    .map((m: IUserDefinedField, index: number) => {
                      return (
                        <Row style={{ borderBottom: "1px solid #dee2e6", marginBottom: "10px" }}>
                          <Form.Group as={Col} sm={1}>
                            <ButtonGroup vertical>
                              <Button size="sm" onClick={() => this.changeRank(m.id ?? "", true)}>
                                <FontAwesomeIcon icon={faChevronUp} />
                              </Button>
                              <Button size="sm" onClick={() => this.changeRank(m.id ?? "", false)}>
                                <FontAwesomeIcon icon={faChevronDown} />
                              </Button>
                            </ButtonGroup>
                          </Form.Group>
                          <Form.Group as={Col} sm={1}>
                            <span>{m.rank}</span>
                          </Form.Group>
                          <Form.Group as={Col} sm={4}>
                            <Form.Control
                              value={m.name}
                              placeholder="Name"
                              onChange={(e) => this.setSelectedCompanyUserDefinedField(e.target.value, "name", m.id ?? "")}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={3}>
                            <Select
                              options={udfTypeOptions}
                              value={udfTypeOptions.find((f) => f.value === m.type ?? "text")}
                              onChange={(e) => this.setSelectedCompanyUserDefinedField(e?.value, "type", m.id ?? "")}
                              placeholder="Type"
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={2}>
                            <Form.Check
                              type="checkbox"
                              checked={m.required}
                              label="Required"
                              onChange={(e) => this.setSelectedCompanyUserDefinedField(e.target.checked, "required", m.id ?? "")}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={1}>
                            <Button size="sm" variant="danger" onClick={() => this.removeUserDefinedField(m.id ?? "")}>
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </Form.Group>

                          {(m.type === "options" || m.type === "multipleOptions") && (
                            <Form.Group>
                              <Form.Control
                                value={m.options}
                                placeholder="Enter Options"
                                onChange={(e) => this.setSelectedCompanyUserDefinedField(e.target.value, "options", m.id ?? "")}
                              />
                              <small>Enter options for dropdown, seperated with a comma (,)</small>
                            </Form.Group>
                          )}
                        </Row>
                      );
                    })}
                  <Row>
                    <Col>
                      <Button
                        size="sm"
                        onClick={() => this.addNewUserDefinedField(false)}
                        disabled={this.state.selectedCompany.userDefinedFields.filter((f) => !f.isJobCard).length >= 10}
                      >
                        Add New User Defined Field
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Tab>
              <Tab className="" eventKey="udfsjobcard" title="Job Card User Defined Fields">
                <Form>
                  {this.state.selectedCompany.userDefinedFields
                    .filter((f) => f.isJobCard)
                    .sort(this.sortUDFs)
                    .map((m: IUserDefinedField, index: number) => {
                      return (
                        <Row style={{ borderBottom: "1px solid #dee2e6", marginBottom: "10px" }}>
                          <Form.Group as={Col} sm={1}>
                            <ButtonGroup vertical>
                              <Button size="sm" onClick={() => this.changeRank(m.id ?? "", true)}>
                                <FontAwesomeIcon icon={faChevronUp} />
                              </Button>
                              <Button size="sm" onClick={() => this.changeRank(m.id ?? "", false)}>
                                <FontAwesomeIcon icon={faChevronDown} />
                              </Button>
                            </ButtonGroup>
                          </Form.Group>
                          <Form.Group as={Col} sm={1}>
                            <span>{m.rank}</span>
                          </Form.Group>
                          <Form.Group as={Col} sm={4}>
                            <Form.Control
                              value={m.name}
                              placeholder="Name"
                              onChange={(e) => this.setSelectedCompanyUserDefinedField(e.target.value, "name", m.id ?? "")}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={3}>
                            <Select
                              options={udfTypeOptions}
                              value={udfTypeOptions.find((f) => f.value === m.type ?? "text")}
                              onChange={(e) => this.setSelectedCompanyUserDefinedField(e?.value, "type", m.id ?? "")}
                              placeholder="Type"
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={2}>
                            <Form.Check
                              type="checkbox"
                              checked={m.required}
                              label="Required"
                              onChange={(e) => this.setSelectedCompanyUserDefinedField(e.target.checked, "required", m.id ?? "")}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={1}>
                            <Button size="sm" variant="danger" onClick={() => this.removeUserDefinedField(m.id ?? "")}>
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </Form.Group>

                          {(m.type === "options" || m.type === "multipleOptions") && (
                            <Form.Group>
                              <Form.Control
                                value={m.options}
                                placeholder="Enter Options"
                                onChange={(e) => this.setSelectedCompanyUserDefinedField(e.target.value, "options", m.id ?? "")}
                              />
                              <small>Enter options for dropdown, seperated with a comma (,)</small>
                            </Form.Group>
                          )}
                        </Row>
                      );
                    })}
                  <Row>
                    <Col>
                      <Button
                        size="sm"
                        onClick={() => this.addNewUserDefinedField(true)}
                        disabled={this.state.selectedCompany.userDefinedFields.filter((f) => f.isJobCard).length >= 10}
                      >
                        Add New Job Card User Defined Field
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.onClose()}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.onSave();
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
