import React from "react";

export const AuthContext = React.createContext({
    isAuthed: false,
    setAuthed: () => { },
    setUser: () => { },
    //User : {},
    userChecked: false,
    companies: [],
    selectedCompany: {},
    setCompany: () => { },
});