import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { deleteData, getData, postData } from "../../helpers/Data";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../helpers/Contexts";
import { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IMachineSection } from "../../entities/MachineSections";
import { toast } from "react-toastify";

interface IProps {}

interface IState {
  machineSections: IMachineSection[];
  showEditDialog: boolean;
  selectedMachineSection: IMachineSection;
}

export default class MachineSection extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      machineSections: [],
      showEditDialog: false,
      selectedMachineSection: { name: "", companyId: "" },
    };
  }
  static contextType = AuthContext;

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    getData("machineSection", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ machineSections: data });
    });
  }

  openEditDialog = (machine: IMachineSection) => {
    this.setState({ selectedMachineSection: { ...machine }, showEditDialog: true });
  };

  openCreateDialog = () => {
    this.setState({
      selectedMachineSection: { name: "", companyId: "" },
      showEditDialog: true,
    });
  };

  onClose = () => {
    this.setState({
      showEditDialog: false,
      selectedMachineSection: { name: "", companyId: "" },
    });
  };

  onSave = () => {
    let machineSection = this.state.selectedMachineSection;
    machineSection.companyId = this.context.selectedCompany.id;

    postData("machineSection", machineSection).then((data) => {
      this.setState(
        {
          showEditDialog: false,
          selectedMachineSection: { name: "", companyId: "" },
        },
        () => this.refresh()
      );
    });
  };

  setSelectedMachineSectionField = (value: any, field: keyof IMachineSection) => {
    let machineSection = this.state.selectedMachineSection;
    machineSection[field] = value;
    this.setState({ selectedMachineSection: machineSection });
  };

  delete = (id: string | undefined) => {
    deleteData("machineSection/", { id: id }).then((data) => {
      if (data.status === 400) {
        toast.error("Machine section cannot be deleted as it is in use.");
      }
      this.refresh();
    });
  };

  render() {
    return (
      <div>
        <Card>
          <Card.Header>
            <Card.Title>
              Machine Sections
              <span className="float-right">
                <Button variant="primary" className="" onClick={() => this.openCreateDialog()}>
                  Add Machine Section
                </Button>
              </span>
            </Card.Title>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col>
                <Table striped hover size="sm">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.machineSections.length > 0
                      ? this.state.machineSections.map((machine, index) => {
                          return (
                            <tr key={index}>
                              <td>{machine.name}</td>
                              <td className="text-end">
                                <Button variant="primary" size="sm" onClick={() => this.openEditDialog(machine)}>
                                  <FontAwesomeIcon icon={faEdit} /> Edit
                                </Button>
                                <Button variant="danger" size="sm" onClick={() => this.delete(machine.id)}>
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Modal show={this.state.showEditDialog} onHide={() => this.onClose()} animation={false}>
          <Modal.Header>
            <Modal.Title>{this.state.selectedMachineSection.id ? "Edit" + this.state.selectedMachineSection.name : "Create Machine Register"}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter machine section name"
                  value={this.state.selectedMachineSection.name}
                  onChange={(e) => this.setSelectedMachineSectionField(e.target.value, "name")}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.onClose()}>
              Close
            </Button>
            <Button variant="primary" onClick={() => this.onSave()}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
