import configData from "../appsettings.json";
import download from "downloadjs";
import { useHistory } from "react-router-dom";

let apiUrl = "";

switch (process.env.NODE_ENV) {
  case "production":
    apiUrl = configData.API_PRODUCTION_URL;
    break;
  case "development":
  default:
    apiUrl = configData.API_LOCAL_URL;
}

export async function getData(url: string, params: any = {}) {
  var queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  var options = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "bearer " + localStorage.getItem("bearer-token"),
    },
  };

  const data = await fetch(apiUrl + url + "?" + queryString, options)
    .then(handleErrors)
    .then((result) => {
      return result;
    });
  return data;
}

export async function getDataEmptyUrl(url: string, params: any = {}) {
  var queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  var options = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };

  const data = await fetch(url + queryString, options)
    .then(handleErrors)
    .then((result) => {
      return result;
    });
  return data;
}

export async function LogIn(url: string, params: any) {
  var queryString = Object.keys(params)
    .map((key) => key + "=" + encodeURIComponent(params[key]))
    .join("&");
  var options = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: queryString,
  };
  const data = await fetch(apiUrl + url, options).then((response) => {
    if (!response.ok) {
      return response;
    } else return response.json();
  });
  return data;
}

export async function LogOut() {
  var options = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      //'Content-Type': 'application/json',
      Authorization: "bearer " + localStorage.getItem("bearer-token"),
    },  
  };
  const data = await fetch(apiUrl + "users/logout", options).then((result) => {
    return result;
  });
  return data;
}

export async function postData(url: string, params: any) {
  var queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  var options = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "bearer " + localStorage.getItem("bearer-token"),
    },
    body: JSON.stringify(params),
  };
  const data = await fetch(apiUrl + url, options)
    .then(handleErrors)
    .then((result) => {
      return result;
    });
  return data;
}

export async function postDataNonJSON(url: string, params: any) {
  var queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  var options = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "bearer " + localStorage.getItem("bearer-token"),
    },
    body: JSON.stringify(params),
  };
  const data = await fetch(apiUrl + url, options).then((result) => {
    return result;
  });
  return data;
}

export async function deleteData(url: string, params: any) {
  var queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  var options = {
    method: "DELETE",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "bearer " + localStorage.getItem("bearer-token"),
    },
    body: JSON.stringify(params),
  };
  const data = await fetch(apiUrl + url, options)
    .then(handleErrors)
    .then((result) => {
      return result;
    });
  return data;
}

export async function postDataWithFiles(url: string, params: any, files: any[]) {
  var queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  var formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });
  var options = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      //'Content-Type': 'multipart/form-data',
      Authorization: "bearer " + localStorage.getItem("bearer-token"),
    },
    body: formData,
  };
  const data = await fetch(apiUrl + url + "?" + queryString, options).then((result) => {
    return result;
  });
  return data;
}

export async function getFile(url: string, name: string, contentType: string) {
  var options = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "bearer " + localStorage.getItem("bearer-token"),
    },
  };

  const data = await fetch(apiUrl + url, options)
    .then((res) => res.blob())
    .then((blob) => {
      download(blob, name, contentType);
    });
  return data;
}

export async function getReport(url: string, params: any = {}, name?: string) {
  var queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  var options = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "bearer " + localStorage.getItem("bearer-token"),
    },
  };

  const data = await fetch(apiUrl + url + "?" + queryString, options)
    .then((res) => res.blob())
    .then((blob) => {
      download(blob, name ?? "", blob.type);
    });
  return data;
}

async function handleErrors(response: Response, json: boolean = false) {
  if (!response.ok) {
    // console.log(response);
    if (response.status === 401) {
      localStorage.removeItem("bearer-token");
      window.location.reload();
    }
    return response;
  }
  if (json) {
  } else {
    try {
      return await response.json();
    } catch (err) {
      debugger;
    }
  }
}
