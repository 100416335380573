import { Accordion, Badge, Button, ButtonGroup, Card, Col, Form, InputGroup, Modal, ModalBody, Row, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { deleteData, getData, getFile, postData, postDataWithFiles } from "../../helpers/Data";
import { faDownload, faFile, faPlus, faSave, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../helpers/Contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IArtisanHandover } from "../../entities/ArtisanHandover";
import { ICompany } from "../../entities/Company";
import { IJobCard } from "../../entities/JobCard";
import { IJobCardCommentList } from "../../entities/Comment";
import { IJobCardCostList } from "../../entities/Cost";
import { IJobCardFile } from "../../entities/CARFiles";
import { IMachineRegisterDDL } from "../../entities/MachineRegister";
import { IMachineSectionDropDownList } from "../../entities/MachineSections";
import { IPlannedJobCardTask } from "../../entities/PlannedJobCardTask";
import { IToolsAndSpares } from "../../entities/Tools";
import { IUser } from "../../entities/User";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Select from "react-select";
import { StyledDropzone } from "../../helpers/StyledDropzone";
import moment from "moment";

interface IProps extends RouteComponentProps {
  onClose: any;
  user: IUser;
  show: boolean;
  jobCard?: IJobCard;
}

interface IState {
  artisanHandoverTabKey: any;
  artisanHandovers: IArtisanHandover[];
  costTypes: any;
  jobCard: IJobCard;
  jobCardToolIndex: number;
  machineRegisters: IMachineRegisterDDL[];
  maintenanceTypes: any;
  modified: boolean;
  operationalUnits: any;
  saving: boolean;
  savingDraft: boolean;
  selectedCompany: ICompany;
  selectedMachineSections: IMachineSectionDropDownList[];
  show: boolean;
  showConfirmationDialog: boolean;
  showDocumentDialog: boolean;
  tabDocKey: any;
  tabKey: any;
  tempComment?: string;
  toolReturnedDescription: string;
  toolReturnedQuantity: string;
  toolTakenDescription: string;
  toolTakenQuantity: string;
  users: any;
}

export default class JobCardDetail extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      artisanHandovers: [],
      machineRegisters: [],
      selectedMachineSections: [],
      jobCard: {
        allToolsAndSparesReturned: false,
        comments: [],
        companyId: "",
        costs: [],
        createdBy: "",
        createdOn: new Date(),
        date: new Date(),
        description: "",
        files: [],
        jobCardTools: [],
        machineCleaned: false,
        machineRunning: false,
        maintenanceForemanId: "",
        maintenanceForemanText: "",
        maintenanceOrRepairsDone: false,
        maintenanceTypeId: "",
        maintenanceTypeText: "",
        name: "",
        noUnaccountedToolsAndSpares: false,
        operationalUnitId: "",
        operationalUnitText: "",
        planned: false,
        plannedJobCardTasks: [],
        saveDraft: false,
        status: "",
        statusId: 0,
      },
      showConfirmationDialog: false,
      showDocumentDialog: false,
      maintenanceTypes: [],
      operationalUnits: [],
      costTypes: [],
      modified: false,
      users: [],
      tabKey: "comments",
      artisanHandoverTabKey: 0,
      tabDocKey: 0,
      show: this.props.show,
      saving: false,
      savingDraft: false,
      selectedCompany: {
        active: true,
        allocateDocumentNumber: "",
        artisanHandoverFlag: false,
        companyPrefix: "",
        finalReviewTitle: "",
        jobCardPrefix: "",
        maintenance: false,
        name: "",
        plannedMaintenance: false,
        requiredMaintenanceWhy: false,
        requiredQualityWhy: false,
        toolsAndSparesFlag: false,
        tradingAsName: "",
        userDefinedFields: [],
      },
      toolReturnedDescription: "",
      toolReturnedQuantity: "",
      toolTakenDescription: "",
      toolTakenQuantity: "",
      jobCardToolIndex: 0,
    };
  }
  static contextType = AuthContext;

  addToolOrSpareTaken = () => {
    let tempArray = this.state.jobCard.jobCardTools;
    let tool: IToolsAndSpares = {
      description: this.state.toolTakenDescription,
      jobCardId: this.props.jobCard?.id,
      quantity: this.state.toolTakenQuantity,
      toolsReturned: false,
      jobCardToolIndex: this.state.jobCardToolIndex,
    };

    tempArray.push(tool);
    this.setSelectedField(tempArray, "jobCardTools");
    let index = tempArray.length;
    this.setState({ toolTakenDescription: "", toolTakenQuantity: "", jobCardToolIndex: index });
  };

  addToolOrSpareReturned = () => {
    let tempArray = this.state.jobCard.jobCardTools;
    let tool: IToolsAndSpares = {
      description: this.state.toolReturnedDescription,
      jobCardId: this.props.jobCard?.id,
      quantity: this.state.toolReturnedQuantity,
      toolsReturned: true,
      jobCardToolIndex: this.state.jobCardToolIndex,
    };

    tempArray.push(tool);
    this.setSelectedField(tempArray, "jobCardTools");
    let index = tempArray.length;
    this.setState({ toolReturnedDescription: "", toolReturnedQuantity: "", jobCardToolIndex: index });
  };

  changeUserDefinedField = (items: any, index: number) => {
    var joinedItems = items.map((item: any) => item.value).join(",");
    this.setSelectedUserDefinedField(
      joinedItems,
      `userDefinedField${index}` as any,
      (this.state.jobCard as any)[`userDefinedField${index}TypeObject`]?.id as keyof IJobCard,
      `userDefinedField${index}Type` as any
    );
  };

  deleteToolOrSpare = (id: string) => {
    deleteData("jobCardTools/", { id: id }).then((data) => {
      this.getJobCard();
    });
  };

  removeToolOrSpare = (index: number, id?: string) => {
    if (id !== undefined) {
      this.deleteToolOrSpare(id);
    }

    let jobCard = this.state.jobCard;
    let tools = jobCard.jobCardTools;
    let arrIndex = tools.map((f) => f.jobCardToolIndex).indexOf(index);
    tools.splice(arrIndex, 1);
    jobCard.jobCardTools = tools;
    this.setState({ jobCard });
  };

  componentDidMount = () => {
    this.getArtisanHandovers();
    this.getCompany();
    this.refreshMaintenanceTypes();
    this.refreshOperationalUnits();
    this.refreshUsers();
    this.refreshCostTypes();
    this.refreshMachineRegisters();
  };

  componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.jobCard !== this.props.jobCard) {
      this.setUrlParms();
      this.refreshMaintenanceTypes();
      this.refreshOperationalUnits();
      this.refreshUsers();
      this.refreshMachineRegisters();
      this.getArtisanHandovers();
      if (this.props.jobCard?.id) {
        this.getJobCard();
      } else {
        this.setState({
          jobCard: {
            allToolsAndSparesReturned: false,
            comments: [],
            companyId: "",
            costs: [],
            createdBy: "",
            createdOn: new Date(),
            date: this.props.jobCard?.date ?? new Date(),
            description: "",
            files: [],
            jobCardTools: [],
            machineCleaned: false,
            machineRunning: false,
            maintenanceForemanId: "",
            maintenanceForemanText: "",
            maintenanceOrRepairsDone: false,
            maintenanceTypeId: "",
            maintenanceTypeText: "",
            name: "",
            noUnaccountedToolsAndSpares: false,
            operationalUnitId: "",
            operationalUnitText: "",
            planned: this.props.jobCard?.planned ?? false,
            plannedJobCardTasks: [],
            saveDraft: false,
            status: "",
            statusId: 0,
          },
          show: this.props.show,
        });
      }
    }
  };

  setUrlParms() {
    const searchParams = new URLSearchParams();
    searchParams.set("type", "maintenance");

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: searchParams.toString(),
    });
  }

  addToArtisanHandover = () => {
    let tempArray = this.state.artisanHandovers;
    let newHandover: IArtisanHandover = {
      artisanId: "",
      artisanName: "",
      companyId: this.context.selectedCompany.id,
      jobCardId: this.state.jobCard.id as string,
      description: "",
      startTime: new Date(),
      endTime: null,
    };

    tempArray.push(newHandover);
    this.setState({ artisanHandovers: tempArray, artisanHandoverTabKey: tempArray.length - 1 });
  };

  addTaskToJobCard = () => {
    let tempTaskArr = this.state.jobCard.plannedJobCardTasks ?? [];
    let newTask: IPlannedJobCardTask = {
      description: "",
      // jobCardId: this.state.jobCard.id ?? null,
    };

    tempTaskArr.push(newTask);
    this.setSelectedField(tempTaskArr, "plannedJobCardTasks");
  };

  bulkPostArtisanHandovers = () => {
    postData("artisanHandovers/bulkHandovers", { ArtisanHandovers: this.state.artisanHandovers });
  };

  cancelConfirmationDialog = () => {
    this.setState({ showConfirmationDialog: false });
    this.props.onClose();
  };

  deleteArtisanHandover = async (handover: IArtisanHandover, index: number) => {
    if (handover.id !== undefined) {
      await deleteData("artisanHandovers/", { id: handover.id });
    }

    let tempArr = this.state.artisanHandovers;
    tempArr.splice(index, 1);
    this.setState({ artisanHandovers: tempArr, artisanHandoverTabKey: 0 });
  };

  checkTask = async (id: string) => {
    await postData("plannedJobCardTasks", { Id: id });
    await this.getJobCard();
  };

  deletePlannedTask = async (task: IPlannedJobCardTask, index: number) => {
    if (task.id !== undefined) {
      await deleteData("plannedJobCardTasks/", { id: task.id });
    }

    let tempArr = this.state.jobCard.plannedJobCardTasks ?? [];
    tempArr.splice(index, 1);
    this.setSelectedField([...tempArr], "plannedJobCardTasks");
  };

  getArtisanHandovers = () => {
    if (this.props.jobCard?.id) {
      getData("artisanHandovers", { jobCardId: this.props.jobCard?.id }).then((data) => {
        this.setState({ artisanHandovers: data });
      });
    }
  };

  getCompany = () => {
    getData("companies/" + this.context.selectedCompany.id).then((data) => {
      this.setState({ selectedCompany: data });
    });
  };

  getJobCard = async () => {
    await getData("jobCards/" + this.props.jobCard?.id).then((data) => {
      this.setState({
        jobCard: data,
        show: this.props.show,
        jobCardToolIndex: data.jobCardTools.length,
      });
    });
  };

  refreshMaintenanceTypes = () => {
    getData("maintenanceTypes/GetMaintenanceTypeDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ maintenanceTypes: data });
    });
  };

  changeOperationalUnit = (opUnitId: string) => {
    const opUnit = this.state.operationalUnits.find((f: any) => f.value === opUnitId);
    this.setSelectedField(opUnit.value, "operationalUnitId");
    this.setSelectedField(opUnit.maintenanceForemanText, "maintenanceForemanText");
    this.setSelectedField(opUnit.maintenanceForeman, "maintenanceForemanId");
  };

  refreshMachineRegisters = () => {
    getData("machineRegisters/GetMachineRegisterDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ machineRegisters: data });

      if (this.state.jobCard.machineRegisterId !== undefined || this.props.jobCard?.machineRegisterId !== undefined) {
        let machineRegister = data.find(
          (f: any) =>
            f.value === (this.state.jobCard.machineRegisterId !== undefined ? this.state.jobCard.machineRegisterId : this.props.jobCard?.machineRegisterId)
        );

        this.setState({ selectedMachineSections: machineRegister?.machineSections });
      }
    });
  };

  refreshOperationalUnits = () => {
    getData("operationalUnits/GetOperationalUnitDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ operationalUnits: data });
    });
  };

  refreshUsers = () => {
    getData("users/GetUserDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ users: data });
    });
  };

  refreshComments = () => {
    getData("jobCards/comments", { JobCardId: this.state.jobCard.id }).then((data) => {
      let jobCard = this.state.jobCard;
      jobCard.comments = data;
      this.setState({ jobCard });
    });
  };

  refreshCostTypes = () => {
    getData("jobCards/costTypes", {}).then((data) => {
      this.setState({ costTypes: data });
    });
  };

  refreshCosts = () => {
    getData("jobCards/costs", { JobCardId: this.state.jobCard.id }).then((data) => {
      this.setState({ costTypes: data });
    });
  };

  refreshDocs = () => {
    let jobCard = this.state.jobCard;
    getData("jobCards/documents", { JobCardId: this.state.jobCard.id }).then((data) => {
      jobCard.files = data;
      this.setState({ jobCard });
    });
  };

  postComment = () => {
    postData("jobCards/comment", { JobCardId: this.state.jobCard.id, text: this.state.tempComment }).then((res) => {
      this.refreshComments();
      this.setState({ tempComment: "" });
    });
  };

  setSelectedField = (value: any, field: keyof IJobCard) => {
    this.setState({ modified: true });
    let jobCard = this.state.jobCard;
    jobCard = this.prop(jobCard, field, value);
    this.setState({ jobCard });
  };

  prop<T, K extends keyof T>(obj: T, key: K, newVal: any) {
    obj[key] = newVal;
    return obj;
  }

  revertStatus = () => {
    let cmd = { id: this.state.jobCard.id };
    postData("jobCards/revertstatus", cmd).then((res) => {
      this.props.onClose();
    });
  };

  setSelectedCostField = (index: number, value: any, field: keyof IJobCardCostList) => {
    let cost = this.state.jobCard.costs[index];
    cost = this.prop(cost, field, value);
    cost.edited = true;

    this.setState({});
  };

  save = () => {
    this.setState({ saving: true });
    let jobCard = this.state.jobCard;
    jobCard.companyId = jobCard.companyId === "" ? this.context.selectedCompany.id : jobCard.companyId;
    jobCard.createdBy = this.context.User.id;
    this.bulkPostArtisanHandovers();
    postData("jobCards", this.state.jobCard).then((res) => {
      this.setState({ saving: false });
      this.props.onClose();
    });
  };

  saveChanges = () => {
    if (this.state.modified) {
      this.setState({ modified: false });
      this.setState({ showConfirmationDialog: true });
    } else if (
      this.state.jobCard.statusId < 1 ||
      (this.state.jobCard.statusId === 3 &&
        (this.state.jobCard.correctiveActionEffectivenessReview === null || this.state.jobCard.correctiveActionEffectivenessReview === undefined))
    ) {
      this.props.onClose();
    } else {
      this.props.onClose();
    }
  };

  saveDraft = () => {
    this.setState({ savingDraft: true });
    let jobCard = this.state.jobCard;
    jobCard.companyId = jobCard.companyId === "" ? this.context.selectedCompany.id : jobCard.companyId;
    jobCard.createdBy = this.context.User.id;
    jobCard.saveDraft = true;
    this.bulkPostArtisanHandovers();
    postData("jobCards", this.state.jobCard).then((res) => {
      this.setState({ savingDraft: false });
      this.setState({ showConfirmationDialog: false });
      this.props.onClose();
    });
  };

  addCost = () => {
    let jobCard = this.state.jobCard;
    let costs = jobCard.costs;

    let newCostIdx = costs.push({
      jobCardId: jobCard.id?.valueOf() + "",
      adHocType: "",
      costValue: 0,
      edited: true,
    } as IJobCardCostList);
    let cost = this.state.jobCard.costs[newCostIdx - 1];
    jobCard.costs = costs;
    this.setState({ jobCard });
  };

  removeCost = (index: number) => {
    let jobCard = this.state.jobCard;
    let costs = jobCard.costs;
    costs.splice(index, 1);
    jobCard.costs = costs;
    this.setState({ jobCard });
  };

  saveCost = (index: number) => {
    let cost = this.state.jobCard.costs[index];
    cost.edited = false;
    postData("jobCards/cost", cost).then((res) => {
      this.setState({});
      if (cost.costValue === undefined || cost.costValue === 0) {
        this.removeCost(index);
      }
    });
  };

  approveReject = (approve: boolean) => {
    this.setState({ saving: true });
    let jobCard = this.state.jobCard;
    jobCard.companyId = jobCard.companyId === "" ? this.context.selectedCompany.id : jobCard.companyId;
    jobCard.createdBy = this.context.User.id;
    postData("jobCards", this.state.jobCard).then((res) => {
      postData("jobCards/approveReject", { JobCardId: this.state.jobCard.id, approve: approve }).then((res) => {
        this.setState({ saving: false });
        this.props.onClose();
      });
    });
  };

  setTab = (key: any) => {
    this.setState({ tabKey: key });
  };

  setDocKey = (key: any) => {
    this.setState({ tabDocKey: key });
  };

  fileUpload = (e: any, type: number) => {
    try {
      postDataWithFiles(
        "jobCards/document",
        {
          FileType: type,
          JobCardId: this.state.jobCard.id,
        },
        e
      ).then((res) => {
        this.refreshDocs();
      });
    } catch {}
  };

  downloadDoc = (id: string, name: string, contentType: string) => {
    getFile("files/" + id, name, contentType).then((data) => {});
  };

  deleteFile = (id: string) => {
    deleteData("files/", { id: id }).then((data) => {
      this.refreshDocs();
    });
  };

  setSelectedUserDefinedField = (value: string, definedField: keyof IJobCard, definedFieldTypeValue: string, definedFieldTypeName: keyof IJobCard) => {
    this.setSelectedField(value, definedField);
    this.setSelectedField(definedFieldTypeValue, definedFieldTypeName);
  };

  disableSubmitButtonForUDFs = () => {
    let disabled = false;

    for (let index = 0; index < 10; index++) {
      disabled =
        (this.state.jobCard as any)[`userDefinedField${index + 1}TypeObject`] !== null &&
        (this.state.jobCard as any)[`userDefinedField${index + 1}TypeObject`]?.required &&
        ((this.state.jobCard as any)[`userDefinedField${index + 1}`] === null || (this.state.jobCard as any)[`userDefinedField${index + 1}`] === "");

      if (disabled) {
        break;
      }
    }

    return disabled;
  };

  render() {
    let userId = this.context.User.id;
    let isForeman = this.state.jobCard.maintenanceForemanId === userId;
    let isMultipleForeman =
      this.state.jobCard.operationalUnitId && this.state.operationalUnits
        ? this.state.operationalUnits
            .find((f: any) => f.value === this.state.jobCard.operationalUnitId)
            ?.operationalUnitAdmins.find((m: any) => m.value === userId)?.value === userId
        : false;

    let isArtisan = this.state.jobCard.artisanId === userId;
    let isManager = this.state.jobCard.maintenanceManager === userId;
    let isAdmin = this.context.User?.roles.includes("Administrators") || this.context.User?.roles.includes("SuperAdministrators");

    let disabledUDFs = false;
    if (isArtisan) {
      disabledUDFs = this.disableSubmitButtonForUDFs();
    }

    let errorsArr: string[] = [];
    const errors = () => {
      if (this.state.jobCard.statusId === 2 && isArtisan) {
        if (this.context.selectedCompany.toolsAndSparesFlag && this.state.jobCard.jobCardTools.filter((f) => f.toolsReturned).length === 0)
          errorsArr.push("Please add tools or spares returned");
        if (this.state.jobCard.artisanCompletedDate === null) errorsArr.push("Please add artisan completed date");
        if (!this.state.jobCard.why1 && this.state.selectedCompany.requiredMaintenanceWhy) errorsArr.push("Please add why 1");
        if (disabledUDFs) errorsArr.push("Please complete all required addtional fields");
      }
    };
    errors();

    const userDefinedFields = (
      <Tab eventKey="udfs" title="Additional Fields">
        {[...Array(10)].map(
          (x, i) =>
            (this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`] != null &&
            (this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`].isJobCard && (
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  {(this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`]?.name}{" "}
                  {(this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`]?.required ? <span className="text-danger">*</span> : null}
                </Form.Label>
                <Col sm={8}>
                  {(() => {
                    switch ((this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`]?.type) {
                      case "options":
                        return (
                          <Select
                            isDisabled={this.state.jobCard.statusId > 2 && !isForeman && !isAdmin}
                            as="input"
                            options={(this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`].options
                              .split(",")
                              .map((m: string) => ({ value: m, label: m }))}
                            onChange={(opt: any) =>
                              this.setSelectedUserDefinedField(
                                opt.value,
                                `userDefinedField${i + 1}` as any,
                                (this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`]?.id as keyof IJobCard,
                                `userDefinedField${i + 1}Type` as any
                              )
                            }
                            value={{
                              value: (this.state.jobCard as any)[`userDefinedField${i + 1}`],
                              label: (this.state.jobCard as any)[`userDefinedField${i + 1}`],
                            }}
                            placeholder={"Select " + (this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`]?.name}
                            required={(this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`]?.required}
                          />
                        );
                      case "text":
                      case "number":
                        return (
                          <Form.Control
                            type={(this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`]?.type}
                            disabled={this.state.jobCard.statusId > 2 && !isForeman && !isAdmin}
                            value={(this.state.jobCard as any)[`userDefinedField${i + 1}`]}
                            onChange={(e) => {
                              this.setSelectedUserDefinedField(
                                e.target.value,
                                `userDefinedField${i + 1}` as any,
                                (this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`]?.id as keyof IJobCard,
                                `userDefinedField${i + 1}Type` as any
                              );
                            }}
                            required={(this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`]?.required}
                          />
                        );

                      case "multipleOptions":
                        return (
                          <Select
                            isDisabled={this.state.jobCard.statusId > 2 && !isForeman && !isAdmin}
                            isMulti
                            options={(this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`].options
                              .split(",")
                              .map((m: string) => ({ value: m, label: m }))}
                            onChange={(e) => this.changeUserDefinedField(e, i + 1)}
                            value={(this.state.jobCard as any)[`userDefinedField${i + 1}`]?.split(",").map((m: string) => ({ value: m, label: m }))}
                            placeholder={"Select " + (this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`]?.name}
                            required={(this.state.jobCard as any)[`userDefinedField${i + 1}TypeObject`]?.required}
                          />
                        );
                    }
                  })()}
                </Col>
              </Form.Group>
            )
        )}
      </Tab>
    );

    return (
      <div>
        <Modal
          dialogClassName="modal-90w"
          centered
          size={this.state.jobCard.id ? "xl" : "lg"}
          show={this.state.show}
          onHide={() => this.props.onClose()}
          animation={true}
        >
          <Modal.Header>
            <Modal.Title className="mr-auto">
              {this.context.selectedCompany.jobCardPrefix && this.context.selectedCompany.jobCardPrefix !== ""
                ? this.context.selectedCompany.jobCardPrefix
                : "Job Card"}{" "}
              Number : <Badge variant="info">{this.state.jobCard.jobCardNumber?.toString().padStart(6, "0")}</Badge>
              {this.state.jobCard.statusId > 1 && isAdmin ? (
                <Button
                  className="ml-3"
                  size="sm"
                  variant="danger"
                  onClick={() => {
                    this.revertStatus();
                  }}
                >
                  Revert Status
                </Button>
              ) : (
                <></>
              )}
            </Modal.Title>
            <Modal.Title className="ml-auto">
              Cost :{" "}
              <Badge variant="primary">
                {this.state.jobCard.costs
                  ?.reduce(function (prev, cur) {
                    return prev + cur.costValue;
                  }, 0)
                  .toFixed(2)}
              </Badge>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col style={{ overflowY: "auto", maxHeight: "70vh" }}>
                <Form>
                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Title
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        placeholder="Title"
                        value={this.state.jobCard.name}
                        onChange={(e) => this.setSelectedField(e.target.value, "name")}
                        disabled={this.state.jobCard.statusId > 1 && !isForeman && !isAdmin}
                      />
                    </Col>
                  </Form.Group>
                  <hr />
                  {this.state.jobCard.planned && !this.state.jobCard.id && (
                    <>
                      <Row className="justify-content-center align-items-center mb-2">
                        <Col>
                          <Form.Label className="mb-0">Tasks</Form.Label>
                        </Col>
                        <Col className="text-end">
                          <Button onClick={this.addTaskToJobCard} disabled={this.state.jobCard.statusId > 1 && !isAdmin}>
                            Add Task <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        </Col>
                      </Row>
                      {this.state.jobCard.plannedJobCardTasks?.map((task, index) => (
                        <Row>
                          <Form.Group as={Row} controlId="formHorizontalEmail" key={index}>
                            <Form.Label column sm={4} lg={3}>
                              Task Description
                            </Form.Label>

                            <Col>
                              <Form.Control
                                value={task.description}
                                placeholder="Description"
                                onChange={(e) => {
                                  let tempArr = this.state.jobCard.plannedJobCardTasks ?? [];
                                  tempArr[index].description = e.target.value;
                                  this.setSelectedField(tempArr, "plannedJobCardTasks");
                                }}
                              />
                            </Col>
                            <Col className="mb-2 text-center" lg={1}>
                              <Button onClick={() => this.deletePlannedTask(task, index)} variant="danger" title="Delete Task">
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </Col>
                          </Form.Group>
                        </Row>
                      ))}

                      <hr />
                    </>
                  )}
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={5}>
                      <Form.Group controlId="formHorizontalEmail">
                        <Form.Label>Machine Number</Form.Label>
                        <Select
                          as="Input"
                          options={this.state.machineRegisters}
                          onChange={(cat: any) => {
                            this.setSelectedField(cat.value, "machineRegisterId");
                            this.setSelectedField(cat.label, "machineNumber");
                            this.changeOperationalUnit(cat.operationalUnitId);
                            this.setState({ selectedMachineSections: cat.machineSections });
                          }}
                          value={
                            this.state.jobCard.machineRegisterId
                              ? this.state.machineRegisters.filter((option: any) => option.value === this.state.jobCard.machineRegisterId)
                              : null
                          }
                          placeholder="Select Number"
                          disabled={this.state.jobCard.statusId > 1 && !isForeman && !isAdmin}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Machine Section</Form.Label>
                        <Select
                          as="Input"
                          placeholder="Select Machine Section"
                          options={this.state.selectedMachineSections}
                          value={
                            this.state.jobCard.machineSectionId
                              ? this.state.selectedMachineSections.filter((option: any) => option.value === this.state.jobCard.machineSectionId)
                              : null
                          }
                          onChange={(val: any) => this.setSelectedField(val.value, "machineSectionId")}
                          disabled={this.state.jobCard.statusId > 1 && !isForeman && !isAdmin}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} sm={12} md={12} lg={5}>
                      <Form.Group>
                        <Form.Label>Operational Unit</Form.Label>
                        <Form.Control
                          placeholder="Operational Unit"
                          value={
                            this.state.jobCard.operationalUnitId
                              ? this.state.operationalUnits.find((f: any) => f.value === this.state.jobCard.operationalUnitId)?.label
                              : ""
                          }
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Maintenance Admin</Form.Label>
                        <Form.Control placeholder="Maintenance Admin" value={this.state.jobCard.maintenanceForemanText} readOnly />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                      Maintenance Admins
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        as={
                          this.state.operationalUnits.find((f: any) => f.value === this.state.jobCard.operationalUnitId)?.operationalUnitAdmins.length > 3
                            ? "textarea"
                            : "input"
                        }
                        placeholder="Maintenance Admins"
                        readOnly
                        value={
                          this.state.jobCard.operationalUnitId
                            ? this.state.operationalUnits
                                .find((f: any) => f.value === this.state.jobCard.operationalUnitId)
                                ?.operationalUnitAdmins.map((m: any) => m.label)
                                .join(", ")
                            : ""
                        }
                      />
                    </Col>
                  </Form.Group>

                  <hr />

                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Initiated By
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control placeholder="User" readOnly value={this.state.jobCard.createdByText ?? this.props.user.fullName} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Initiation Date
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type={"datetime"}
                        placeholder="Date"
                        disabled={true}
                        value={moment(this.state.jobCard?.createdOn).format("YYYY/MM/DD")}
                        width="100%"
                        //onChange={(e) => this.setSelectedField(moment(e.target.value), "date")}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Planned/Breakdown Date
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type={"datetime-local"}
                        placeholder="Date"
                        disabled={this.state.jobCard.statusId > 1 && !isForeman && !isAdmin}
                        value={moment.utc(this.state.jobCard?.date).local().format("YYYY-MM-DD HH:mm")}
                        width="100%"
                        onChange={(e) => this.setSelectedField(moment(e.target.value), "date")}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Maintenance Priority
                    </Form.Label>
                    <Col sm={8}>
                      <Select
                        options={this.state.maintenanceTypes}
                        onChange={(cat: any) => this.setSelectedField(cat.value, "maintenanceTypeId")}
                        value={
                          this.state.jobCard.maintenanceTypeId
                            ? this.state.maintenanceTypes.filter((option: any) => option.value === this.state.jobCard.maintenanceTypeId)
                            : null
                        }
                        placeholder="Select Maintenance Priority"
                        isDisabled={this.state.jobCard.statusId > 1 && !isForeman && !isAdmin}
                      />
                    </Col>
                  </Form.Group>

                  <Accordion>
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                      {/* <Form.Label>Non-Conformance Description</Form.Label> */}
                      <Form.Label>Maintenance Description</Form.Label>
                      <Col sm={12}>
                        <InputGroup>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={this.state.jobCard.description}
                            onChange={(e) => this.setSelectedField(e.target.value, "description")}
                            disabled={this.state.jobCard.statusId > 1 && !isForeman && !isAdmin}
                          />
                          {this.state.jobCard.statusId >= 1 ? (
                            <InputGroup.Append>
                              <Button
                                variant="secondary"
                                onClick={() => {
                                  this.setTab("docs");
                                  this.setDocKey("1");
                                }}
                                style={{ zIndex: 0 }}
                              >
                                <FontAwesomeIcon icon={faFile} />
                              </Button>
                            </InputGroup.Append>
                          ) : null}
                        </InputGroup>
                      </Col>
                    </Form.Group>
                  </Accordion>

                  {this.state.jobCard.statusId > 1 || isForeman || isMultipleForeman ? (
                    <span>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={4}>
                          Artisan
                        </Form.Label>
                        <Col sm={8}>
                          <Select
                            options={this.state.users}
                            onChange={(inv: any) => {
                              this.setSelectedField(inv.value, "artisanId");
                            }}
                            value={
                              this.state.jobCard.artisanId ? this.state.users.filter((option: any) => option.value === this.state.jobCard.artisanId) : null
                            }
                            placeholder="Select Artisan"
                            isDisabled={this.state.jobCard.statusId > 1 && !isForeman && !isAdmin && !isMultipleForeman}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={4}>
                          Target Completion Date
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type={"datetime-local"}
                            placeholder="Date"
                            disabled={this.state.jobCard.statusId > 1 && !isForeman && !isAdmin && !isMultipleForeman}
                            value={moment.utc(this.state.jobCard?.targetDate).local().format("YYYY-MM-DD HH:mm")}
                            width="100%"
                            onChange={(e) => this.setSelectedField(moment(e.target.value), "targetDate")}
                          />
                        </Col>
                      </Form.Group>
                    </span>
                  ) : null}

                  {this.state.jobCard.statusId > 1 || this.state.jobCard.artisanId === userId ? (
                    <span>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Machine Downtime</Form.Label>
                        <Col sm={12}>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                              variant={!this.state.jobCard.machineRunning ? "primary" : "outline-secondary"}
                              onClick={() => this.setSelectedField(false, "machineRunning")}
                            >
                              Yes
                            </Button>
                            <Button
                              disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                              variant={this.state.jobCard.machineRunning ? "primary" : "outline-secondary"}
                              onClick={() => this.setSelectedField(true, "machineRunning")}
                            >
                              No
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Form.Group>

                      {this.context.selectedCompany.toolsAndSparesFlag && (
                        <Col className="px-0">
                          <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Form.Label column sm={12}>
                              Tools &amp; Spares Taken to Job
                            </Form.Label>
                          </Form.Group>

                          <Form.Group as={Row}>
                            <Col sm={5}>
                              <Form.Control
                                placeholder="Description"
                                onChange={(e) => this.setState({ toolTakenDescription: e.target.value })}
                                value={this.state.toolTakenDescription}
                                disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                              />
                            </Col>
                            <Col sm={5}>
                              <Form.Control
                                placeholder="Quantity"
                                type="number"
                                onChange={(e) => this.setState({ toolTakenQuantity: e.target.value })}
                                value={this.state.toolTakenQuantity}
                                disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                              />
                            </Col>
                            <Col sm={1}>
                              <Button
                                variant="primary"
                                onClick={() => this.addToolOrSpareTaken()}
                                disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </Col>
                          </Form.Group>
                          {this.state.jobCard.jobCardTools
                            .filter((f) => !f.toolsReturned)
                            .map((tool: IToolsAndSpares, index) => (
                              <Form.Group key={index} as={Row}>
                                <Col sm={5}>
                                  <Form.Control value={tool.description} disabled />
                                  <Form.Text>{tool.userId ? tool.fullName : ""}</Form.Text>
                                </Col>
                                <Col sm={5}>
                                  <Form.Control placeholder="Quantity" value={tool.quantity} disabled />
                                </Col>
                                <Col sm={1}>
                                  <Button
                                    variant="danger"
                                    onClick={() => this.removeToolOrSpare(tool.jobCardToolIndex, tool.id)}
                                    disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                </Col>
                              </Form.Group>
                            ))}
                        </Col>
                      )}

                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Maintenance Action</Form.Label>
                        <Col sm={12}>
                          <InputGroup>
                            <Form.Control
                              disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                              as="textarea"
                              rows={3}
                              value={this.state.jobCard.immediateCorrectiveAction}
                              onChange={(e) => this.setSelectedField(e.target.value, "immediateCorrectiveAction")}
                            />
                            <InputGroup.Append>
                              <Button style={{ zIndex: 0 }} variant="success" onClick={() => this.setTab("whys")}>
                                5 Why's
                              </Button>
                              <Button
                                style={{ zIndex: 0 }}
                                variant="secondary"
                                onClick={() => {
                                  this.setTab("docs");
                                  this.setDocKey("2");
                                }}
                              >
                                <FontAwesomeIcon icon={faFile} />
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                      </Form.Group>

                      {this.context.selectedCompany.toolsAndSparesFlag && (
                        <Col className="px-0">
                          <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Form.Label column sm={12}>
                              Tools &amp; Spares Back to Maintenance
                            </Form.Label>
                          </Form.Group>

                          <Form.Group as={Row}>
                            <Col sm={5}>
                              <Form.Control
                                placeholder="Description"
                                onChange={(e) => this.setState({ toolReturnedDescription: e.target.value })}
                                value={this.state.toolReturnedDescription}
                                disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                              />
                            </Col>
                            <Col sm={5}>
                              <Form.Control
                                placeholder="Quantity"
                                type="number"
                                onChange={(e) => this.setState({ toolReturnedQuantity: e.target.value })}
                                value={this.state.toolReturnedQuantity}
                                disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                              />
                            </Col>
                            <Col sm={1}>
                              <Button
                                variant="primary"
                                onClick={() => this.addToolOrSpareReturned()}
                                disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </Col>
                          </Form.Group>
                          {this.state.jobCard.jobCardTools
                            .filter((f) => f.toolsReturned)
                            .map((tool: IToolsAndSpares, index) => (
                              <Form.Group key={index} as={Row}>
                                <Col sm={5}>
                                  <Form.Control value={tool.description} disabled />
                                  <Form.Text>{tool.userId ? tool.fullName : ""}</Form.Text>
                                </Col>
                                <Col sm={5}>
                                  <Form.Control placeholder="Quantity" value={tool.quantity} disabled />
                                </Col>
                                <Col sm={1}>
                                  <Button
                                    variant="danger"
                                    onClick={() => this.removeToolOrSpare(tool.jobCardToolIndex, tool.id)}
                                    disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                </Col>
                              </Form.Group>
                            ))}
                        </Col>
                      )}

                      <Col className="px-0">
                        <Form.Group as={Row}>
                          <Col>
                            <Form.Group>
                              <Form.Check
                                disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                                type="checkbox"
                                checked={this.state.jobCard.maintenanceOrRepairsDone}
                                label="Maintenance/ Repairs done"
                                onChange={(e) => {
                                  this.setSelectedField(e.target.checked, "maintenanceOrRepairsDone");
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Check
                                disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                                type="checkbox"
                                checked={this.state.jobCard.allToolsAndSparesReturned}
                                label="All tools &amp; Spares returned"
                                onChange={(e) => {
                                  this.setSelectedField(e.target.checked, "allToolsAndSparesReturned");
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                          <Col>
                            <Form.Group>
                              <Form.Check
                                disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                                type="checkbox"
                                checked={this.state.jobCard.machineCleaned}
                                label="Machine Cleaned"
                                onChange={(e) => this.setSelectedField(e.target.checked, "machineCleaned")}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Check
                                disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}
                                type="checkbox"
                                checked={this.state.jobCard.noUnaccountedToolsAndSpares}
                                label="No unaccounted spares or tools"
                                onChange={(e) => this.setSelectedField(e.target.checked, "noUnaccountedToolsAndSpares")}
                              />
                            </Form.Group>
                          </Col>
                        </Form.Group>
                      </Col>

                      {this.context.selectedCompany.artisanHandoverFlag && (
                        <Col className="px-0">
                          <Row className="justify-content-center align-items-center">
                            <Col>
                              <Form.Label className="mb-0">Artisan Handover</Form.Label>
                            </Col>
                            <Col className="text-end">
                              <Button onClick={this.addToArtisanHandover} disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isAdmin}>
                                Add Artisan <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </Col>
                          </Row>

                          <Form.Group as={Row} className="px-2 mt-2 border">
                            <Tabs activeKey={this.state.artisanHandoverTabKey} onSelect={(e) => this.setState({ artisanHandoverTabKey: e })}>
                              {this.state.artisanHandovers.map((handover: IArtisanHandover, index) => (
                                <Tab eventKey={index} key={index} title={handover.artisanName !== "" ? handover.artisanName : "New Handover"}>
                                  <Form.Group as={Row} controlId="formHorizontalEmail" className="mt-2">
                                    <Form.Label column sm={4}>
                                      Artisan
                                    </Form.Label>
                                    <Col sm={8}>
                                      <Select
                                        options={this.state.users}
                                        onChange={(inv: any) => {
                                          handover.artisanId = inv.value;
                                          handover.artisanName = inv.label;
                                          this.setState({ artisanHandovers: this.state.artisanHandovers });
                                        }}
                                        placeholder="Select Artisan"
                                        isDisabled={!isArtisan && !isAdmin}
                                        value={handover.artisanId !== "" ? this.state.users.filter((option: any) => option.value === handover.artisanId) : null}
                                      />
                                    </Col>
                                  </Form.Group>
                                  <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={4}>
                                      Start Time
                                    </Form.Label>
                                    <Col sm={8}>
                                      <Form.Control
                                        type={"datetime-local"}
                                        placeholder="Start Time"
                                        disabled={!isArtisan && !isAdmin}
                                        value={moment.utc(handover.startTime).local().format("YYYY-MM-DD HH:mm")}
                                        width="100%"
                                        onChange={(e) => {
                                          handover.startTime = moment(e.target.value);
                                          this.setState({ artisanHandovers: this.state.artisanHandovers });
                                        }}
                                      />
                                    </Col>
                                  </Form.Group>
                                  <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={4}>
                                      End Time
                                    </Form.Label>
                                    <Col sm={8}>
                                      <Form.Control
                                        type={"datetime-local"}
                                        placeholder="End Time"
                                        disabled={!isArtisan && !isAdmin}
                                        value={moment.utc(handover.endTime).local().format("YYYY-MM-DD HH:mm")}
                                        width="100%"
                                        onChange={(e) => {
                                          handover.endTime = moment(e.target.value);
                                          this.setState({ artisanHandovers: this.state.artisanHandovers });
                                        }}
                                      />
                                    </Col>
                                  </Form.Group>

                                  <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={4}>
                                      Description
                                    </Form.Label>
                                    <Col sm={8}>
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value={handover?.description}
                                        onChange={(e) => {
                                          handover.description = e.target.value;
                                          this.setState({ artisanHandovers: this.state.artisanHandovers });
                                        }}
                                        disabled={!isArtisan && !isAdmin}
                                      />
                                    </Col>
                                  </Form.Group>
                                  <Col className="mb-2 text-center">
                                    <Button
                                      onClick={() => this.deleteArtisanHandover(handover, index)}
                                      variant="danger"
                                      title="Delete Handover"
                                      disabled={this.context.User.id !== this.state.jobCard.artisanId && !isAdmin}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                  </Col>
                                </Tab>
                              ))}
                            </Tabs>
                          </Form.Group>
                        </Col>
                      )}

                      <Col className="px-0">
                        <Form.Group as={Row}>
                          <Form.Label column sm={4}>
                            Artisan Hours Worked
                          </Form.Label>
                          <Col>
                            <Form.Control
                              width="100%"
                              type={"time"}
                              placeholder="Artisan Hours Worked"
                              disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isForeman && !isAdmin}
                              value={this.state.jobCard.artisanHoursWorked}
                              onChange={(e) => this.setSelectedField(e.target.value, "artisanHoursWorked")}
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontalEmail" className="mt-2">
                          <Form.Label column sm={4}>
                            Actual Completion Date
                          </Form.Label>
                          <Col>
                            <Form.Control
                              type={"datetime-local"}
                              placeholder="Date"
                              disabled={this.state.jobCard.statusId > 1 && !isArtisan && !isForeman && !isAdmin}
                              value={moment.utc(this.state.jobCard?.artisanCompletedDate).local().format("YYYY-MM-DD HH:mm")}
                              width="100%"
                              onChange={(e) => this.setSelectedField(moment(e.target.value), "artisanCompletedDate")}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </span>
                  ) : null}

                  {this.state.jobCard.statusId > 2 ? (
                    <span>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Job Card Effectiveness Review - Maintenance Admin</Form.Label>
                        <Col sm={12}>
                          <InputGroup>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              disabled={this.state.jobCard.statusId > 2 && !isForeman && !isAdmin}
                              value={this.state.jobCard.correctiveActionEffectivenessReview}
                              onChange={(e) => this.setSelectedField(e.target.value, "correctiveActionEffectivenessReview")}
                            />
                          </InputGroup>
                        </Col>
                      </Form.Group>
                    </span>
                  ) : null}

                  {this.state.jobCard.statusId > 3 ? (
                    <span>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Maintenance Manager Review</Form.Label>
                        <Col sm={12}>
                          <InputGroup>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              disabled={this.state.jobCard.statusId > 3 && !isManager && !isAdmin}
                              value={this.state.jobCard.jobCardMaintenanceManagerReview}
                              onChange={(e) => this.setSelectedField(e.target.value, "jobCardMaintenanceManagerReview")}
                            />
                          </InputGroup>
                        </Col>
                      </Form.Group>
                    </span>
                  ) : null}
                </Form>
              </Col>

              {this.state.jobCard.id ? (
                <Col style={{ overflowY: "auto", maxHeight: "70vh" }}>
                  <Tabs activeKey={this.state.tabKey} onSelect={(k) => this.setTab(k)} id="uncontrolled-tab-example" className="mb-3">
                    <Tab className="" eventKey="comments" title="Comments">
                      <div className="">
                        {this.state.jobCard.comments?.map((comment: IJobCardCommentList) => {
                          return (
                            <Card className="mb-2" key={comment.id}>
                              <Card.Body className="p-3">{comment.text}</Card.Body>
                              <Card.Footer className="p-2">
                                <small className="ml-auto d-block text-right">
                                  {comment.createdByText} at {moment(comment.createdOn).format("HH:mm on DD/MM/YYYY")}
                                </small>
                              </Card.Footer>
                            </Card>
                          );
                        })}
                      </div>
                      <div className="">
                        <InputGroup>
                          <Form.Control
                            as="textarea"
                            placeholder="New Comment..."
                            rows={2}
                            value={this.state.tempComment}
                            onChange={(e) => this.setState({ tempComment: e.target.value })}
                          />
                          <InputGroup.Append>
                            <Button variant="primary" onClick={() => this.postComment()}>
                              Post
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                    </Tab>
                    <Tab eventKey="whys" title="5 Why's">
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>
                          <span>Why 1</span> {this.state.selectedCompany.requiredMaintenanceWhy && <span className="text-danger">*</span>}
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            disabled={this.state.jobCard.statusId > 2 && !isForeman && !isAdmin}
                            as="textarea"
                            rows={3}
                            value={this.state.jobCard.why1}
                            onChange={(e) => this.setSelectedField(e.target.value, "why1")}
                          />
                        </Col>
                        {this.state.jobCard.statusId > 1 && isArtisan && this.state.selectedCompany.requiredMaintenanceWhy && (
                          <Form.Text>Why 1 is required</Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Why 2</Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            disabled={this.state.jobCard.statusId > 2 && !isForeman && !isAdmin}
                            as="textarea"
                            rows={3}
                            value={this.state.jobCard.why2}
                            onChange={(e) => this.setSelectedField(e.target.value, "why2")}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Why 3</Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            disabled={this.state.jobCard.statusId > 2 && !isForeman && !isAdmin}
                            as="textarea"
                            rows={3}
                            value={this.state.jobCard.why3}
                            onChange={(e) => this.setSelectedField(e.target.value, "why3")}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Why 4</Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            disabled={this.state.jobCard.statusId > 2 && !isForeman && !isAdmin}
                            as="textarea"
                            rows={3}
                            value={this.state.jobCard.why4}
                            onChange={(e) => this.setSelectedField(e.target.value, "why4")}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Why 5</Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            disabled={this.state.jobCard.statusId > 2 && !isForeman && !isAdmin}
                            as="textarea"
                            rows={3}
                            value={this.state.jobCard.why5}
                            onChange={(e) => this.setSelectedField(e.target.value, "why5")}
                          />
                        </Col>
                      </Form.Group>
                    </Tab>
                    <Tab className="" eventKey="costs" title="Costs">
                      <div className="">
                        <Table hover size="sm">
                          <thead>
                            <tr>
                              <th style={{ width: "40%" }}>Type</th>
                              <th>AdHoc Type</th>
                              <th style={{ width: "20%" }}>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.jobCard.costs?.map((cost: IJobCardCostList, index: number) => {
                              return (
                                <tr>
                                  <td>
                                    <Select
                                      as="input"
                                      options={this.state.costTypes}
                                      onChange={(cost: any) => this.setSelectedCostField(index, cost.value, "jobCardCostTypeId")}
                                      value={
                                        cost.jobCardCostTypeId ? this.state.costTypes.filter((option: any) => option.value === cost.jobCardCostTypeId) : null
                                      }
                                      placeholder="Select Cost Type"
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      as="textarea"
                                      placeholder="AdHoc Type"
                                      rows={1}
                                      value={cost.adHocType}
                                      onChange={(e) => this.setSelectedCostField(index, e.target.value, "adHocType")}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      placeholder="Value"
                                      value={cost.costValue}
                                      onChange={(e) => this.setSelectedCostField(index, parseFloat(e.target.value), "costValue")}
                                    />
                                  </td>
                                  {cost.edited === true ? (
                                    <td>
                                      <Button variant="success" onClick={() => this.saveCost(index)}>
                                        <FontAwesomeIcon icon={faSave} />
                                      </Button>{" "}
                                    </td>
                                  ) : null}
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={4}>
                                <Button variant="primary" block onClick={() => this.addCost()}>
                                  Add Cost
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Tab>

                    <Tab className="" eventKey="docs" title="Documents">
                      <div className="">
                        <Accordion activeKey={this.state.tabDocKey} onSelect={(k) => this.setDocKey(k)}>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                              Description{" "}
                              <Badge variant="danger" className="float-right">
                                {this.state.jobCard.files.filter((f) => f.fileType === 1).length}
                              </Badge>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                <Table size="sm">
                                  <tbody>
                                    {this.state.jobCard.files
                                      .filter((f) => f.fileType === 1)
                                      .map((file: IJobCardFile) => {
                                        return (
                                          <tr>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" onClick={() => this.downloadDoc(file.id, file.fileName, file.contentType)}>
                                                <FontAwesomeIcon icon={faDownload} />
                                              </Button>
                                            </td>
                                            <td>{file.fileName}</td>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" variant="danger" onClick={() => this.deleteFile(file.id)}>
                                                <FontAwesomeIcon icon={faTimes} />
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                                <StyledDropzone fileUploadCallback={(e: any) => this.fileUpload(e, 1)} />
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                              Maintenance Action
                              <Badge variant="danger" className="float-right">
                                {this.state.jobCard.files.filter((f) => f.fileType === 2).length}
                              </Badge>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                              <Card.Body>
                                <Table size="sm">
                                  <tbody>
                                    {this.state.jobCard.files
                                      .filter((f) => f.fileType === 2)
                                      .map((file: IJobCardFile) => {
                                        return (
                                          <tr>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" onClick={() => this.downloadDoc(file.id, file.fileName, file.contentType)}>
                                                <FontAwesomeIcon icon={faDownload} />
                                              </Button>
                                            </td>
                                            <td>{file.fileName}</td>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" variant="danger" onClick={() => this.deleteFile(file.id)}>
                                                <FontAwesomeIcon icon={faTimes} />
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                                <StyledDropzone fileUploadCallback={(e: any) => this.fileUpload(e, 2)} />
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="5">
                              Cost Related{" "}
                              <Badge variant="danger" className="float-right">
                                {this.state.jobCard.files.filter((f) => f.fileType === 5).length}
                              </Badge>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="5">
                              <Card.Body>
                                <Table size="sm">
                                  <tbody>
                                    {this.state.jobCard.files
                                      .filter((f) => f.fileType === 5)
                                      .map((file: IJobCardFile) => {
                                        return (
                                          <tr>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" onClick={() => this.downloadDoc(file.id, file.fileName, file.contentType)}>
                                                <FontAwesomeIcon icon={faDownload} />
                                              </Button>
                                            </td>
                                            <td>{file.fileName}</td>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" variant="danger" onClick={() => this.deleteFile(file.id)}>
                                                <FontAwesomeIcon icon={faTimes} />
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                                <StyledDropzone fileUploadCallback={(e: any) => this.fileUpload(e, 5)} />
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </div>
                    </Tab>

                    <Tab className="" eventKey="audit" title="Audit">
                      <div className="">
                        <Table>
                          <tbody>
                            {this.state.jobCard.audits?.map((audit: any) => {
                              return (
                                <tr>
                                  <td>{moment(audit.createdOn).format("DD/MM/YYYY HH:mm")}</td>
                                  <td>{audit.message}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Tab>

                    {userDefinedFields}

                    {this.state.jobCard.source === "Planned" && (
                      <Tab className="" eventKey="tasks" title="Tasks">
                        <div className="">
                          {isAdmin && (
                            <Row className="justify-content-center align-items-center mb-2">
                              <Col className="text-end">
                                <Button onClick={this.addTaskToJobCard} disabled={this.state.jobCard.statusId > 1 && !isAdmin}>
                                  Add Task <FontAwesomeIcon icon={faPlus} />
                                </Button>
                              </Col>
                            </Row>
                          )}
                          {this.state.jobCard.plannedJobCardTasks?.map((task, index) => (
                            <div key={index}>
                              <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col lg={1} className="d-flex align-items-center justify-content-center">
                                  <Form.Check
                                    type="checkbox"
                                    checked={task.completedDate !== null && task.completedDate !== undefined}
                                    onChange={(e) => this.checkTask(task.id as string)}
                                    disabled={!isAdmin && !isArtisan}
                                  />
                                </Col>

                                <Col lg={isAdmin ? 9 : 10}>
                                  <Form.Control
                                    value={task.description}
                                    placeholder="Description"
                                    readOnly={!isAdmin}
                                    onChange={(e) => {
                                      let tempArr = this.state.jobCard.plannedJobCardTasks ?? [];
                                      tempArr[index].description = e.target.value;
                                      this.setSelectedField(tempArr, "plannedJobCardTasks");
                                    }}
                                  />
                                </Col>

                                {isAdmin && (
                                  <Col className="mb-2 text-center" lg={1}>
                                    <Button onClick={() => this.deletePlannedTask(task, index)} variant="danger" title="Delete Task" disabled={!isAdmin}>
                                      <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                  </Col>
                                )}
                                <Row>
                                  <Col xs="auto" className="ml-auto pl-1">
                                    <p>{task.completedByName && !task.completedDate ? "Unchecked" : "Checked"}:</p>
                                  </Col>
                                  <Col xs="auto" className="p-0">
                                    <b>
                                      {task.completedByName ?? ""} {task.completedDate ? moment(task.completedDate).format("HH:mm DD MMM") : ""}
                                    </b>
                                  </Col>
                                </Row>
                              </Form.Group>
                              <hr />
                            </div>
                          ))}
                        </div>
                      </Tab>
                    )}
                  </Tabs>
                </Col>
              ) : null}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row className="w-100">
              <Col className="text-start">
                <ul>
                  {errorsArr.length > 0 &&
                    errorsArr.map((err: any, index: number) => (
                      <li key={index}>
                        <p className="text-danger">{err}</p>
                      </li>
                    ))}
                </ul>
              </Col>
              <Col className="text-end">
                {this.state.jobCard.statusId === 3 &&
                (this.state.jobCard.correctiveActionEffectivenessReview === null ||
                  this.state.jobCard.correctiveActionEffectivenessReview === undefined ||
                  this.state.jobCard.correctiveActionEffectivenessReview === "") ? (
                  <p className="text-danger">Please complete the Job Card Effectiveness Review</p>
                ) : null}
                {this.state.jobCard.statusId === 4 &&
                (this.state.jobCard.jobCardMaintenanceManagerReview === null ||
                  this.state.jobCard.jobCardMaintenanceManagerReview === undefined ||
                  this.state.jobCard.jobCardMaintenanceManagerReview === "") ? (
                  <p className="text-danger">Please complete the Maintenance Manager Review</p>
                ) : null}
              </Col>
            </Row>

            <Button variant="secondary" onClick={() => this.saveChanges()} disabled={this.state.saving}>
              Cancel
            </Button>

            {(this.state.jobCard.statusId === 3 && isForeman) ||
            (this.state.jobCard.statusId === 4 && isManager) ||
            (this.state.jobCard.statusId === 3 && isAdmin) ||
            (this.state.jobCard.statusId === 4 && isAdmin) ? (
              <span>
                <Button
                  variant="success"
                  disabled={
                    (this.state.jobCard.statusId === 3 &&
                      (this.state.jobCard.correctiveActionEffectivenessReview === null ||
                        this.state.jobCard.correctiveActionEffectivenessReview === undefined ||
                        this.state.jobCard.correctiveActionEffectivenessReview === "")) ||
                    (this.state.jobCard.statusId === 4 &&
                      (this.state.jobCard.jobCardMaintenanceManagerReview === null ||
                        this.state.jobCard.jobCardMaintenanceManagerReview === undefined ||
                        this.state.jobCard.jobCardMaintenanceManagerReview === "")) ||
                    this.state.saving
                  }
                  onClick={() => {
                    this.approveReject(true);
                  }}
                >
                  {!this.state.saving ? "Approve" : <Spinner animation="grow" size="sm" />}
                </Button>
                &nbsp;
                <Button
                  variant="danger"
                  onClick={() => {
                    this.approveReject(false);
                  }}
                  disabled={this.state.saving}
                >
                  {!this.state.saving ? "Reject" : <Spinner animation="grow" size="sm" />}
                </Button>
              </span>
            ) : null}

            <Button
              hidden={
                this.state.jobCard.statusId < 1 ||
                (this.state.jobCard.statusId === 3 &&
                  (this.state.jobCard.correctiveActionEffectivenessReview === null || this.state.jobCard.correctiveActionEffectivenessReview === undefined))
              }
              variant="info"
              disabled={
                this.state.saving || this.state.jobCard.maintenanceTypeId === "" || this.state.jobCard.operationalUnitId === ""
                // ||this.state.jobCard.jobCardTools.filter((f) => !f.toolsReturned).length === 0
              }
              onClick={() => {
                this.saveDraft();
              }}
            >
              {!this.state.savingDraft ? this.state.jobCard.statusId >= 1 ? "Save Draft" : "Create" : <Spinner animation="grow" size="sm" />}
            </Button>

            {(this.state.jobCard.statusId === 2 && isArtisan) || this.state.jobCard.statusId <= 2 || isAdmin ? (
              <>
                <Button
                  hidden={this.state.jobCard.statusId > 2}
                  variant="primary"
                  disabled={
                    this.state.saving ||
                    this.state.jobCard.maintenanceTypeId === "" ||
                    this.state.jobCard.operationalUnitId === "" ||
                    (isArtisan && errorsArr.length > 0)
                  }
                  onClick={() => {
                    this.save();
                  }}
                >
                  {!this.state.saving ? this.state.jobCard.statusId >= 1 ? "Submit" : "Create" : <Spinner animation="grow" size="sm" />}
                </Button>
              </>
            ) : null}
          </Modal.Footer>
        </Modal>

        <Modal centered show={this.state.showConfirmationDialog} onHide={() => this.props.onClose()} animation={true} size="sm">
          <Modal.Header>
            <Modal.Title>Save Changes</Modal.Title>
          </Modal.Header>
          <ModalBody>Do you want to save your changes?</ModalBody>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.cancelConfirmationDialog()}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.saveDraft();
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
