import { Button, Modal } from "react-bootstrap";

import { confirmAlert } from "react-confirm-alert";

interface IOptions {
  confirmFunction: any;
  title?: string;
  message?: string;
  closeFunction?: any;
  confirmButtonText?: string;
  closeButtonText?: string;
}

export function ConfirmationDialog(options: IOptions) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui">
          <Modal show={true} onHide={() => onClose()} animation={false}>
            <Modal.Header>
              <Modal.Title>{options.title ?? "Confirm"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{options.message ?? "Are you sure?"}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => onClose()}>
                {options.closeButtonText ?? "No"}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  options.confirmFunction();
                  onClose();
                }}
              >
                {options.confirmButtonText ?? "Yes"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    },
  });
}
