import React from "react";
import { Spinner } from "react-bootstrap";
import { postDataNonJSON } from "../../helpers/Data";

interface IState {
  verified: boolean;
  regenerated: boolean;
}

interface IProp {}

export default class QueryParamsExample extends React.Component<IProp, IState> {
  constructor(props: IProp) {
    super(props);
    this.state = { verified: false, regenerated: false };
  }

  componentDidMount() {
    this.verifyEmail();
  }

  verifyEmail = () => {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let userId = urlParams.get("userId");
    let token = urlParams.get("token");
    postDataNonJSON("users/confirm", { userId: userId, token: token }).then((response) => {
      if (response.ok) {
        this.setState({ verified: true });
        setTimeout(function () {
          window.location.href = window.location.origin;
        }, 2000);
      } else if (response.status === 470) {
        this.setState({ regenerated: true });
      }
    });
  };

  render() {
    return (
      <div style={{ padding: "50px" }}>
        {this.state.regenerated ? (
          <h1 className="text-center">Your confirmation link has expired. Please check your email for a new link.</h1>
        ) : (
          <h1 className="text-center">{this.state.verified ? "Emailed verified. Redirecting to login page" : "Verifying Email"}</h1>
        )}
        <h1 className="text-center">
          <Spinner className="ml-auto mr-auto" animation="grow" />
        </h1>
      </div>
    );
  }
}
