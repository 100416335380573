import { Route, Switch } from "react-router-dom";

import Announcement from "../screens/admin/Announcement";
import Categories from "../screens/admin/Categories";
import CategoryDashboard from "../screens/reports/CategoryDashboard";
import Companies from "../screens/admin/Company";
import Dashboard from "../screens/reports/Dashboard";
import DefinedFieldsDashboard from "../screens/reports/DefinedFieldsDashboard";
import Departments from "../screens/admin/Departments";
import Home from "../screens/Home";
import { INavLink } from "../entities/Navigation";
import JobCardDefinedFieldsDashboard from "../screens/reports/JobCardDefinedFieldsDashboard";
import MachineDashboard from "../screens/reports/MachineDashboard";
import MachineRegister from "../screens/admin/MachineRegister";
import MachineSection from "../screens/admin/MachineSection";
import MaintenanceDashboard from "../screens/reports/MaintenanceDashboard";
import MaintenanceTypes from "../screens/admin/MaintenanceType";
import OperationalUnits from "../screens/admin/OperationalUnits";
import React from "react";
import Users from "../screens/admin/Users";

let routes: any = [];

routes = [
  {
    path: "/home",
    object: (props: any) => <Home location={props.location} history={props.history} match={props.match} />,
    title: "Home",
    diplayOnNav: true,
    hideMaintenance: false,
    roles: [],
  },
  {
    path: "/",
    object: (props: any) => <Home location={props.location} history={props.history} match={props.match} />,
    title: "Home",
    diplayOnNav: false,
    hideMaintenance: false,
    roles: [],
  },

  {
    path: "/admin",
    object: (props: any) => null, //<Trip tripId={(props.location.state as any)?.tripId} />,
    title: "Admin",
    diplayOnNav: true,
    hideMaintenance: false,
    roles: ["Administrators", "SuperAdministrators"],
    children: [
      {
        path: "/companies",
        object: (props: any) => <Companies /* tripId={(props.location.state as any)?.tripId} */ />,
        title: "Companies",
        diplayOnNav: true,
        hideMaintenance: false,
        roles: ["Administrators", "SuperAdministrators"],
      },
      {
        path: "/users",
        object: (props: any) => <Users />,
        title: "Users",
        diplayOnNav: true,
        hideMaintenance: false,
        roles: ["Administrators", "SuperAdministrators"],
      },
      {
        path: "/departments",
        object: (props: any) => <Departments />,
        title: "Departments",
        diplayOnNav: true,
        hideMaintenance: false,
        roles: ["Administrators", "SuperAdministrators"],
      },
      {
        path: "/categories",
        object: (props: any) => <Categories />,
        title: "Categories of Non-Conformance/Failure",
        diplayOnNav: true,
        hideMaintenance: false,
        roles: ["Administrators", "SuperAdministrators"],
      },
      {
        path: "/announcements",
        object: (props: any) => <Announcement />,
        title: "Send Announcement",
        diplayOnNav: true,
        hideMaintenance: false,
        roles: ["SuperAdministrators"],
      },
    ],
  },
  {
    path: "/maintenanceAdmin",
    object: (props: any) => null, //<Trip tripId={(props.location.state as any)?.tripId} />,
    title: "Maintenance Admin",
    diplayOnNav: true,
    hideMaintenance: false,
    roles: ["Administrators", "SuperAdministrators"],
    children: [
      {
        path: "/maintenanceTypes",
        object: (props: any) => <MaintenanceTypes />,
        title: "Maintenance Priorities",
        diplayOnNav: true,
        hideMaintenance: true,
        roles: ["Administrators", "SuperAdministrators"],
      },
      {
        path: "/machines",
        object: (props: any) => <MachineRegister />,
        title: "Machines",
        diplayOnNav: true,
        hideMaintenance: true,
        roles: ["Administrators", "SuperAdministrators"],
      },
      {
        path: "/machineSections",
        object: (props: any) => <MachineSection />,
        title: "Machine Sections",
        diplayOnNav: true,
        hideMaintenance: true,
        roles: ["Administrators", "SuperAdministrators"],
      },
      {
        path: "/operationalUnits",
        object: (props: any) => <OperationalUnits />,
        title: "Operational Units",
        diplayOnNav: true,
        hideMaintenance: true,
        roles: ["Administrators", "SuperAdministrators"],
      },
    ],
  },
  {
    path: "/reports",
    object: (props: any) => null, //<Trip tripId={(props.location.state as any)?.tripId} />,
    title: "Reports",
    diplayOnNav: true,
    hideMaintenance: false,
    roles: [],
    children: [
      {
        path: "/dashboard",
        object: (props: any) => <Dashboard /* tripId={(props.location.state as any)?.tripId} */ />,
        title: "Quality Dashboard",
        diplayOnNav: true,
        hideMaintenance: false,
        roles: [],
        type: "car",
      },
      {
        path: "/categoryDashboard",
        object: (props: any) => <CategoryDashboard /* tripId={(props.location.state as any)?.tripId} */ />,
        title: "Quality Category Dashboard",
        diplayOnNav: true,
        hideMaintenance: false,
        roles: [],
        type: "car",
      },
      {
        path: "/definedfieldsdashboard",
        object: (props: any) => <DefinedFieldsDashboard /* tripId={(props.location.state as any)?.tripId} */ />,
        title: "Quality Defined Fields Dashboard",
        diplayOnNav: true,
        hideMaintenance: false,
        roles: [],
        type: "car",
      },
      {
        path: "/maintenanceDashboard",
        object: (props: any) => <MaintenanceDashboard />,
        title: "Maintenance Dashboard",
        diplayOnNav: true,
        hideMaintenance: false,
        roles: [],
        type: "maintenance",
      },
      {
        path: "/machineDashboard",
        object: (props: any) => <MachineDashboard />,
        title: "Machine Dashboard",
        diplayOnNav: true,
        hideMaintenance: false,
        roles: [],
        type: "maintenance",
      },

      {
        path: "/maintenancedefinedfieldsdashboard",
        object: (props: any) => <JobCardDefinedFieldsDashboard />,
        title: "Maintenance Defined Fields Dashboard",
        diplayOnNav: true,
        hideMaintenance: false,
        roles: [],
        type: "maintenance",
      },
    ],
  },
];

export default routes;

export function CheckRoleAccess(Roles: any, Routes: any) { 
  var result: any = Routes.filter((f: any) => {
    if (f.roles.length === 0) return true;
    var inRole = false;
    for (var i = 0; i < Roles.length && !inRole; i++) {
      inRole = f.roles.includes(Roles[i]);
    }
    return inRole;
  }); 
  return result;
}

interface IProps {
  roles: string[];
}

interface IState {
  routes: INavLink[];
}

export class NavRoutes extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    let tempRoutes: INavLink[] = [];
    routes.map((route: INavLink) => {
      if (route.children) {
        route.children.map((subRoute: INavLink) => {
          tempRoutes.push(subRoute);
        });
      } else {
        tempRoutes.push(route);
      }
    });

    this.state = { routes: CheckRoleAccess(this.props.roles, tempRoutes) };
  }

  render() {
    return (
      <Switch>
        {this.state.routes.map((route) => {
          return <Route exact path={route.path} key={route.path} render={route.object} />;
        })}
      </Switch>
    );
  }
}
