import { Badge, Button, Card, Col, Form, InputGroup, Modal, ModalBody, Row, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { deleteData, getData, getFile, postData, postDataWithFiles } from "../../helpers/Data";
import { faCalendar, faDownload, faFile, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";

import Accordion from "react-bootstrap/Accordion";
import { AuthContext } from "../../helpers/Contexts";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICommentList } from "../../entities/Comment";
import { ICompany } from "../../entities/Company";
import { ICorrectiveAction } from "../../entities/CorrectiveAction";
import { ICorrectiveActionFile } from "../../entities/CARFiles";
import { ICostList } from "../../entities/Cost";
import { IUser } from "../../entities/User";
import React from "react";
import Select from "react-select";
import { StyledDropzone } from "../../helpers/StyledDropzone";
import moment from "moment";
import { toast } from "react-toastify";

interface IProps {
  onClose: any;
  user: IUser;
  show: boolean;
  car?: ICorrectiveAction;
}

interface IState {
  car: ICorrectiveAction;
  showConfirmationDialog: boolean;
  showDocumentDialog: boolean;
  categories: any;
  departments: any;
  costTypes: any;
  modified: boolean;
  users: any;
  tabKey: any;
  tabDocKey: any;
  tempComment?: string;
  show: boolean;
  saving: boolean;
  savingDraft: boolean;
  selectedCompany: ICompany;
}

export default class CorrectiveActionDialog extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      car: {
        date: new Date(),
        name: "",
        companyId: "",
        createdBy: "",
        createdOn: new Date(),
        status: "",
        statusId: 0,
        categoryId: "",
        departmentId: "",
        description: "",
        headOfDepartment: "",
        headOfDepartmentText: "",
        comments: [],
        costs: [],
        files: [],
        categoryText: "",
        departmentText: "",
        saveDraft: false,
      },
      showConfirmationDialog: false,
      showDocumentDialog: false,
      categories: [],
      departments: [],
      costTypes: [],
      modified: false,
      users: [],
      tabKey: "comments",
      tabDocKey: 0,
      show: this.props.show,
      saving: false,
      savingDraft: false,
      selectedCompany: {
        active: true,
        allocateDocumentNumber: "",
        artisanHandoverFlag: false,
        companyPrefix: "",
        finalReviewTitle: "",
        jobCardPrefix: "",
        maintenance: false,
        name: "",
        plannedMaintenance: false,
        requiredMaintenanceWhy: false,
        requiredQualityWhy: false,
        toolsAndSparesFlag: false,
        tradingAsName: "",
        userDefinedFields: [],
      },
    };
  }
  static contextType = AuthContext;
  componentDidMount = () => {
    this.getCompany();
    this.refreshCategories();
    this.refreshDepartments();
    this.refreshUsers();
    this.refreshCostTypes();
  };

  componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.car !== this.props.car) {
      this.refreshCategories();
      this.refreshDepartments();
      this.refreshUsers();
      if (this.props.car?.id) {
        getData("car/" + this.props.car?.id).then((data) => {
          // console.log("car", data);
          this.setState({
            car: data,
            show: this.props.show,
          });
        });
      } else {
        this.setState({
          car: {
            date: new Date(),
            name: "",
            companyId: "",
            createdBy: "",
            createdOn: new Date(),
            status: "",
            statusId: 0,
            categoryId: "",
            departmentId: "",
            description: "",
            headOfDepartment: "",
            headOfDepartmentText: "",
            comments: [],
            costs: [],
            files: [],
            categoryText: "",
            departmentText: "",
            saveDraft: false,
          },
          show: this.props.show,
        });
      }
    }
  };

  cancelConfirmationDialog = () => {
    this.setState({ showConfirmationDialog: false });
    this.props.onClose();
  };

  changeUserDefinedField = (items: any, index: number) => {
    var joinedItems = items.map((item: any) => item.value).join(",");
    this.setSelectedUserDefinedField(
      joinedItems,
      `userDefinedField${index}` as any,
      (this.state.car as any)[`userDefinedField${index}TypeObject`]?.id as keyof ICorrectiveAction,
      `userDefinedField${index}Type` as any
    );
  };

  getCompany = () => {
    getData("companies/" + this.context.selectedCompany.id).then((data) => {
      this.setState({ selectedCompany: data });
    });
  };

  refreshCategories = () => {
    getData("categories/GetCategoryDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      // console.log("Cats", data);
      this.setState({ categories: data });
    });
  };
  refreshDepartments = () => {
    getData("departments/GetDepartmentDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      // console.log("Depts", data);
      this.setState({ departments: data });
    });
  };

  refreshUsers = () => {
    getData("users/GetUserDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      // console.log("Users", data);
      this.setState({ users: data });
    });
  };

  refreshComments = () => {
    getData("car/comments", { correctiveActionReportId: this.state.car.id }).then((data) => {
      let car = this.state.car;
      car.comments = data;
      this.setState({ car });
    });
  };

  refreshCostTypes = () => {
    getData("car/costtypes", {}).then((data) => {
      this.setState({ costTypes: data });
    });
  };

  refreshCosts = () => {
    getData("car/costs", { CorrectiveActionReportId: this.state.car.id }).then((data) => {
      this.setState({ costTypes: data });
    });
  };

  refreshDocs = () => {
    let car = this.state.car;
    getData("car/documents", { CorrectiveActionReportId: this.state.car.id }).then((data) => {
      car.files = data;
      this.setState({ car });
    });
  };

  postComment = () => {
    postData("car/comment", { correctiveActionReportId: this.state.car.id, text: this.state.tempComment }).then((res) => {
      this.refreshComments();
      this.setState({ tempComment: "" });
    });
  };

  setSelectedField = (value: any, field: keyof ICorrectiveAction) => {
    this.setState({ modified: true });
    let car = this.state.car;
    car = this.prop(car, field, value);
    this.setState({ car });
  };

  prop<T, K extends keyof T>(obj: T, key: K, newVal: any) {
    obj[key] = newVal;
    return obj;
  }

  revertStatus = () => {
    let cmd = { id: this.state.car.id };
    postData("car/revertstatus", cmd).then((res) => {
      this.props.onClose();
    });
  };

  setSelectedCostField = (index: number, value: any, field: keyof ICostList) => {
    let cost = this.state.car.costs[index];
    cost = this.prop(cost, field, value);
    cost.edited = true;

    this.setState({});
  };

  save = () => {
    this.setState({ saving: true });
    let car = this.state.car;
    car.companyId = car.companyId === "" ? this.context.selectedCompany.id : car.companyId;
    car.createdBy = this.context.User.id;
    postData("car", this.state.car).then((res) => {
      this.setState({ saving: false });
      if (res.status === 400) {
        toast.error("Could not create CAR: Category / Department not selected.");
        return;
      }

      this.props.onClose();
    });
  };

  saveChanges = () => {
    if (this.state.modified) {
      this.setState({ modified: false });
      this.setState({ showConfirmationDialog: true });
    } else if (
      this.state.car.statusId < 1 ||
      (this.state.car.statusId === 3 &&
        (this.state.car.correctiveActionEffectivenessReview === null || this.state.car.correctiveActionEffectivenessReview === undefined))
    ) {
      this.props.onClose();
    } else {
      this.props.onClose();
    }
  };

  saveDraft = () => {
    this.setState({ savingDraft: true });
    let car = this.state.car;
    car.companyId = car.companyId === "" ? this.context.selectedCompany.id : car.companyId;
    car.createdBy = this.context.User.id;
    car.saveDraft = true;
    postData("car", this.state.car).then((res) => {
      this.setState({ savingDraft: false });
      if (res.status === 400) {
        toast.error("Could not create CAR: Category / Department not selected.");
        return;
      }
      this.setState({ showConfirmationDialog: false });
      // console.log(res);
      this.props.onClose();
    });
  };

  addCost = () => {
    let car = this.state.car;
    let costs = car.costs;

    let newCostIdx = costs.push({
      correctiveActionReportId: car.id?.valueOf() + "",
      adHocType: "",
      costValue: 0,
      edited: true,
    } as ICostList);
    let cost = this.state.car.costs[newCostIdx - 1];
    car.costs = costs;
    this.setState({ car });
  };

  removeCost = (index: number) => {
    let car = this.state.car;
    let costs = car.costs;
    costs.splice(index, 1);
    car.costs = costs;
    this.setState({ car });
  };

  saveCost = (index: number) => {
    let cost = this.state.car.costs[index];
    cost.edited = false;
    postData("car/cost", cost).then((res) => {
      this.setState({});
      if (cost.costValue === undefined || cost.costValue === 0) {
        this.removeCost(index);
      }
    });
  };

  approveReject = (approve: boolean) => {
    this.setState({ saving: true });
    let car = this.state.car;
    car.companyId = car.companyId === "" ? this.context.selectedCompany.id : car.companyId;
    car.createdBy = this.context.User.id;
    postData("car", this.state.car).then((res) => {
      if (res.status === 400) {
        toast.error("Could not create CAR: Category / Department not selected.");
        this.setState({ saving: false });
        return;
      }
      postData("car/approvereject", { CARId: this.state.car.id, approve: approve }).then((res) => {
        this.setState({ saving: false });
        this.props.onClose();
      });
    });
  };

  setTab = (key: any) => {
    this.setState({ tabKey: key });
  };

  setDocKey = (key: any) => {
    this.setState({ tabDocKey: key });
  };

  fileUpload = (e: any, type: number) => {
    // console.log(e);
    try {
      postDataWithFiles(
        "car/document",
        {
          FileType: type,
          CorrectiveActionReportdId: this.state.car.id,
        },
        e
      ).then((res) => {
        this.refreshDocs();
      });
    } catch {}
  };

  downloadDoc = (id: string, name: string, contentType: string) => {
    getFile("files/" + id, name, contentType).then((data) => {
      //toast("Downloading file...", { type: 'info' })
    });
  };

  deleteFile = (id: string) => {
    deleteData("files/", { id: id }).then((data) => {
      this.refreshDocs();
    });
  };

  setSelectedUserDefinedField = (
    value: string,
    definedField: keyof ICorrectiveAction,
    definedFieldTypeValue: string,
    definedFieldTypeName: keyof ICorrectiveAction
  ) => {
    this.setSelectedField(value, definedField);
    this.setSelectedField(definedFieldTypeValue, definedFieldTypeName);
  };

  disableSubmitButtonForUDFs = () => {
    let disabled = false;

    for (let index = 0; index < 10; index++) {
      disabled =
        (this.state.car as any)[`userDefinedField${index + 1}TypeObject`] !== null &&
        (this.state.car as any)[`userDefinedField${index + 1}TypeObject`]?.required &&
        ((this.state.car as any)[`userDefinedField${index + 1}`] === null || (this.state.car as any)[`userDefinedField${index + 1}`] === "");

      if (disabled) {
        break;
      }
    }

    return disabled;
  };

  render() {
    let userId = this.context.User.id;
    let isHOD = this.state.car.headOfDepartment === userId;
    let isInvestigator = this.state.car.investigatorId === userId;
    let isQAManager = this.state.car.qaManager === userId;
    let isAdmin = this.context.User?.roles.includes("Administrators") || this.context.User?.roles.includes("SuperAdministrators");

    let disabledUDFs = false;
    if (isInvestigator) {
      disabledUDFs = this.disableSubmitButtonForUDFs();
    }

    let errorsArr: string[] = [];
    const errors = () => {
      if (this.state.car.statusId === 2 && isInvestigator) {
        if (!this.state.car.why1 && this.state.selectedCompany.requiredQualityWhy) errorsArr.push("Please add why 1");
        if (disabledUDFs) errorsArr.push("Please complete all required addtional fields");
      }
    };
    errors();

    const userDefinedFields = (
      <Tab eventKey="udfs" title="Additional Fields">
        {[...Array(10)].map(
          (x, i) =>
            (this.state.car as any)[`userDefinedField${i + 1}TypeObject`] != null &&
            !(this.state.car as any)[`userDefinedField${i + 1}TypeObject`].isJobCard && (
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  {(this.state.car as any)[`userDefinedField${i + 1}TypeObject`]?.name}{" "}
                  {(this.state.car as any)[`userDefinedField${i + 1}TypeObject`]?.required ? <span className="text-danger">*</span> : null}
                </Form.Label>
                <Col sm={8}>
                  {(() => {
                    switch ((this.state.car as any)[`userDefinedField${i + 1}TypeObject`]?.type) {
                      case "options":
                        return (
                          <Select
                            as="input"
                            options={(this.state.car as any)[`userDefinedField${i + 1}TypeObject`].options
                              .split(",")
                              .map((m: string) => ({ value: m, label: m }))}
                            onChange={(opt: any) =>
                              this.setSelectedUserDefinedField(
                                opt.value,
                                `userDefinedField${i + 1}` as any,
                                (this.state.car as any)[`userDefinedField${i + 1}TypeObject`]?.id as keyof ICorrectiveAction,
                                `userDefinedField${i + 1}Type` as any
                              )
                            }
                            value={{ value: (this.state.car as any)[`userDefinedField${i + 1}`], label: (this.state.car as any)[`userDefinedField${i + 1}`] }}
                            placeholder={"Select " + (this.state.car as any)[`userDefinedField${i + 1}TypeObject`]?.name}
                            required={(this.state.car as any)[`userDefinedField${i + 1}TypeObject`]?.required}
                          />
                        );
                      case "text":
                      case "number":
                        return (
                          <Form.Control
                            type={(this.state.car as any)[`userDefinedField${i + 1}TypeObject`]?.type}
                            disabled={this.state.car.statusId > 2 && !isHOD && !isAdmin}
                            value={(this.state.car as any)[`userDefinedField${i + 1}`]}
                            onChange={(e) => {
                              this.setSelectedUserDefinedField(
                                e.target.value,
                                `userDefinedField${i + 1}` as any,
                                (this.state.car as any)[`userDefinedField${i + 1}TypeObject`]?.id as keyof ICorrectiveAction,
                                `userDefinedField${i + 1}Type` as any
                              );
                            }}
                            required={(this.state.car as any)[`userDefinedField${i + 1}TypeObject`]?.required}
                          />
                        );

                      case "multipleOptions":
                        return (
                          <Select
                            isMulti
                            options={(this.state.car as any)[`userDefinedField${i + 1}TypeObject`].options
                              .split(",")
                              .map((m: string) => ({ value: m, label: m }))}
                            onChange={(e) => this.changeUserDefinedField(e, i + 1)}
                            value={(this.state.car as any)[`userDefinedField${i + 1}`]?.split(",").map((m: string) => ({ value: m, label: m }))}
                            placeholder={"Select " + (this.state.car as any)[`userDefinedField${i + 1}TypeObject`]?.name}
                            required={(this.state.car as any)[`userDefinedField${i + 1}TypeObject`]?.required}
                          />
                        );
                    }
                  })()}
                </Col>
              </Form.Group>
            )
        )}
      </Tab>
    );

    return (
      <div>
        <Modal
          dialogClassName="modal-90w"
          centered
          size={this.state.car.id ? "xl" : "lg"}
          show={this.state.show}
          onHide={() => this.props.onClose()}
          animation={true}
        >
          <Modal.Header>
            <Modal.Title className="mr-auto">
              {this.context.selectedCompany.companyPrefix ?? "CAR"} Number :{" "}
              <Badge variant="info">{this.state.car.correctiveActionNumber?.toString().padStart(6, "0")}</Badge>
              {this.state.car.statusId > 1 && isAdmin ? (
                <Button
                  className="ml-3"
                  size="sm"
                  variant="danger"
                  onClick={() => {
                    this.revertStatus();
                  }}
                >
                  Revert Status
                </Button>
              ) : (
                <></>
              )}
            </Modal.Title>
            <Modal.Title className="ml-auto">
              Cost :{" "}
              <Badge variant="primary">
                {this.state.car.costs
                  ?.reduce(function (prev, cur) {
                    return prev + cur.costValue;
                  }, 0)
                  .toFixed(2)}
              </Badge>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col style={{ overflowY: "auto", maxHeight: "70vh" }}>
                <Form>
                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Title
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        placeholder="Title"
                        value={this.state.car.name}
                        onChange={(e) => this.setSelectedField(e.target.value, "name")}
                        disabled={this.state.car.statusId > 1 && !isHOD && !isAdmin}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Job Number
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        placeholder="Job Number (Optional)"
                        value={this.state.car.jobNumber}
                        onChange={(e) => this.setSelectedField(e.target.value, "jobNumber")}
                        disabled={this.state.car.statusId > 1 && !isHOD && !isAdmin}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Initiated By
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control placeholder="User" readOnly value={this.state.car.createdByText ?? this.props.user.fullName} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Initiation Date
                    </Form.Label>
                    <Col sm={8}>
                      <DatePicker
                        locale="en-ZA"
                        disabled={true}
                        customInput={
                          <InputGroup className="mb-3">
                            <Form.Control placeholder="Date" disabled={true} value={moment(this.state.car?.createdOn).format("DD/MM/YYYY")} width="100%" />
                            <InputGroup.Append>
                              <InputGroup.Text id="basic-addon2">
                                <FontAwesomeIcon icon={faCalendar} />
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        }
                        onChange={(date) => this.setSelectedField(moment(date?.toString()).utc(true).toDate(), "date")}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Date Of Incident
                    </Form.Label>
                    <Col sm={8}>
                      <DatePicker
                        locale="en-ZA"
                        /*selected={options.date}*/ disabled={this.state.car.statusId > 1 && !isHOD && !isAdmin}
                        customInput={
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Date"
                              disabled={this.state.car.statusId > 1 && !isHOD && !isAdmin}
                              value={moment(this.state.car?.date).format("DD/MM/YYYY")}
                              width="100%"
                            />
                            <InputGroup.Append>
                              <InputGroup.Text id="basic-addon2">
                                <FontAwesomeIcon icon={faCalendar} />
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        }
                        onChange={(date) => this.setSelectedField(moment(date?.toString()).utc(true).toDate(), "date")}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Category
                    </Form.Label>
                    <Col sm={8}>
                      <Select
                        options={this.state.categories}
                        onChange={(cat: any) => this.setSelectedField(cat.value, "categoryId")}
                        value={this.state.car.categoryId ? this.state.categories.filter((option: any) => option.value === this.state.car.categoryId) : null}
                        placeholder="Select Category"
                        isDisabled={this.state.car.statusId > 1 && !isHOD && !isAdmin}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Applicable Department
                    </Form.Label>
                    <Col sm={8}>
                      <Select
                        options={this.state.departments}
                        onChange={(dep: any) => {
                          this.setSelectedField(dep.value, "departmentId");
                          this.setSelectedField(dep.headOfDepartmentText, "headOfDepartmentText");
                          this.setSelectedField(dep.headOfDepartment, "headOfDepartment");
                        }}
                        value={
                          this.state.car.departmentId ? this.state.departments.filter((option: any) => option.value === this.state.car.departmentId) : null
                        }
                        placeholder="Select Department"
                        isDisabled={this.state.car.statusId > 1 && !isAdmin}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      HOD
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control placeholder="HOD" value={this.state.car.headOfDepartmentText} readOnly />
                    </Col>
                  </Form.Group>
                  <Accordion>
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                      <Form.Label>Non-Conformance Description</Form.Label>
                      <Col sm={12}>
                        <InputGroup>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={this.state.car.description}
                            onChange={(e) => this.setSelectedField(e.target.value, "description")}
                            disabled={this.state.car.statusId > 1 && !isHOD && !isAdmin}
                          />
                          {this.state.car.statusId >= 1 ? (
                            <InputGroup.Append>
                              <Button
                                variant="secondary"
                                onClick={() => {
                                  this.setTab("docs");
                                  this.setDocKey("1");
                                }}
                                style={{ zIndex: 0 }}
                              >
                                <FontAwesomeIcon icon={faFile} />
                              </Button>
                            </InputGroup.Append>
                          ) : null}
                        </InputGroup>
                      </Col>
                    </Form.Group>
                  </Accordion>

                  {this.state.car.statusId > 1 || isHOD ? (
                    <span>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={4}>
                          Investigator
                        </Form.Label>
                        <Col sm={8}>
                          <Select
                            options={this.state.users}
                            onChange={(inv: any) => {
                              this.setSelectedField(inv.value, "investigatorId");
                            }}
                            value={
                              this.state.car.investigatorId ? this.state.users.filter((option: any) => option.value === this.state.car.investigatorId) : null
                            }
                            placeholder="Select Investigator"
                            isDisabled={this.state.car.statusId > 1 && !isHOD && !isAdmin}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={4}>
                          Target Completion Date
                        </Form.Label>
                        <Col sm={8}>
                          <DatePicker
                            /*selected={options.date}*/ disabled={this.state.car.statusId > 1 && !isHOD && !isAdmin}
                            customInput={
                              <InputGroup className="mb-3">
                                <Form.Control
                                  placeholder="User"
                                  disabled={this.state.car.statusId > 1 && !isHOD && !isAdmin}
                                  value={moment(this.state.car?.targetDate).format("DD/MM/YYYY")}
                                  width="100%"
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text id="basic-addon2">
                                    <FontAwesomeIcon icon={faCalendar} />
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            }
                            onChange={(date) => this.setSelectedField(moment(date?.toString()).utc(true).toDate(), "targetDate")}
                          />
                        </Col>
                      </Form.Group>
                    </span>
                  ) : null}

                  {(this.state.car.statusId > 1 && (this.state.car.investigatorId === userId || isAdmin || isHOD || isQAManager)) ||
                  this.state.car.statusId > 2 ? (
                    <span>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Immediate Action</Form.Label>
                        <Col sm={12}>
                          <InputGroup>
                            <Form.Control
                              disabled={this.state.car.statusId > 2 && !isHOD && !isAdmin}
                              as="textarea"
                              rows={3}
                              value={this.state.car.immediateCorrectiveAction}
                              onChange={(e) => this.setSelectedField(e.target.value, "immediateCorrectiveAction")}
                            />
                            <InputGroup.Append>
                              <Button
                                style={{ zIndex: 0 }}
                                variant="secondary"
                                onClick={() => {
                                  this.setTab("docs");
                                  this.setDocKey("2");
                                }}
                              >
                                <FontAwesomeIcon icon={faFile} />
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Root Cause</Form.Label>
                        <Col sm={12}>
                          <InputGroup>
                            <Form.Control
                              as="textarea"
                              disabled={this.state.car.statusId > 2 && !isHOD && !isAdmin}
                              rows={3}
                              value={this.state.car.rootCauseAnalysis}
                              onChange={(e) => this.setSelectedField(e.target.value, "rootCauseAnalysis")}
                            />
                            <InputGroup.Append>
                              <Button style={{ zIndex: 0 }} variant="success" onClick={() => this.setTab("whys")}>
                                5 Why's
                              </Button>
                              <Button
                                style={{ zIndex: 0 }}
                                variant="secondary"
                                onClick={() => {
                                  this.setTab("docs");
                                  this.setDocKey("3");
                                }}
                              >
                                <FontAwesomeIcon icon={faFile} />
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={4}>
                          Root Cause Department
                        </Form.Label>
                        <Col sm={8} style={{ zIndex: 999 }}>
                          <Select
                            menuPlacement="top"
                            options={this.state.departments}
                            onChange={(dep: any) => {
                              this.setSelectedField(dep.value, "rootCauseDepartmentId");
                            }}
                            value={
                              this.state.car.rootCauseDepartmentId
                                ? this.state.departments.filter((option: any) => option.value === this.state.car.rootCauseDepartmentId)
                                : null
                            }
                            placeholder="Select Department"
                            isDisabled={this.state.car.statusId > 2 && !isHOD && !isAdmin}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Root Cause Corrective Action</Form.Label>
                        <Col sm={12}>
                          <InputGroup>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              disabled={this.state.car.statusId > 2 && !isHOD && !isAdmin}
                              value={this.state.car.rootCauseCorrectiveAction}
                              onChange={(e) => this.setSelectedField(e.target.value, "rootCauseCorrectiveAction")}
                            />
                            <InputGroup.Append>
                              <Button
                                variant="secondary"
                                onClick={() => {
                                  this.setTab("docs");
                                  this.setDocKey("4");
                                }}
                              >
                                <FontAwesomeIcon icon={faFile} />
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                      </Form.Group>
                    </span>
                  ) : null}

                  {this.state.car.statusId > 2 ? (
                    <span>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Corrective Action Effectiveness Review - HOD</Form.Label>
                        <Col sm={12}>
                          <InputGroup>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              disabled={this.state.car.statusId > 2 && !isHOD && !isAdmin}
                              value={this.state.car.correctiveActionEffectivenessReview}
                              onChange={(e) => this.setSelectedField(e.target.value, "correctiveActionEffectivenessReview")}
                            />
                          </InputGroup>
                        </Col>
                      </Form.Group>
                    </span>
                  ) : null}

                  {this.state.car.statusId > 3 ? (
                    <span>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>
                          {this.state.selectedCompany.finalReviewTitle ? this.state.selectedCompany.finalReviewTitle : "HSE Manager / QA Manager / Director"}{" "}
                          Review
                        </Form.Label>
                        <Col sm={12}>
                          <InputGroup>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              disabled={this.state.car.statusId > 3 && !isQAManager && !isAdmin}
                              value={this.state.car.correctiveActionQAReview}
                              onChange={(e) => this.setSelectedField(e.target.value, "correctiveActionQAReview")}
                            />
                          </InputGroup>
                        </Col>
                      </Form.Group>
                    </span>
                  ) : null}
                </Form>
              </Col>

              {this.state.car.id ? (
                <Col style={{ overflowY: "auto", maxHeight: "70vh" }}>
                  <Tabs activeKey={this.state.tabKey} onSelect={(k) => this.setTab(k)} id="uncontrolled-tab-example" className="mb-3">
                    <Tab className="" eventKey="comments" title="Comments">
                      <div className="">
                        {this.state.car.comments?.map((comment: ICommentList) => {
                          return (
                            <Card className="mb-2" key={comment.id}>
                              <Card.Body className="p-3">{comment.text}</Card.Body>
                              <Card.Footer className="p-2">
                                <small className="ml-auto d-block text-right">
                                  {comment.createdByText} at {moment(comment.createdOn).format("HH:mm on DD/MM/YYYY")}
                                </small>
                              </Card.Footer>
                            </Card>
                          );
                        })}
                      </div>
                      <div className="">
                        <InputGroup>
                          <Form.Control
                            as="textarea"
                            placeholder="New Comment..."
                            rows={2}
                            value={this.state.tempComment}
                            onChange={(e) => this.setState({ tempComment: e.target.value })}
                          />
                          <InputGroup.Append>
                            <Button variant="primary" onClick={() => this.postComment()}>
                              Post
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                    </Tab>
                    <Tab eventKey="whys" title="5 Why's">
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>
                          <span>Why 1</span> {this.state.selectedCompany.requiredQualityWhy && <span className="text-danger">*</span>}
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            disabled={this.state.car.statusId > 2 && !isHOD && !isAdmin}
                            as="textarea"
                            rows={3}
                            value={this.state.car.why1}
                            onChange={(e) => this.setSelectedField(e.target.value, "why1")}
                          />
                        </Col>
                        {this.state.car.statusId > 1 && isInvestigator && this.state.selectedCompany.requiredQualityWhy && (
                          <Form.Text>Why 1 is required</Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Why 2</Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            disabled={this.state.car.statusId > 2 && !isHOD && !isAdmin}
                            as="textarea"
                            rows={3}
                            value={this.state.car.why2}
                            onChange={(e) => this.setSelectedField(e.target.value, "why2")}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Why 3</Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            disabled={this.state.car.statusId > 2 && !isHOD && !isAdmin}
                            as="textarea"
                            rows={3}
                            value={this.state.car.why3}
                            onChange={(e) => this.setSelectedField(e.target.value, "why3")}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Why 4</Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            disabled={this.state.car.statusId > 2 && !isHOD && !isAdmin}
                            as="textarea"
                            rows={3}
                            value={this.state.car.why4}
                            onChange={(e) => this.setSelectedField(e.target.value, "why4")}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label>Why 5</Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            disabled={this.state.car.statusId > 2 && !isHOD && !isAdmin}
                            as="textarea"
                            rows={3}
                            value={this.state.car.why5}
                            onChange={(e) => this.setSelectedField(e.target.value, "why5")}
                          />
                        </Col>
                      </Form.Group>
                    </Tab>
                    <Tab className="" eventKey="costs" title="Costs">
                      <div className="">
                        <Table hover size="sm">
                          <thead>
                            <tr>
                              <th style={{ width: "50%" }}>Type</th>
                              <th>AdHoc Type</th>
                              <th style={{ width: "20%" }}>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.car.costs?.map((cost: ICostList, index: number) => {
                              return (
                                <tr>
                                  <td>
                                    {" "}
                                    <Select
                                      as="input"
                                      options={this.state.costTypes}
                                      onChange={(cost: any) => this.setSelectedCostField(index, cost.value, "costTypeId")}
                                      value={cost.costTypeId ? this.state.costTypes.filter((option: any) => option.value === cost.costTypeId) : null}
                                      placeholder="Select Cost Type"
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      as="textarea"
                                      placeholder="AdHoc Type"
                                      style={{ height: "auto" }}
                                      value={cost.adHocType}
                                      onChange={(e) => this.setSelectedCostField(index, e.target.value, "adHocType")}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      placeholder="Value"
                                      value={cost.costValue}
                                      onChange={(e) => this.setSelectedCostField(index, parseFloat(e.target.value), "costValue")}
                                    />
                                  </td>
                                  {cost.edited === true ? (
                                    <td>
                                      <Button variant="success" onClick={() => this.saveCost(index)}>
                                        <FontAwesomeIcon icon={faSave} />
                                      </Button>{" "}
                                    </td>
                                  ) : null}
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={4}>
                                <Button variant="primary" block onClick={() => this.addCost()}>
                                  Add Cost
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Tab>

                    <Tab className="" eventKey="docs" title="Documents">
                      <div className="">
                        <Accordion activeKey={this.state.tabDocKey} onSelect={(k) => this.setDocKey(k)}>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                              Non-Conformance Description{" "}
                              <Badge variant="danger" className="float-right">
                                {this.state.car.files.filter((f) => f.fileType === 1).length}
                              </Badge>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                <Table size="sm">
                                  <tbody>
                                    {this.state.car.files
                                      .filter((f) => f.fileType === 1)
                                      .map((file: ICorrectiveActionFile) => {
                                        return (
                                          <tr>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" onClick={() => this.downloadDoc(file.id, file.fileName, file.contentType)}>
                                                <FontAwesomeIcon icon={faDownload} />
                                              </Button>
                                            </td>
                                            <td>{file.fileName}</td>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" variant="danger" onClick={() => this.deleteFile(file.id)}>
                                                <FontAwesomeIcon icon={faTimes} />
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                                <StyledDropzone fileUploadCallback={(e: any) => this.fileUpload(e, 1)} />
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                              Immediate Action{" "}
                              <Badge variant="danger" className="float-right">
                                {this.state.car.files.filter((f) => f.fileType === 2).length}
                              </Badge>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                              <Card.Body>
                                <Table size="sm">
                                  <tbody>
                                    {this.state.car.files
                                      .filter((f) => f.fileType === 2)
                                      .map((file: ICorrectiveActionFile) => {
                                        return (
                                          <tr>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" onClick={() => this.downloadDoc(file.id, file.fileName, file.contentType)}>
                                                <FontAwesomeIcon icon={faDownload} />
                                              </Button>
                                            </td>
                                            <td>{file.fileName}</td>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" variant="danger" onClick={() => this.deleteFile(file.id)}>
                                                <FontAwesomeIcon icon={faTimes} />
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                                <StyledDropzone fileUploadCallback={(e: any) => this.fileUpload(e, 2)} />
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="3">
                              Root Cause Analysis{" "}
                              <Badge variant="danger" className="float-right">
                                {this.state.car.files.filter((f) => f.fileType === 3).length}
                              </Badge>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                              <Card.Body>
                                <Table size="sm">
                                  <tbody>
                                    {this.state.car.files
                                      .filter((f) => f.fileType === 3)
                                      .map((file: ICorrectiveActionFile) => {
                                        return (
                                          <tr>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" onClick={() => this.downloadDoc(file.id, file.fileName, file.contentType)}>
                                                <FontAwesomeIcon icon={faDownload} />
                                              </Button>
                                            </td>
                                            <td>{file.fileName}</td>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" variant="danger" onClick={() => this.deleteFile(file.id)}>
                                                <FontAwesomeIcon icon={faTimes} />
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                                <StyledDropzone fileUploadCallback={(e: any) => this.fileUpload(e, 3)} />
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="4">
                              Root Cause Corrective Action{" "}
                              <Badge variant="danger" className="float-right">
                                {this.state.car.files.filter((f) => f.fileType === 4).length}
                              </Badge>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="4">
                              <Card.Body>
                                <Table size="sm">
                                  <tbody>
                                    {this.state.car.files
                                      .filter((f) => f.fileType === 4)
                                      .map((file: ICorrectiveActionFile) => {
                                        return (
                                          <tr>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" onClick={() => this.downloadDoc(file.id, file.fileName, file.contentType)}>
                                                <FontAwesomeIcon icon={faDownload} />
                                              </Button>
                                            </td>
                                            <td>{file.fileName}</td>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" variant="danger" onClick={() => this.deleteFile(file.id)}>
                                                <FontAwesomeIcon icon={faTimes} />
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                                <StyledDropzone fileUploadCallback={(e: any) => this.fileUpload(e, 4)} />
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="5">
                              Cost Related{" "}
                              <Badge variant="danger" className="float-right">
                                {this.state.car.files.filter((f) => f.fileType === 5).length}
                              </Badge>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="5">
                              <Card.Body>
                                <Table size="sm">
                                  <tbody>
                                    {this.state.car.files
                                      .filter((f) => f.fileType === 5)
                                      .map((file: ICorrectiveActionFile) => {
                                        return (
                                          <tr>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" onClick={() => this.downloadDoc(file.id, file.fileName, file.contentType)}>
                                                <FontAwesomeIcon icon={faDownload} />
                                              </Button>
                                            </td>
                                            <td>{file.fileName}</td>
                                            <td style={{ width: "40px" }}>
                                              <Button size="sm" variant="danger" onClick={() => this.deleteFile(file.id)}>
                                                <FontAwesomeIcon icon={faTimes} />
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                                <StyledDropzone fileUploadCallback={(e: any) => this.fileUpload(e, 5)} />
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </div>
                    </Tab>

                    <Tab className="" eventKey="audit" title="Audit">
                      <div className="">
                        <Table>
                          <tbody>
                            {this.state.car.audits?.map((audit: any) => {
                              return (
                                <tr>
                                  <td>{moment(audit.createdOn).format("DD/MM/YYYY HH:mm")}</td>
                                  <td>{audit.message}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Tab>

                    {userDefinedFields}
                  </Tabs>
                </Col>
              ) : null}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row className="w-100">
              <Col className="text-start">
                <ul>
                  {errorsArr.length > 0 &&
                    errorsArr.map((err: any, index: number) => (
                      <li key={index}>
                        <p className="text-danger">{err}</p>
                      </li>
                    ))}
                </ul>
              </Col>
              <Col className="text-right">
                {this.state.car.statusId === 3 &&
                (this.state.car.correctiveActionEffectivenessReview === null ||
                  this.state.car.correctiveActionEffectivenessReview === undefined ||
                  this.state.car.correctiveActionEffectivenessReview === "") ? (
                  <p className="text-danger">Please complete the Corrective Action Effectiveness Review</p>
                ) : null}
                {this.state.car.statusId === 4 &&
                (this.state.car.correctiveActionQAReview === null ||
                  this.state.car.correctiveActionQAReview === undefined ||
                  this.state.car.correctiveActionQAReview === "") ? (
                  <p className="text-danger">
                    Please complete the{" "}
                    {this.state.selectedCompany.finalReviewTitle ? this.state.selectedCompany.finalReviewTitle : "HSE Manager / QA Manager / Director"} Review
                  </p>
                ) : null}
              </Col>
            </Row>

            <Button variant="secondary" onClick={() => this.saveChanges()} disabled={this.state.saving}>
              Cancel
            </Button>

            {(this.state.car.statusId === 3 && isHOD) ||
            (this.state.car.statusId === 4 && isQAManager) ||
            (this.state.car.statusId === 3 && isAdmin) ||
            (this.state.car.statusId === 4 && isAdmin) ? (
              <span>
                <Button
                  variant="success"
                  disabled={
                    (this.state.car.statusId === 3 &&
                      (this.state.car.correctiveActionEffectivenessReview === null || this.state.car.correctiveActionEffectivenessReview === undefined)) ||
                    (this.state.car.statusId === 4 &&
                      (this.state.car.correctiveActionQAReview === null || this.state.car.correctiveActionQAReview === undefined)) ||
                    this.state.saving
                  }
                  onClick={() => {
                    this.approveReject(true);
                  }}
                >
                  {!this.state.saving ? "Approve" : <Spinner animation="grow" size="sm" />}
                </Button>
                &nbsp;
                <Button
                  variant="danger"
                  onClick={() => {
                    this.approveReject(false);
                  }}
                  disabled={this.state.saving}
                >
                  {!this.state.saving ? "Reject" : <Spinner animation="grow" size="sm" />}
                </Button>
              </span>
            ) : null}

            <Button
              hidden={
                this.state.car.statusId < 1 ||
                (this.state.car.statusId === 3 &&
                  (this.state.car.correctiveActionEffectivenessReview === null || this.state.car.correctiveActionEffectivenessReview === undefined))
              }
              variant="info"
              disabled={this.state.saving || this.state.car.categoryId === "" || this.state.car.departmentId === ""}
              onClick={() => {
                this.saveDraft();
              }}
            >
              {!this.state.savingDraft ? this.state.car.statusId >= 1 ? "Save Draft" : "Create" : <Spinner animation="grow" size="sm" />}
            </Button>

            {(this.state.car.statusId === 2 && isInvestigator) || this.state.car.statusId <= 2 || isAdmin ? (
              <>
                <Button
                  hidden={this.state.car.statusId > 2}
                  variant="primary"
                  disabled={
                    (this.state.car.statusId >= 1 && this.state.car.investigatorId === null) ||
                    this.state.saving ||
                    this.state.car.categoryId === "" ||
                    this.state.car.departmentId === "" ||
                    (isInvestigator && errorsArr.length > 0)
                  }
                  onClick={() => {
                    this.save();
                  }}
                >
                  {!this.state.saving ? this.state.car.statusId >= 1 ? "Submit" : "Create" : <Spinner animation="grow" size="sm" />}
                </Button>
              </>
            ) : null}
          </Modal.Footer>
        </Modal>

        <Modal centered show={this.state.showConfirmationDialog} onHide={() => this.props.onClose()} animation={true} size="sm">
          <Modal.Header>
            <Modal.Title>Save Changes</Modal.Title>
          </Modal.Header>
          <ModalBody>Do you want to save your changes?</ModalBody>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.cancelConfirmationDialog()}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.saveDraft();
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
