import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

interface IState {}

interface IProp {}

export default class NoCompany extends React.Component<IProp, IState> {
  render() {
    return (
      <div style={{ padding: "50px" }}>
        <h1 className="text-center">
          <FontAwesomeIcon icon={faExclamationTriangle} color="red" />{" "}
        </h1>
        <h1 className="text-center">No Companies</h1>
        <h3 className="text-center">You have no companies assigned to you. Please contact your administrator.</h3>
      </div>
    );
  }
}
