import { Button, Card, Form, Spinner } from "react-bootstrap";

import { AuthContext } from "../../helpers/Contexts";
import Container from "react-bootstrap/esm/Container";
import { LogIn } from "../../helpers/Data";
import React from "react";

interface IState {
  loading: boolean;
  email: string;
  password: string;
  errorText: string;
  twoFactor: boolean;
  code: string;
  userId: string;
}

interface IProp {}

export default class Login extends React.Component<IProp, IState> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.setToken = this.setToken.bind(this);
    this.state = {
      loading: false,
      email: "",
      password: "",
      errorText: "",
      twoFactor: false,
      code: "",
      userId: "",
    };
  }

  loginURL = "users/login";

  setToken(callback: Function, setUser: Function) {
    this.setState({ loading: true, errorText: "" });

    let url = this.state.twoFactor ? "users/login2fa" : "users/login";

    LogIn(url, { email: this.state.email, password: this.state.password, code: this.state.code, userId: this.state.userId }).then((user) => {
      switch (user.status) {
        case 403:
          this.setState({
            errorText: "You have been locked out due to 3 failed login attempts. Please wait 5 minutes and try again or contact support for assistance.",
            loading: false,
          });
          break;
        case 401:
          this.setState({ errorText: "Username/Password combination is invalid.", loading: false });
          break;
        case 470:
          this.setState({ errorText: "Email has not been verified yet. Please check your email and spam folders for a verification link.", loading: false });
          break;
        case 471:
          this.setState({ errorText: "The OTP is invalid.", loading: false });
          break;
        default:
          if (user.token !== "") {
            // console.log(user);
            localStorage.setItem("bearer-token", user.token);
            this.setState({ loading: false });
            callback();
          } else {
            if (user.twoFactorEnabled) this.setState({ twoFactor: true, userId: user.id, loading: false });
          }

          break;
      }
    });
  }

  render() {
    return (
      <AuthContext.Consumer>
        {({ isAuthed, setAuthed, setUser }) => (
          <div className="ml-auto mr-auto" style={{ maxWidth: "450px", padding: "1%", textAlign: "center" }}>
            <Card>
              <Card.Body>
                <img style={styles.loginImage} src="logo512.png" alt="Logo"></img>
                <h3 color="primary">Welcome</h3>
                <Container style={{ marginTop: "20px" }}>
                  <Form>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" onChange={(e) => this.setState({ email: e.target.value })} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" placeholder="Enter password" onChange={(e) => this.setState({ password: e.target.value })} />
                    </Form.Group>
                    {this.state.twoFactor ? (
                      <Form.Group>
                        <Form.Label>One-Time Pin</Form.Label>
                        <Form.Control type="" placeholder="Enter OTP" onChange={(e) => this.setState({ code: e.target.value })} />
                        <Form.Text className="text-muted">An OTP has been sent to {this.state.email}</Form.Text>
                      </Form.Group>
                    ) : null}
                  </Form>
                  <Button
                    variant="primary"
                    style={styles.loginButton}
                    onClick={() => {
                      this.setToken(setAuthed, setUser);
                    }}
                  >
                    {!this.state.loading ? "Log in" : <Spinner animation="grow" size="sm" />}
                  </Button>
                  <br />
                  <br />
                  <p className="text-danger">{this.state.errorText}</p>
                </Container>
              </Card.Body>
            </Card>
          </div>
        )}
      </AuthContext.Consumer>
    );
  }
}

const styles = {
  loginContainer: {
    margin: "20px",
    textAlign: "center",
  },
  loginImage: {
    maxWidth: "90%",
    marginBottom: "30px",
  },
  loginInput: {
    width: "100%",
    marginBottom: "20px",
  },
  loginButton: {
    width: "100%",
  },
  loginLogo: {
    width: "100%",
  },
};
