import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { deleteData, getData, postData } from "../../helpers/Data";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../helpers/Contexts";
import { ConfirmationDialog } from "../../helpers/ConfirmationDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICategoryList } from "../../entities/Categories";
import { ICompany } from "../../entities/Company";
import React from "react";
import Select from "react-select";

interface IProps {}

interface IState {
  text: string;
  categories: ICategoryList[];
  selectedCompany: ICompany;
  selectedCategory: ICategoryList;
  showEditDialog: boolean;
  users: any;
}

export default class Categories extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedCompany: {
        active: true,
        allocateDocumentNumber: "",
        artisanHandoverFlag: false,
        companyPrefix: "",
        finalReviewTitle: "",
        jobCardPrefix: "",
        maintenance: false,
        name: "",
        plannedMaintenance: false,
        requiredMaintenanceWhy: false,
        requiredQualityWhy: false,
        toolsAndSparesFlag: false,
        tradingAsName: "",
        userDefinedFields: [],
      },
      text: "TEST",
      categories: [],
      showEditDialog: false,
      selectedCategory: { name: "", companyId: "" },
      users: [],
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getCompany();
    this.refresh();
    this.refreshUsers(this.context.selectedCompany?.id);
  }

  refresh() {
    getData("categories", { companyId: this.context.selectedCompany?.id }).then((data) => {
      // console.log("Cats", data);
      this.setState({ categories: data });
    });
  }

  buttonClick = () => {
    ConfirmationDialog({ confirmFunction: this.complete, title: "YOH?", message: "Are you sure you wanna yoh?", confirmButtonText: "YEAH" });
  };

  complete = () => {
    this.setState({ text: "YOOOOOOOOOOO" });
  };

  getCompany = () => {
    getData("companies/" + this.context.selectedCompany.id).then((data) => {
      this.setState({ selectedCompany: data });
    });
  };

  openEditDialog = (department: ICategoryList) => {
    this.refreshUsers(department.companyId);
    this.setState({ selectedCategory: { ...department }, showEditDialog: true });
  };

  openCreateDialog = () => {
    this.setState({ selectedCategory: { name: "", companyId: "" }, showEditDialog: true });
  };

  onClose = () => {
    this.setState({ showEditDialog: false, selectedCategory: { name: "", companyId: "" } });
  };

  onSave = () => {
    let dept = this.state.selectedCategory;
    dept.companyId = this.context.selectedCompany.id;
    postData("categories", dept).then((res) => {
      this.setState({ showEditDialog: false, selectedCategory: { name: "", companyId: "" } }, () => this.refresh());
    });
  };

  refreshUsers = (id: string) => {
    getData("users/GetUserDDL", { companyId: id }).then((data) => {
      // console.log("Users", data);
      this.setState({ users: data });
    });
  };

  getUser = (id: string) => {
    let user = "";

    this.state.users.forEach((option: any) => {
      if (option.value === id) {
        user = option.label;
      }
    });

    return user;
  };

  setSelectedCategoryField = (value: any, field: keyof ICategoryList) => {
    let cat = this.state.selectedCategory;
    cat = this.prop(cat, field, value);
    this.setState({ selectedCategory: cat });
  };

  prop<T, K extends keyof T>(obj: T, key: K, newVal: any) {
    obj[key] = newVal;
    return obj;
  }

  delete = (id: string | undefined) => {
    deleteData("categories/", { id: id }).then((data) => {
      this.refresh();
    });
  };

  render() {
    return (
      <div>
        <Card style={{ width: "100%" }}>
          <Card.Header>
            <Card.Title>
              Categories
              <span className="float-right">
                <Button variant="primary" className="" onClick={() => this.openCreateDialog()}>
                  Add Category
                </Button>
              </span>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Table striped hover size="sm">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>
                        {this.state.selectedCompany.finalReviewTitle ? this.state.selectedCompany.finalReviewTitle : "HSE Manager / QA Manager / Director"}
                      </th>
                      <th style={{ width: "100px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.categories.length > 0
                      ? this.state.categories.map((cat: ICategoryList) => {
                          return (
                            <tr>
                              <td>{cat.name}</td>
                              <td>{cat.qaManager && this.state.users.length > 0 ? this.getUser(cat.qaManager) : null}</td>
                              <td>
                                <Button variant="primary" size="sm" onClick={() => this.openEditDialog(cat)}>
                                  <FontAwesomeIcon icon={faEdit} /> Edit
                                </Button>
                                <Button variant="danger" size="sm" onClick={() => this.delete(cat.id)}>
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Modal show={this.state.showEditDialog} onHide={() => this.onClose()} animation={false}>
          <Modal.Header>
            <Modal.Title>{this.state.selectedCategory.id ? "Edit " + this.state.selectedCategory.name : "Create Category"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={this.state.selectedCategory.name}
                  placeholder="Enter Category Name"
                  onChange={(e) => this.setSelectedCategoryField(e.target.value, "name")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  {this.state.selectedCompany.finalReviewTitle ? this.state.selectedCompany.finalReviewTitle : "HSE Manager / QA Manager / Director"}
                </Form.Label>
                <Select
                  options={this.state.users}
                  onChange={(inv: any) => {
                    this.setSelectedCategoryField(inv.value, "qaManager");
                  }}
                  value={
                    this.state.selectedCategory.qaManager
                      ? this.state.users.filter((option: any) => option.value === this.state.selectedCategory.qaManager)
                      : null
                  }
                  placeholder="Select QA Manager"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.onClose()}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.onSave();
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
