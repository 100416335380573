import { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  //   onClose: any;
  // show: boolean;
}

interface IState {
  show: boolean;
}

export default class OfflineModal extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: navigator.onLine,
    };
  }

  componentDidMount() {
    this.checkOnline();
  }

  componentDidUpdate() {
    this.checkOnline();
  }

  checkOnline() {
    window.addEventListener("online", () => {
      this.setState({ show: false });
      console.log("Online");
    });
    window.addEventListener("offline", () => {
      this.setState({ show: true });
      console.log("Offline");
    });
  }

  render() {
    return (
      <div>
        <Modal dialogClassName="offlineModal" centered size={"lg"} show={!navigator.onLine} onHide={() => null} animation={true}>
          <Modal.Header>
            <Modal.Title className="mr-auto" style={{ color: "white" }}>
              <FontAwesomeIcon icon={faExclamationCircle} size="lg" /> NO INTERNET CONNECTION
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ color: "white" }}>
            <h5>Please restore your internet connection to continue.</h5>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
