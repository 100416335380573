import * as htmlToImage from "html-to-image";

import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { faCalendar, faDownload } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../helpers/Contexts";
import { Bar } from "react-chartjs-2";
import { CategoryTable } from "../../entities/CategoryDashboard";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Component } from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICategoryDDL } from "../../entities/Categories";
import { IDeparmentDDL } from "../../entities/Departments";
import Select from "react-select";
import download from "downloadjs";
import { getData } from "../../helpers/Data";
import moment from "moment";
import { saveAs } from "file-saver";

interface IProps {}

interface IState {
  categories: ICategoryDDL[];
  categoryIds: string[];
  departmentIds: string[];
  departments: IDeparmentDDL[];
  filterItem: string;
  fromDate?: Date;
  graphData: CategoryTable;
  toDate?: Date;
}

export default class CategoryDashboard extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      categories: [],
      categoryIds: [],
      departmentIds: [],
      departments: [],
      filterItem: "1",
      fromDate: moment().startOf("year").toDate(),
      graphData: {
        labels: [],
        tables: [],
      },
      toDate: moment().endOf("day").toDate(),
    };
  }
  static contextType = AuthContext;

  componentDidMount = () => {
    this.refreshCategories();
    this.refreshDepartments();
  };

  refresh = async () => {
    getData("reports/categoryDashboard", {
      companyId: this.context.selectedCompany?.id,
      CategoryIds: this.state.categoryIds,
      DepartmentIds: this.state.departmentIds,
      from: this.state.fromDate !== undefined ? moment(this.state.fromDate).format("DD MMM YYYY") : "",
      to: this.state.toDate !== undefined ? moment(this.state.toDate).format("DD MMM YYYY hh:mm") : "",
    }).then((data) => {
      this.setState({ graphData: data });
    });
  };

  refreshCategories = () => {
    getData("categories/GetCategoryDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ categories: data });
    });
  };

  refreshDepartments = () => {
    getData("departments/GetDepartmentDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ departments: data });
    });
  };

  saveChartToPng = async (chartId: string, name: string) => {
    const canvas = document.getElementById(chartId) as HTMLCanvasElement;
    htmlToImage
      .toPng(canvas)
      .then((dataUrl) => {
        download(dataUrl, `${name}.png`);
      })
      .catch((err) => {
        console.error("Oops, something went wrong!", err);
      });
  };

  setCategoryItems = (items: any) => {
    let categoryIds: any[] = [];
    items.forEach((element: any) => {
      categoryIds.push(element.value);
    });
    this.setState({ categoryIds }, () => this.refresh());
  };

  setDepartmentItems = (items: any) => {
    let departmentIds: any[] = [];
    items.forEach((element: any) => {
      departmentIds.push(element.value);
    });
    this.setState({ departmentIds }, () => this.refresh());
  };

  setFilterItem = (item: any) => {
    let newFilterItem: string = item.value;

    let newFromDate = undefined;
    let newToDate = undefined;

    if (newFilterItem === "1") {
      newFromDate = moment().startOf("year").toDate();
      newToDate = moment().endOf("day").toDate();
    } else if (newFilterItem === "2") {
      newFromDate = moment().startOf("month").subtract(6, "months").toDate();
      newToDate = moment().endOf("day").toDate();
    }

    this.setState({ filterItem: newFilterItem, fromDate: newFromDate, toDate: newToDate }, () => this.refresh());
  };

  render() {
    const selectOptions = [
      { value: "1", label: "Year to Date" },
      { value: "2", label: "Custom" },
    ];

    let graphs;
    if (this.state.categoryIds.length > 0 && this.state.graphData.tables.length > 0) {
      graphs = this.state.graphData.tables.map((table) => {
        let data = {
          labels: this.state.graphData.labels,
          datasets: table.tableDataSets.map((department) => ({
            label: department.departmentName,
            data: department.data.map((value) => (value === 0 ? null : value)),
            backgroundColor: department.colour,
          })),
        };

        let options = {
          plugins: {
            datalabels: {
              display: true,
              align: "center",
              borderRadius: 3,
              font: {
                size: 16,
              },
              color: "#222",
              backgroundColor: "#ffffff66",
              formatter: (value: any) => {
                if (value) {
                  return value;
                }
              },
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          legend: {
            display: true,
            position: "top",
          },
        };

        return (
          <div className="mt-5" key={table.categoryName}>
            <Row>
              <Col>
                <h4>{table.categoryName}</h4>
                <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng(table.categoryName, `${table.categoryName}`)}>
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <div id={table.categoryName} style={{ backgroundColor: "white" }}>
                  <Bar height={400} width={window.innerWidth} plugins={[ChartDataLabels]} data={data} options={options} />
                </div>
              </Col>
              <Col />
            </Row>
          </div>
        );
      });
    }

    let row;
    if (Number(this.state.filterItem) < 2) {
      row = (
        <Row>
          <Col>Quality Category Dashboard</Col>
          <Row className="align-items-center mt-2">
            <Col sm={3} md={3} lg={5}>
              <Select options={this.state.categories} isMulti onChange={(e) => this.setCategoryItems(e)} placeholder="Select Categories" />
            </Col>
            <Col sm={3} md={3} lg={5}>
              <Select placeholder="Select Departments" isMulti options={this.state.departments} onChange={(e) => this.setDepartmentItems(e)} />
            </Col>
            <Col sm={3} md={3} lg={2}>
              <Select options={selectOptions} defaultValue={selectOptions[0]} onChange={(e) => this.setFilterItem(e)} placeholder="All" />
            </Col>
          </Row>
        </Row>
      );
    } else if (this.state.filterItem === "2") {
      row = (
        <Row>
          <Col>Quality Category Dashboard</Col>
          <Row className="align-items-center mt-2">
            <Col sm={3} md={3} lg={3}>
              <Select options={this.state.categories} isMulti onChange={(e) => this.setCategoryItems(e)} placeholder="Select Categories" />
            </Col>
            <Col sm={3} md={3} lg={3}>
              <Select placeholder="Select Departments" isMulti options={this.state.departments} onChange={(e) => this.setDepartmentItems(e)} />
            </Col>
            <Col sm={3} md={3} lg={2}>
              <Select options={selectOptions} onChange={(e) => this.setFilterItem(e)} />
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="customDatePickerWidth">
                <DatePicker
                  customInput={
                    <InputGroup className="">
                      <Form.Control placeholder="Date" value={moment(this.state.fromDate).format("DD/MM/YYYY")} />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">
                          <FontAwesomeIcon icon={faCalendar} />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  }
                  onChange={(date: Date) => this.setState({ fromDate: date }, () => this.refresh())}
                />
              </div>
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="customDatePickerWidth">
                <DatePicker
                  customInput={
                    <InputGroup className="">
                      <Form.Control placeholder="Date" value={moment(this.state.toDate).format("DD/MM/YYYY")} />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">
                          <FontAwesomeIcon icon={faCalendar} />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  }
                  onChange={(date: Date) => this.setState({ toDate: date }, () => this.refresh())}
                />
              </div>
            </Col>
          </Row>
        </Row>
      );
    }

    return (
      <div>
        <Card style={{ width: "100%" }}>
          <Card.Header>
            <Card.Title>{row}</Card.Title>
          </Card.Header>
          <Card.Body>{graphs}</Card.Body>
        </Card>
      </div>
    );
  }
}
