import Pagination from "react-bootstrap/Pagination";
import React from "react";

interface IPaginationProps {
  page: number;
  pageCount: number;
  total: number;
  pageCallback: any;
}

interface IPaginationState {
  page: number;
  pageCount: number;
  total: number;
  items: any[];
}

export default class PaginationHelper extends React.Component<
  IPaginationProps,
  IPaginationState
> {
  constructor(props: IPaginationProps) {
    super(props);
    this.state = {
      page: this.props.page,
      pageCount: this.props.pageCount,
      total: this.props.total,
      items: [],
    };
  }

  pageClick = (number: number) => {
    this.setState({ page: number });
    this.props.pageCallback(number);
  };

  render() {
    
    let items = [];
    for (let number = 1; number <= this.state.pageCount; number++) {
      items.push(
        <Pagination.Item
          key={this.state.pageCount}
          active={number === this.state.page}
          activeLabel={""}
          onClick={() => this.pageClick(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return <Pagination style={{display: "flex", justifyContent: "center"}}> {items}</Pagination>;
  }
}
