import { Button, Form, Modal } from "react-bootstrap";

import React from "react";

interface IProps {
  show: boolean;
  onClose: any;
}

interface IState {
  show: boolean;
}

export default class AboutDialog extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount = () => {};

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={() => this.props.onClose()} animation={false}>
          <Modal.Header>
            <Modal.Title>About</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Security</p>
            <p>
              All data is accessed through authorised end points which are protected by bearer tokens that expire every 24 hours, at which point each user will
              be challenged to enter username and password again.
            </p>
            <p>The entire web application is protected by SSL (Secure Socket Layer).</p>
            <p>
              All data is hosted in the Teraco datacentre, South Africa’s premier hosting provider. Incremental backups are performed every 24 hours. Snapshots
              are taken every 2 hours. Data is replicated to an alternative server every 2 seconds for instance failure.
            </p>
            <p>Your information will never be shared with anyone.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.props.onClose()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
