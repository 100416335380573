import 'firebase/messaging';
import 'firebase/firestore';

import { Button } from 'react-bootstrap';
import firebase from 'firebase/app';
import { toast } from 'react-toastify';

export const initializeFirebase = () => {

    const config = {
        apiKey: "AIzaSyB92N9hDmEVjKN68IRoHtAfDpe4EhXpx5w",
        authDomain: "quality-manager-28177.firebaseapp.com",
        projectId: "quality-manager-28177",
        storageBucket: "quality-manager-28177.appspot.com",
        messagingSenderId: "72539450644",
        appId: "1:72539450644:web:051325e7eb310ddb502601",
        measurementId: "G-VYRZ0GHTBT"
      };
    firebase.initializeApp(config);


    const initMessaging = firebase.messaging();

    

    initMessaging.onMessage((payload) => {
        console.log('[firebase-messaging-sw.js] Received foreground message ', payload);

        var msg = () => <div>
            <p><strong>{payload?.notification?.title}</strong></p>
            <span>{payload?.notification?.body}</span>
            <br />     
            <br />     
            {payload?.notification?.click_action ?  <Button raised primary style={{backgroundColor:'rgba(0,0,0,0.4)'}} href={payload?.notification?.click_action}>Go To</Button> : null} 
        </div>
          const msgOptions =  {
            autoClose: parseInt(payload?.data?.autoClose) ?? 5000
            }
        switch (payload?.data?.type) {
            case "info":
                toast.info(msg,msgOptions);
                break;
            case "success":
                toast.success(msg,msgOptions);
                break;
            case "warning":
                toast.warning(msg,msgOptions);
                break;
            case "error":
                toast.error(msg,msgOptions);
                break;
            case "dark":
                toast.dark(msg,msgOptions);
                break;
            default:
                toast(msg,msgOptions);
                break;
        }


    });    

}


export const askForPermissionToReceiveNotifications = async () => {
    try {
        const messaging = firebase.messaging();
        await Notification.requestPermission();
        const token = await messaging.getToken();
        // console.log('token', token);
        localStorage.setItem('notification-token', token);

        const db = firebase.firestore();

        db.collection("users").add({
            token: token
        })
        .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });


        return token;
    } catch (error) {
        console.error(error);
    }
}


