export enum EStatus {
  "Initiated" = 1,
  "Assigned" = 2,
  "Pending HOD Sign Off" = 3,
  "Pending QA Manager Sign Off" = 4,
  "Closed" = 5,
  "Cancelled" = 6,
}

export enum EJobCardStatus {
  "Initiated" = 1,
  "Assigned" = 2,
  "Pending Foreman Sign Off" = 3,
  "Pending Manager Sign Off" = 4,
  "Closed" = 5,
  "Cancelled" = 6,
}

export enum EStatusColours {
  "secondary" = 1,
  "primary" = 2,
  "info" = 3,
  "warning" = 4,
  "success" = 5,
  "dark" = 6,
}

export enum EStatusColoursHex {
  "#6c757d" = 1,
  "#0d6efd" = 2,
  "#17a2b8" = 3,
  "#ffc107" = 4,
  "#198754" = 5,
  "#212529" = 6,
}
