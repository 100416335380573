import * as htmlToImage from "html-to-image";

import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { faCalendar, faDownload } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../helpers/Contexts";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Component } from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IMachineRegisterDDL } from "../../entities/MachineRegister";
import Select from "react-select";
import download from "downloadjs";
import { getData } from "../../helpers/Data";
import moment from "moment";

interface IProps {}

interface IState {
  filterItem: string;
  fromDate?: Date;
  graphType: string;
  machineRegisterId: string;
  statistics?: any;
  toDate?: Date;
  machineRegisters: IMachineRegisterDDL[];
}

export default class MachineDashboard extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      filterItem: "2",
      fromDate: moment().startOf("year").toDate(),
      graphType: "jobCard",
      machineRegisters: [],
      machineRegisterId: "",
      statistics: [],
      toDate: moment().endOf("day").toDate(),
    };
  }

  static contextType = AuthContext;

  componentDidMount = () => {
    this.refresh();
  };

  refresh = async () => {
    await this.refreshMachineRegisters();
  };

  refreshMachineRegisters = () => {
    getData("machineRegisters/GetMachineRegisterDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      this.setState({ machineRegisters: data, machineRegisterId: this.state.machineRegisterId !== "" ? this.state.machineRegisterId : data[0].value });
      this.getChartData();
    });
  };

  getChartData = () => {
    let url = "reports/jobCardMachineDashboard";
    switch (this.state.graphType) {
      case "jobCard":
        url = "reports/jobCardMachineDashboard";
        break;
      case "cost":
        url = "reports/jobCardCostPerMachineDashboard";
        break;
      case "downtime":
        url = "reports/jobCardDownTimePerMachineDashboard";
        break;
      case "totalDowntime":
        url = "reports/totalDowntime";
        break;
      case "totalBreakdown":
        url = "reports/totalBreakdown";
        break;
    }

    getData(url, {
      companyId: this.context.selectedCompany?.id,
      machineRegisterId: this.state.machineRegisterId,
      from: this.state.fromDate !== undefined ? moment(this.state.fromDate).format("DD MMM YYYY") : "",
      to: this.state.toDate !== undefined ? moment(this.state.toDate).format("DD MMM YYYY hh:mm") : "",
    }).then((data) => {
      this.setState({ statistics: data });
    });
  };

  setFilterItem = (item: any) => {
    let newFilterItem: string = item.value;

    let newFromDate = undefined;
    let newToDate = undefined;

    if (newFilterItem === "1") {
      newFromDate = moment().startOf("month").toDate();
      newToDate = moment().endOf("day").toDate();
    } else if (newFilterItem === "2") {
      newFromDate = moment().startOf("year").toDate();
      newToDate = moment().endOf("day").toDate();
    } else if (newFilterItem === "3") {
      newFromDate = moment().startOf("month").subtract(6, "months").toDate();
      newToDate = moment().endOf("day").toDate();
    }

    this.setState({ filterItem: newFilterItem, fromDate: newFromDate, toDate: newToDate }, () => this.refresh());
  };

  setGraphTypeFilter = (items: any) => {
    let newGraphType: string = "";

    items.forEach((element: any) => {
      newGraphType = element.value;
    });

    this.setState({ graphType: newGraphType }, () => this.refresh());
  };

  saveChartToPng = async (chartId: string, name: string) => {
    const canvas = document.getElementById(chartId) as HTMLCanvasElement;
    htmlToImage
      .toPng(canvas)
      .then((dataUrl) => {
        download(dataUrl, `${name}.png`);
      })
      .catch((err) => {
        console.error("Oops, something went wrong!", err);
      });
  };

  render() {
    let jobCardPrefix =
      this.context.selectedCompany.jobCardPrefix !== null && this.context.selectedCompany.jobCardPrefix !== ""
        ? this.context.selectedCompany.jobCardPrefix
        : "Job Card";

    const selectOptions = [
      { value: "1", label: "Month to Date" },
      { value: "2", label: "Year to Date" },
      { value: "3", label: "Custom" },
    ];
    const chartSelectOptions = [
      { value: "jobCard", label: "Number of Job Cards" },
      { value: "cost", label: "Cost per Machine" },
      { value: "downtime", label: "Downtime per Machine" },
      { value: "totalDowntime", label: "Total Downtime" },
      { value: "totalBreakdown", label: "Total Breakdown Incidents" },
    ];

    let graph;
    if (this.state.machineRegisterId !== "") {
      switch (this.state.graphType) {
        case "jobCard":
          const jobCardPercentageOptions = {
            scales: {
              y: {
                beginAtZero: true,
                max: 100,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                position: "bottom",
                display: false,
              },
              title: {
                display: true,
                text: jobCardPrefix + " Number Per Machine",
                font: {
                  size: 24,
                },
              },
              subtitle: {
                display: true,
                text: this.state.machineRegisterId
                  ? this.state.machineRegisters.filter((option: any) => option.value === this.state.machineRegisterId)[0].label
                  : "",
                font: {
                  size: 20,
                },
                position: "bottom",
              },
              datalabels: {
                display: true,
                align: "top",
                borderRadius: 3,
                font: {
                  size: 16,
                },
                color: "#222",
                backgroundColor: "#ffffff66",
                formatter: (value: any) => {
                  return value;
                },
              },
            },
          };

          const jobCardAvePercentageOptions = {
            scales: {
              y: {
                beginAtZero: true,
                max: 100,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                position: "bottom",
                display: false,
              },
              title: {
                display: true,
                text: jobCardPrefix + " Number Per Operational Unit",
                font: {
                  size: 24,
                },
              },
              subtitle: {
                display: true,
                text: this.state.machineRegisterId
                  ? this.state.machineRegisters.filter((option: any) => option.value === this.state.machineRegisterId)[0].operationalUnit
                  : "",
                font: {
                  size: 20,
                },
                position: "bottom",
              },
              datalabels: {
                display: true,
                align: "top",
                borderRadius: 3,
                font: {
                  size: 16,
                },
                color: "#222",
                backgroundColor: "#ffffff66",
                formatter: (value: any) => {
                  return value;
                },
              },
            },
          };

          const jobCardPercentageData = {
            labels: this.state.statistics?.jobCardPercentagePerMachine?.map((a: any) => a.machineSectionName),
            datasets: [
              {
                label: "",
                data: this.state.statistics?.jobCardPercentagePerMachine?.map((a: any) => a.percentage),
                backgroundColor: this.state.statistics.jobCardPercentagePerMachine?.map((a: any) => a.colour),
                borderWidth: 0,
              },
            ],
          };

          const jobCardAvePercentageData = {
            labels: this.state.statistics.averageJobCardPercentagePerOperationalUnit?.map((a: any) => a.machineSectionName),
            datasets: [
              {
                label: "",
                data: this.state.statistics.averageJobCardPercentagePerOperationalUnit?.map((a: any) => a.percentage),
                backgroundColor: this.state.statistics.averageJobCardPercentagePerOperationalUnit?.map((a: any) => a.colour),
                borderWidth: 0,
              },
            ],
          };

          graph = (
            <Row>
              {this.state.statistics?.jobCardPercentagePerMachine?.length > 0 && (
                <>
                  <Col className="text-center">
                    <Button
                      variant={"success"}
                      size="sm"
                      title="Download Chart"
                      onClick={() => this.saveChartToPng("1", `Number per ${jobCardPrefix} Machine`)}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                    <div id="1" style={{ backgroundColor: "white" }}>
                      <Bar data={jobCardPercentageData} height={200} plugins={[ChartDataLabels]} options={jobCardPercentageOptions} />
                    </div>
                  </Col>

                  <Col className="text-center">
                    <Button
                      variant={"success"}
                      size="sm"
                      title="Download Chart"
                      onClick={() => this.saveChartToPng("2", `Number per ${jobCardPrefix} Operational Unit`)}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                    <div id="2" style={{ backgroundColor: "white" }}>
                      <Bar data={jobCardAvePercentageData} height={200} plugins={[ChartDataLabels]} options={jobCardAvePercentageOptions} />
                    </div>
                  </Col>
                </>
              )}
            </Row>
          );
          break;
        case "cost":
          const costChartOptions = {
            scales: {
              y: {
                beginAtZero: true,
                max: this.state.statistics?.maxValue === 0 ? undefined : this.state.statistics?.maxValue,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: "bottom",
              },
              title: {
                display: true,
                text: "Cost Per Machine",
                font: {
                  size: 24,
                },
              },
              subtitle: {
                display: true,
                text: this.state.machineRegisterId
                  ? this.state.machineRegisters.filter((option: any) => option.value === this.state.machineRegisterId)[0].label
                  : "",
                font: {
                  size: 20,
                },
                position: "bottom",
              },
              datalabels: {
                display: true,
                align: "center",
                borderRadius: 2,
                font: {
                  size: 16,
                },
                color: "#222",
                backgroundColor: "#ffffff66",
                formatter: (value: any) => {
                  return value.toLocaleString("en-ZA", {
                    style: "currency",
                    currency: "ZAR",
                  });
                },
              },
            },
          };
          const aveCostChartOptions = {
            scales: {
              y: {
                beginAtZero: true,
                max: this.state.statistics?.maxValue === 0 ? undefined : this.state.statistics?.maxValue,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: "bottom",
              },
              title: {
                display: true,
                text: "Cost Per Operational Unit",
                font: {
                  size: 24,
                },
              },
              subtitle: {
                display: true,
                text: this.state.machineRegisterId
                  ? this.state.machineRegisters.filter((option: any) => option.value === this.state.machineRegisterId)[0].operationalUnit
                  : "",
                font: {
                  size: 20,
                },
                position: "bottom",
              },
              datalabels: {
                display: true,
                align: "center",
                borderRadius: 2,
                font: {
                  size: 16,
                },
                color: "#222",
                backgroundColor: "#ffffff66",
                formatter: (value: any) => {
                  return value.toLocaleString("en-ZA", {
                    style: "currency",
                    currency: "ZAR",
                  });
                },
              },
            },
          };

          const aveCostPerMachineData = {
            labels: this.state.statistics.averageCostPerOperationalUnits?.map((a: any) => a.machineSectionName),
            datasets: [
              {
                label: "Cost",
                data: this.state.statistics.averageCostPerOperationalUnits?.map((a: any) => a.cost),
                backgroundColor: this.state.statistics.averageCostPerOperationalUnits?.map((a: any) => a.colour),
                borderWidth: 0,
              },
            ],
          };

          const costPerMachineData = {
            labels: this.state.statistics.costPerMachines?.map((a: any) => a.machineSectionName),
            datasets: [
              {
                label: "Cost",
                data: this.state.statistics.costPerMachines?.map((a: any) => a.cost),
                backgroundColor: this.state.statistics.costPerMachines?.map((a: any) => a.colour),
                borderWidth: 0,
              },
            ],
          };

          graph = (
            <Row>
              {this.state.statistics?.costPerMachines?.length > 0 && (
                <>
                  <Col className="text-center">
                    <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("3", `Cost per Machine`)}>
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                    <div id="3" style={{ backgroundColor: "white" }}>
                      <Bar data={costPerMachineData} height={200} plugins={[ChartDataLabels]} options={costChartOptions} />
                    </div>
                  </Col>
                  <Col className="text-center">
                    <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("4", `Cost per Operational Unit`)}>
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                    <div id="4" style={{ backgroundColor: "white" }}>
                      <Bar data={aveCostPerMachineData} height={200} plugins={[ChartDataLabels]} options={aveCostChartOptions} />
                    </div>
                  </Col>
                </>
              )}
            </Row>
          );
          break;
        case "downtime":
          const downtimeChartOptions = {
            scales: {
              y: {
                beginAtZero: true,
                max: this.state.statistics?.maxValue === 0 ? undefined : this.state.statistics?.maxValue,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: "bottom",
              },
              title: {
                display: true,
                text: "Downtime Per Machine",
                font: {
                  size: 24,
                },
              },
              subtitle: {
                display: true,
                text: this.state.machineRegisterId
                  ? this.state.machineRegisters.filter((option: any) => option.value === this.state.machineRegisterId)[0].label
                  : "",
                font: {
                  size: 20,
                },
                position: "bottom",
              },
              datalabels: {
                display: true,
                align: "center",
                borderRadius: 2,
                font: {
                  size: 16,
                },
                color: "#222",
                backgroundColor: "#ffffff66",
              },
            },
          };
          const aveDowntimeChartOptions = {
            scales: {
              y: {
                beginAtZero: true,
                max: this.state.statistics?.maxValue === 0 ? undefined : this.state.statistics?.maxValue,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: "bottom",
              },
              title: {
                display: true,
                text: "Downtime For Operational Unit",
                font: {
                  size: 24,
                },
              },
              subtitle: {
                display: true,
                text: this.state.machineRegisterId
                  ? this.state.machineRegisters.filter((option: any) => option.value === this.state.machineRegisterId)[0].operationalUnit
                  : "",
                font: {
                  size: 20,
                },
                position: "bottom",
              },
              datalabels: {
                display: true,
                align: "center",
                borderRadius: 2,
                font: {
                  size: 16,
                },
                color: "#222",
                backgroundColor: "#ffffff66",
              },
            },
          };

          const aveDowntimePerMachineData = {
            labels: this.state.statistics.averageDownTimePerOperationalUnit?.map((a: any) => a.machineSectionName),
            datasets: [
              {
                label: "Hours",
                data: this.state.statistics.averageDownTimePerOperationalUnit?.map((a: any) => a.time),
                backgroundColor: this.state.statistics.averageDownTimePerOperationalUnit?.map((a: any) => a.colour),
                borderWidth: 0,
              },
            ],
          };

          const downtimePerMachineData = {
            labels: this.state.statistics.downTimePerMachine?.map((a: any) => a.machineSectionName),
            datasets: [
              {
                label: "Hours",
                data: this.state.statistics.downTimePerMachine?.map((a: any) => a.time),
                backgroundColor: this.state.statistics.downTimePerMachine?.map((a: any) => a.colour),
                borderWidth: 0,
              },
            ],
          };

          graph = (
            <Row>
              {this.state.statistics?.downTimePerMachine?.length > 0 && (
                <>
                  <Col className="text-center">
                    <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("5", `Downtime per Machine`)}>
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                    <div id="5" style={{ backgroundColor: "white" }}>
                      <Bar data={downtimePerMachineData} height={200} plugins={[ChartDataLabels]} options={downtimeChartOptions} />
                    </div>
                  </Col>
                  <Col className="text-center">
                    <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("6", `Downtime per Operational Unit`)}>
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                    <div id="6" style={{ backgroundColor: "white" }}>
                      <Bar data={aveDowntimePerMachineData} height={200} plugins={[ChartDataLabels]} options={aveDowntimeChartOptions} />
                    </div>
                  </Col>
                </>
              )}
            </Row>
          );
          break;
        case "totalDowntime":
          const totalDowntimeChartOptions = {
            scales: {
              y: {
                beginAtZero: true,
                max: this.state.statistics?.maxValue === 0 ? undefined : this.state.statistics?.maxValue,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: "bottom",
              },
              title: {
                display: true,
                text: "Total Downtime Per Machine",
                font: {
                  size: 24,
                },
              },
              subtitle: {
                display: true,
                text: this.state.machineRegisterId
                  ? this.state.machineRegisters.filter((option: any) => option.value === this.state.machineRegisterId)[0].label
                  : "",
                font: {
                  size: 20,
                },
                position: "bottom",
              },
              datalabels: {
                display: true,
                align: "center",
                borderRadius: 2,
                font: {
                  size: 16,
                },
                color: "#222",
                backgroundColor: "#ffffff66",
              },
            },
            spanGaps: true,
          };
          const totalAveDowntimeChartOptions = {
            scales: {
              y: {
                beginAtZero: true,
                max: this.state.statistics?.maxValue === 0 ? undefined : this.state.statistics?.maxValue,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: "bottom",
              },
              title: {
                display: true,
                text: "Total Downtime For Operational Unit",
                font: {
                  size: 24,
                },
              },
              subtitle: {
                display: true,
                text: this.state.machineRegisterId
                  ? this.state.machineRegisters.filter((option: any) => option.value === this.state.machineRegisterId)[0].operationalUnit
                  : "",
                font: {
                  size: 20,
                },
                position: "bottom",
              },
              datalabels: {
                display: true,
                align: "center",
                borderRadius: 2,
                font: {
                  size: 16,
                },
                color: "#222",
                backgroundColor: "#ffffff66",
              },
            },
            spanGaps: true,
          };

          const monthTotalsAve = this.state.statistics.totalPerOperationalUnit?.monthTotals || [];
          const firstValueAve = monthTotalsAve.length > 0 ? monthTotalsAve[0] : 0;
          const lastValueAve = monthTotalsAve.length > 1 ? monthTotalsAve[monthTotalsAve.length - 1] : firstValueAve; // Handle case with only 1 value

          // Generate the data array for the trendline
          let trendlineDataAve = [];
          if (monthTotalsAve.length > 1) {
            // Create the trendline: start at first value, end at last value, fill nulls in between
            trendlineDataAve = [firstValueAve, ...Array(monthTotalsAve.length - 2).fill(null), lastValueAve];
          } else {
            // If there's only one data point, just use it for the trendline
            trendlineDataAve = [firstValueAve];
          }

          const totalAveDowntimePerMachineData = {
            labels: this.state.statistics.totalPerOperationalUnit?.labels,
            datasets: [
              {
                type: "line",
                data: trendlineDataAve,
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 2,
                fill: false, // Ensure the line isn't filled under
                pointRadius: 0, // Hide the points if you don't want them visible
                tension: 0.4, // Adds a curve to the line (0 = straight lines)
                tooltip: { enabled: false },
                // Hide data labels for the trendline
                datalabels: {
                  display: false, // Disable labels for the line
                },
              },
              {
                label: "Hours",
                data: monthTotalsAve,
                backgroundColor: this.state.statistics.totalPerOperationalUnit?.colour,
                borderWidth: 0,
              },
            ],
          };

          const monthTotals = this.state.statistics.totalPerMachine?.monthTotals || [];
          const firstValue = monthTotals.length > 0 ? monthTotals[0] : 0;
          const lastValue = monthTotals.length > 1 ? monthTotals[monthTotals.length - 1] : firstValue; // Handle case with only 1 value

          // Generate the data array for the trendline
          let trendlineData = [];
          if (monthTotals.length > 1) {
            // Create the trendline: start at first value, end at last value, fill nulls in between
            trendlineData = [firstValue, ...Array(monthTotals.length - 2).fill(null), lastValue];
          } else {
            // If there's only one data point, just use it for the trendline
            trendlineData = [firstValue];
          }

          const totalDowntimePerMachineData = {
            labels: this.state.statistics.totalPerMachine?.labels,
            datasets: [
              {
                type: "line",
                data: trendlineData,
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 2,
                fill: false, // Ensure the line isn't filled under
                pointRadius: 0, // Hide the points if you don't want them visible
                tension: 0.4, // Adds a curve to the line (0 = straight lines)
                tooltip: { enabled: false },
                // Hide data labels for the trendline
                datalabels: {
                  display: false, // Disable labels for the line
                },
              },
              {
                label: "Hours",
                data: this.state.statistics.totalPerMachine?.monthTotals,
                backgroundColor: this.state.statistics.totalPerMachine?.colour,
                borderWidth: 0,
              },
            ],
          };

          graph = (
            <Row>
              <Col className="text-center">
                <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("7", `Total Downtime per Machine`)}>
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
                <div id="7" style={{ backgroundColor: "white" }}>
                  <Bar data={totalDowntimePerMachineData} height={200} plugins={[ChartDataLabels]} options={totalDowntimeChartOptions} />
                </div>
              </Col>
              <Col className="text-center">
                <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("8", `Total Downtime per Operational Unit`)}>
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
                <div id="8" style={{ backgroundColor: "white" }}>
                  <Bar data={totalAveDowntimePerMachineData} height={200} plugins={[ChartDataLabels]} options={totalAveDowntimeChartOptions} />
                </div>
              </Col>
            </Row>
          );
          break;
        case "totalBreakdown":
          const totalBreakdownChartOptions = {
            scales: {
              y: {
                beginAtZero: true,
                max: this.state.statistics?.maxValue === 0 ? undefined : this.state.statistics?.maxValue,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: "bottom",
              },
              title: {
                display: true,
                text: "Total Breakdown Per Machine",
                font: {
                  size: 24,
                },
              },
              subtitle: {
                display: true,
                text: this.state.machineRegisterId
                  ? this.state.machineRegisters.filter((option: any) => option.value === this.state.machineRegisterId)[0].label
                  : "",
                font: {
                  size: 20,
                },
                position: "bottom",
              },
              datalabels: {
                display: true,
                align: "center",
                borderRadius: 2,
                font: {
                  size: 16,
                },
                color: "#222",
                backgroundColor: "#ffffff66",
              },
            },
            spanGaps: true,
          };
          const totalAveBreakdownChartOptions = {
            scales: {
              y: {
                beginAtZero: true,
                max: this.state.statistics?.maxValue === 0 ? undefined : this.state.statistics?.maxValue,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: "bottom",
              },
              title: {
                display: true,
                text: "Total Breakdown For Operational Unit",
                font: {
                  size: 24,
                },
              },
              subtitle: {
                display: true,
                text: this.state.machineRegisterId
                  ? this.state.machineRegisters.filter((option: any) => option.value === this.state.machineRegisterId)[0].operationalUnit
                  : "",
                font: {
                  size: 20,
                },
                position: "bottom",
              },
              datalabels: {
                display: true,
                align: "center",
                borderRadius: 2,
                font: {
                  size: 16,
                },
                color: "#222",
                backgroundColor: "#ffffff66",
              },
            },
            spanGaps: true,
          };

          const monthTotalsBreakdownAve = this.state.statistics.totalPerOperationalUnit?.monthTotals || [];
          const firstValueBreakdownAve = monthTotalsBreakdownAve.length > 0 ? monthTotalsBreakdownAve[0] : 0;
          const lastValueBreakdownAve =
            monthTotalsBreakdownAve.length > 1 ? monthTotalsBreakdownAve[monthTotalsBreakdownAve.length - 1] : firstValueBreakdownAve; // Handle case with only 1 value

          // Generate the data array for the trendline
          let trendlineDataBreakdownAve = [];
          if (monthTotalsBreakdownAve.length > 1) {
            // Create the trendline: start at first value, end at last value, fill nulls in between
            trendlineDataBreakdownAve = [firstValueBreakdownAve, ...Array(monthTotalsBreakdownAve.length - 2).fill(null), lastValueBreakdownAve];
          } else {
            // If there's only one data point, just use it for the trendline
            trendlineDataBreakdownAve = [firstValueBreakdownAve];
          }

          const totalAveBreakdownPerMachineData = {
            labels: this.state.statistics.totalPerOperationalUnit?.labels,
            datasets: [
              {
                type: "line",
                data: trendlineDataBreakdownAve,
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 2,
                fill: false, // Ensure the line isn't filled under
                pointRadius: 0, // Hide the points if you don't want them visible
                tension: 0.4, // Adds a curve to the line (0 = straight lines)
                tooltip: { enabled: false },
                // Hide data labels for the trendline
                datalabels: {
                  display: false, // Disable labels for the line
                },
              },
              {
                label: "Hours",
                data: monthTotalsBreakdownAve,
                backgroundColor: this.state.statistics.totalPerOperationalUnit?.colour,
                borderWidth: 0,
              },
            ],
          };

          const monthTotalsBreakdown = this.state.statistics.totalPerMachine?.monthTotals || [];
          const firstValueBreakdown = monthTotalsBreakdown.length > 0 ? monthTotalsBreakdown[0] : 0;
          const lastValueBreakdown = monthTotalsBreakdown.length > 1 ? monthTotalsBreakdown[monthTotalsBreakdown.length - 1] : firstValueBreakdown; // Handle case with only 1 value

          // Generate the data array for the trendline
          let trendlineDataBreakdown = [];
          if (monthTotalsBreakdown.length > 1) {
            // Create the trendline: start at first value, end at last value, fill nulls in between
            trendlineDataBreakdown = [firstValueBreakdown, ...Array(monthTotalsBreakdown.length - 2).fill(null), lastValueBreakdown];
          } else {
            // If there's only one data point, just use it for the trendline
            trendlineDataBreakdown = [firstValueBreakdown];
          }

          const totalBreakdownPerMachineData = {
            labels: this.state.statistics.totalPerMachine?.labels,
            datasets: [
              {
                type: "line",
                data: trendlineDataBreakdown,
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 2,
                fill: false, // Ensure the line isn't filled under
                pointRadius: 0, // Hide the points if you don't want them visible
                tension: 0.4, // Adds a curve to the line (0 = straight lines)
                tooltip: { enabled: false },
                // Hide data labels for the trendline
                datalabels: {
                  display: false, // Disable labels for the line
                },
              },
              {
                label: "Hours",
                data: this.state.statistics.totalPerMachine?.monthTotals,
                backgroundColor: this.state.statistics.totalPerMachine?.colour,
                borderWidth: 0,
              },
            ],
          };

          graph = (
            <Row>
              <Col className="text-center">
                <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("7", `Total Breakdowns per Machine`)}>
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
                <div id="7" style={{ backgroundColor: "white" }}>
                  <Bar data={totalBreakdownPerMachineData} height={200} plugins={[ChartDataLabels]} options={totalBreakdownChartOptions} />
                </div>
              </Col>
              <Col className="text-center">
                <Button variant={"success"} size="sm" title="Download Chart" onClick={() => this.saveChartToPng("8", `Total Breakdowns per Operational Unit`)}>
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
                <div id="8" style={{ backgroundColor: "white" }}>
                  <Bar data={totalAveBreakdownPerMachineData} height={200} plugins={[ChartDataLabels]} options={totalAveBreakdownChartOptions} />
                </div>
              </Col>
            </Row>
          );
          break;
      }
    }

    let row;
    if (Number(this.state.filterItem) < 3) {
      row = (
        <Row className="align-items-center">
          <Col>Machine Dashboard</Col>
          <Col sm={3} md={3} lg={2}>
            <Select
              options={chartSelectOptions}
              defaultValue={chartSelectOptions[0]}
              onChange={(e) => this.setState({ graphType: e?.value as string, statistics: [] }, () => this.refresh())}
              placeholder=""
            />
          </Col>
          <Col sm={3} md={3} lg={3}>
            <Select
              options={this.state.machineRegisters}
              onChange={(cat: any) => {
                this.setState({ machineRegisterId: cat.value, statistics: [] }, () => this.refresh());
              }}
              value={this.state.machineRegisterId ? this.state.machineRegisters.filter((option: any) => option.value === this.state.machineRegisterId) : null}
            />
          </Col>
          <Col sm={3} md={3} lg={2}>
            <Select options={selectOptions} defaultValue={selectOptions[1]} onChange={(e) => this.setFilterItem(e)} placeholder="All" />
          </Col>
        </Row>
      );
    } else if (this.state.filterItem === "3") {
      row = (
        <Row className="align-items-center">
          <Col>Machine Dashboard</Col>
          <Col sm={3} md={3} lg={2}>
            <Select
              options={chartSelectOptions}
              defaultValue={chartSelectOptions[0]}
              onChange={(e) => this.setState({ graphType: e?.value as string }, () => this.refresh())}
              placeholder="All"
            />
          </Col>
          <Col sm={3} md={3} lg={3}>
            <Select
              options={this.state.machineRegisters}
              onChange={(cat: any) => {
                this.setState({ machineRegisterId: cat.value, statistics: [] }, () => this.refresh());
              }}
              value={this.state.machineRegisterId ? this.state.machineRegisters.filter((option: any) => option.value === this.state.machineRegisterId) : null}
            />
          </Col>
          <Col sm={3} md={3} lg={2}>
            <Select options={selectOptions} onChange={(e) => this.setFilterItem(e)} />
          </Col>
          <Col sm={2} md={2} lg={2}>
            <div className="customDatePickerWidth">
              <DatePicker
                customInput={
                  <InputGroup className="">
                    <Form.Control placeholder="Date" value={moment(this.state.fromDate).format("DD/MM/YYYY")} />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon2">
                        <FontAwesomeIcon icon={faCalendar} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                }
                onChange={(date: Date) => this.setState({ fromDate: date }, () => this.refresh())}
              />
            </div>
          </Col>
          <Col sm={2} md={2} lg={2}>
            <div className="customDatePickerWidth">
              <DatePicker
                customInput={
                  <InputGroup className="">
                    <Form.Control placeholder="Date" value={moment(this.state.toDate).format("DD/MM/YYYY")} />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon2">
                        <FontAwesomeIcon icon={faCalendar} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                }
                onChange={(date: Date) => this.setState({ toDate: date }, () => this.refresh())}
              />
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <div>
        <Card style={{ width: "100%" }}>
          <Card.Header>
            <Card.Title>{row}</Card.Title>
          </Card.Header>
          <Card.Body>{graph}</Card.Body>
        </Card>
      </div>
    );
  }
}
