import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { deleteData, getData, postData } from "../../helpers/Data";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../helpers/Contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IOperationalUnitList } from "../../entities/OperationalUnits";
import React from "react";
import Select from "react-select";
import { toast } from "react-toastify";

interface IProps {}

interface IState {
  operationalUnits: IOperationalUnitList[];
  showEditDialog: boolean;
  selectedOperationalUnit: IOperationalUnitList;
  users: any;
}

export default class OperationalUnits extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      operationalUnits: [],
      showEditDialog: false,
      selectedOperationalUnit: { name: "", maintenanceForeman: "", companyId: "", maintenanceForemanText: "", operationalUnitAdmins: [] },
      users: [],
    };
  }
  static contextType = AuthContext;

  componentDidMount() {
    this.refresh();
    this.refreshUsers();
  }

  refresh() {
    getData("operationalUnits", { companyId: this.context.selectedCompany?.id }).then((data) => {
      // console.log("Depts", data);
      this.setState({ operationalUnits: data });
    });
  }

  openEditDialog = (unit: IOperationalUnitList) => {
    debugger;
    this.setState({ selectedOperationalUnit: { ...unit }, showEditDialog: true });
  };

  openCreateDialog = () => {
    this.setState({
      selectedOperationalUnit: { name: "", maintenanceForeman: "", companyId: "", maintenanceForemanText: "", operationalUnitAdmins: [] },
      showEditDialog: true,
    });
  };

  onClose = () => {
    this.setState({
      showEditDialog: false,
      selectedOperationalUnit: { name: "", maintenanceForeman: "", companyId: "", maintenanceForemanText: "", operationalUnitAdmins: [] },
    });
  };

  onSave = () => {
    let unit = this.state.selectedOperationalUnit;
    unit.companyId = this.context.selectedCompany.id;

    postData("operationalUnits", unit).then((data) => {
      this.setState(
        {
          showEditDialog: false,
          selectedOperationalUnit: { name: "", maintenanceForeman: "", companyId: "", maintenanceForemanText: "", operationalUnitAdmins: [] },
        },
        () => this.refresh()
      );
    });
  };

  setSelectedOperationalUnitField = (value: any, field: keyof IOperationalUnitList) => {
    let unit = this.state.selectedOperationalUnit;
    unit = this.prop(unit, field, value);
    this.setState({ selectedOperationalUnit: unit });
  };

  prop<T, K extends keyof T>(obj: T, key: K, newVal: any) {
    obj[key] = newVal;
    return obj;
  }

  refreshUsers = () => {
    getData("users/GetUserDDL", { companyId: this.context.selectedCompany?.id }).then((data) => {
      // console.log("Users", data);
      this.setState({ users: data });
    });
  };

  delete = (id: string | undefined) => {
    deleteData("operationalUnits/", { id: id }).then((data) => {
      if (data.status === 400) {
        toast.error("Operational Unit cannot be deleted as it is in use.");
      }
      this.refresh();
    });
  };

  render() {
    return (
      <div>
        <Card style={{ width: "100%" }}>
          <Card.Header>
            <Card.Title>
              Operational Units
              <span className="float-right">
                <Button variant="primary" className="" onClick={() => this.openCreateDialog()}>
                  Add Operational Unit
                </Button>
              </span>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Table striped hover size="sm">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Sign-off Maintenance Admin</th>
                      <th>Maintenance Admins</th>
                      <th style={{ width: "100px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.operationalUnits.length > 0
                      ? this.state.operationalUnits.map((unit: IOperationalUnitList, index) => {
                          return (
                            <tr key={index}>
                              <td>{unit.name}</td>
                              <td>{unit.maintenanceForemanText}</td>
                              <td>{unit.operationalUnitAdmins.map((m) => m.label).join(" | ")}</td>
                              <td>
                                <Button variant="primary" size="sm" onClick={() => this.openEditDialog(unit)}>
                                  <FontAwesomeIcon icon={faEdit} /> Edit
                                </Button>
                                <Button variant="danger" size="sm" onClick={() => this.delete(unit.id)}>
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Modal show={this.state.showEditDialog} onHide={() => this.onClose()} animation={false}>
          <Modal.Header>
            <Modal.Title>{this.state.selectedOperationalUnit.id ? "Edit " + this.state.selectedOperationalUnit.name : "Create Operational Unit"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={this.state.selectedOperationalUnit.name}
                  placeholder="Enter Operational Unit Name"
                  onChange={(e) => this.setSelectedOperationalUnitField(e.target.value, "name")}
                />
              </Form.Group>
              <Form.Group controlId="formHorizontalEmail">
                <Form.Label>Sign-off Maintenance Admin</Form.Label>

                <Select
                  options={this.state.users}
                  onChange={(inv: any) => {
                    this.setSelectedOperationalUnitField(inv.value, "maintenanceForeman");
                  }}
                  value={
                    this.state.selectedOperationalUnit.maintenanceForeman
                      ? this.state.users.filter((option: any) => option.value === this.state.selectedOperationalUnit.maintenanceForeman)
                      : null
                  }
                  placeholder="Select Sign-off Maintenance Admin"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Maintenance Admins</Form.Label>
                <Select
                  isMulti
                  options={this.state.users}
                  placeholder="Select Maintenance Admins"
                  value={this.state.selectedOperationalUnit.operationalUnitAdmins.map((item: any) => ({ value: item.value, label: item.label }))}
                  onChange={(items) => this.setSelectedOperationalUnitField(items, "operationalUnitAdmins")}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.onClose()}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.onSave();
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
