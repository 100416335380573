import React from 'react';
import packageJson from '../package.json';
import { toast } from "react-toastify";

(global as any).appVersion = packageJson.version;

const semverGreaterThan = (versionA: any, versionB: any) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

class CacheBuster extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: () => {
                console.log('Clearing cache and hard reloading...')
                toast.warning("A new version of the app is available. The app will reload please be patient this may take a few minutes.");
                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then(function (names) {
                        for (let name of names) caches.delete(name);
                    });
                }

                // delete browser cache and hard reload
                window.location.reload();
            }
        };
    }

    componentDidMount() {
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = (global as any).appVersion;

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Chache will be cleared`);
                    toast.warning("A new version of the app is available. The app will reload please be patient this may take a few minutes.");
                    this.setState({ loading: false, isLatestVersion: false });
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    this.setState({ loading: false, isLatestVersion: true });
                }
            });
    }
    render() {
        const { loading, isLatestVersion, refreshCacheAndReload } = this.state as {
            loading: boolean;
            isLatestVersion: boolean;
            refreshCacheAndReload: () => void;
        };
        return (this.props as any).children({ loading, isLatestVersion, refreshCacheAndReload });
    }
}

export default CacheBuster;